import { getCompanyConfigData } from '../company/service';
import { USER_TYPE } from '../../utils/constants/user';
import { toast } from 'react-toastify';
import {
    callEditUser,
    fetchAllUser,
    fetchAmlPepData,
    fetchAvailableDebtProducts,
    fetchAvailableProducts,
    fetchCityNames,
    fetchCompanySector,
    getAllInvestmentPreferenceData,
    getInvestmentReportService,
} from './service';
import { moveCompanyToNewTier, unlockAICAReport } from './investorApiHelper';
import { JAVA_API_STATUS } from '../../utils/axios-interceptor';
import { GENERIC_ERROR } from '../../modules/Company/UploadJourney/components/GstFetch/GSTModal/Context/GstContext';

export async function getPartnetConfigDataAndSetFormData(payload, handlers) {
    try {
        const configResponse = await getCompanyConfigData({
            accountType: USER_TYPE.PARTNER,
            investorOrgId: payload.investorOrgId,
            primaryEmai: payload.primaryEmail,
            configName: 'AICA_PARTNER_SIGNUP_ADDITIONAL_DETAILS',
        });
        const configData = configResponse?.data?.mainConfig;
        const additionalDetailsConfig = configData?.additionalDetailsConfig;
        if (configData && additionalDetailsConfig) handlers?.onSuccess?.(configData);
        else {
            handlers?.onError?.();
        }
    } catch (error) {
        handlers?.onError?.();
        toast.error('Error fetching application details config');
    }
}

export async function getDealFormConfig(payload, handlers) {
    try {
        const configResponse = await getCompanyConfigData({
            accountType: payload.accountType,
            investorOrgId: payload.investorOrgId,
            primaryEmai: payload.primaryEmail,
            configName: payload.configName,
            step: payload.step,
        });
        const configData = configResponse?.data?.mainConfig;
        const additionalDetailsConfig = configData?.additionalDetailsConfig;
        const organizationName = configResponse?.data?.organizationName;
        if (configData && additionalDetailsConfig)
            handlers?.onSuccess?.(additionalDetailsConfig, organizationName);
        else {
            handlers?.onError?.();
        }
    } catch (error) {
        handlers?.onError?.();
        toast.error('Error fetching application details config');
    }
}

export async function EditUser(payload) {
    try {
        const response = await callEditUser(payload);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export async function getCityNames() {
    try {
        const response = await fetchCityNames();
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export async function getAllUsers(payload) {
    try {
        const response = await fetchAllUser(payload);
        if (response.data.responseData.responseCode === 20) {
            return response.data;
        } else {
            return response.data.responseData.responseMessage;
        }
    } catch (error) {
        console.error(error);
        return {};
    }
}

export async function fetchAllPecData(payload) {
    try {
        const response = await getAllInvestmentPreferenceData(payload);
        if (response.data.responseData.responseCode === JAVA_API_STATUS.SUCCESS) {
            return response.data.responseBody.data;
        } else {
            toast.error(response.data.responseBody.responseData.responseMessage || GENERIC_ERROR);
            return [];
        }
    } catch (error) {
        console.error(error);
        return {};
    }
}

export async function getRiskIndicatorsData(payload) {
    try {
        const response = await fetchAmlPepData(payload);

        if (response?.data?.responseData?.responseCode === JAVA_API_STATUS.SUCCESS) {
            return response.data;
        } else {
            toast.error(response.data.responseBody.responseData.responseMessage || GENERIC_ERROR);
            return [];
        }
    } catch (error) {
        console.error(error);
        toast.error(GENERIC_ERROR);
        return [];
    }
}

export async function fetchAllAvailableProducts(payload, isDebtProducts = false) {
    try {
        let response = isDebtProducts
            ? await fetchAvailableDebtProducts(payload)
            : await fetchAvailableProducts(payload);
        if (response.data.responseData.responseCode === JAVA_API_STATUS.SUCCESS) {
            return response.data.responseBody.data;
        } else {
            toast.error(response.data.responseBody.responseData.responseMessage || GENERIC_ERROR);
            return {};
        }
    } catch (error) {
        console.error(error);
        return {};
    }
}

export async function fetchCompanySectorsList() {
    try {
        const response = await fetchCompanySector();
        if (response.data.responseData.responseCode === JAVA_API_STATUS.SUCCESS) {
            return response.data.responseBody;
        } else {
            toast.error(response.data.responseBody.responseData.responseMessage || GENERIC_ERROR);
            return {};
        }
    } catch (error) {
        console.error(error);
        return {};
    }
}

export async function getInvestmentReport(payload, handlers) {
    try {
        const response = await getInvestmentReportService(payload);
        const responseData = response?.data?.responseData;
        if (responseData?.responseCode === 20) {
            handlers?.onSuccess?.(response?.data || {});
            return response.data;
        } else {
            handlers?.onError?.(response?.data || {});
            return response.data.responseData.responseMessage;
        }
    } catch (error) {
        console.error(error);
        return {};
    }
}

export const moveTierAndUnlockAICAReport = (
    orgId: string,
    orgName: string,
    tierName: string,
    investorOrgId: string,
    onSuccess: Function,
    unlockReport: boolean = true,
) => {
    moveCompanyToNewTier({
        assesseeOrgId: orgId,
        inviteType: tierName,
        investorOrgId: investorOrgId,
    })
        .then(() => {
            if (unlockReport) {
                unlockAICAReport(
                    {
                        assesseeOrgId: orgId,
                        investorOrgId: investorOrgId,
                    },
                    {
                        onSuccess: () => {
                            onSuccess?.();
                        },
                    },
                );
            } else onSuccess?.();
        })
        .catch((err) => {
            toast.error('Error in unlocking report.');
            console.error('Error in unlocking report', err);
        });
};
