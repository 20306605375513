import React, { useEffect, useState } from 'react';
import { useSlate } from 'slate-react';
import Button from '../common/Button';
import Icon from '../common/Icon';
import {
    activeMark,
    addMarkData,
    isBlockActive,
    isMarkActive,
    toggleBlock,
    toggleMark,
} from '../utils/SlateUtilityFunctions.js';
import useFormat from '../utils/customHooks/useFormat.js';
import defaultToolbarGroups from './toolbarGroups.js';
import ColorPicker from '../Elements/ColorPicker/ColorPicker';
import LinkButton from '../Elements/Link/LinkButton';
import Embed from '../Elements/Embed/Embed';
import TableSelector from '../Elements/Table/TableSelector';
import Id from '../Elements/ID/ID';
import TableContextMenu from '../Elements/TableContextMenu/TableContextMenu';
import styles from './Toolbar.module.scss';
import { progressAutosavedGray } from '../../../assets/hostedassets';
import closeIcon from '../../_custom/Modal/close.svg';

const Toolbar = (props) => {
    const { handleCodeToText, icon } = props;
    const editor = useSlate();
    const isTable = useFormat(editor, 'table');
    const [toolbarGroups, setToolbarGroups] = useState(defaultToolbarGroups);

    useEffect(() => {
        // Filter out the groups which are not allowed to be inserted when a table is in focus.
        let filteredGroups = [...defaultToolbarGroups];
        if (isTable) {
            filteredGroups = toolbarGroups.map((grp) =>
                grp.filter(
                    (element) =>
                        //groups that are not supported inside the table
                        !['codeToText'].includes(element.type),
                ),
            );
            filteredGroups = filteredGroups.filter((elem) => elem.length);
        }
        setToolbarGroups(filteredGroups);
    }, [isTable]);

    const BlockButton = ({ format, icon }) => {
        return (
            <Button
                active={isBlockActive(editor, format)}
                format={format}
                onMouseDown={(e) => {
                    e.preventDefault();
                    toggleBlock(editor, format);
                }}
            >
                <Icon icon={icon} />
            </Button>
        );
    };
    const MarkButton = ({ format, icon }) => {
        return (
            <Button
                active={isMarkActive(editor, format)}
                format={format}
                onMouseDown={(e) => {
                    e.preventDefault();
                    toggleMark(editor, format);
                }}
            >
                <Icon icon={icon} />
            </Button>
        );
    };
    const Dropdown = ({ format, options }) => {
        return (
            <select
                value={activeMark(editor, format)}
                onChange={(e) => changeMarkData(e, format)}
                className={styles.Select}
            >
                {options.map((item, index) => (
                    <option key={index} value={item.value}>
                        {item.text}
                    </option>
                ))}
            </select>
        );
    };
    const changeMarkData = (event, format) => {
        event.preventDefault();
        const value = event.target.value;
        addMarkData(editor, { format, value });
    };

    return (
        <div className={styles.Toolbar}>
            <div className={styles.ToolbarLeft}>
                {toolbarGroups.map((group, index) => (
                    <span key={index} className={styles.ToolbarGrp}>
                        {group.map((element) => {
                            switch (element.type) {
                                case 'block':
                                    return <BlockButton key={element.id} {...element} />;
                                case 'mark':
                                    return <MarkButton key={element.id} {...element} />;
                                case 'dropdown':
                                    return <Dropdown key={element.id} {...element} />;
                                case 'link':
                                    return (
                                        <LinkButton
                                            key={element.id}
                                            active={isBlockActive(editor, 'link')}
                                            editor={editor}
                                            icon={element?.icon}
                                        />
                                    );
                                case 'embed':
                                    return (
                                        <Embed
                                            key={element.id}
                                            format={element.format}
                                            editor={editor}
                                            icon={element?.icon}
                                        />
                                    );
                                case 'color-picker':
                                    return (
                                        <ColorPicker
                                            key={element.id}
                                            activeMark={activeMark}
                                            format={element.format}
                                            editor={editor}
                                        />
                                    );
                                case 'table':
                                    return (
                                        <TableSelector
                                            key={element.id}
                                            editor={editor}
                                            icon={element?.icon}
                                        />
                                    );
                                case 'id':
                                    return <Id editor={editor} />;
                                default:
                                    return null;
                            }
                        })}
                    </span>
                ))}
            </div>

            {/*<div className={styles.TableMsg}>*/}
            {/*    *Right-click tables to modify: add rows, add columns, or delete.*/}
            {/*</div>*/}
            <TableContextMenu editor={editor} />
            <img
                src={progressAutosavedGray}
                alt={'p'}
                height={'22px'}
                style={{ marginRight: '40px' }}
            />
            <img
                onClick={() => props?.onClose?.()}
                className={styles.CloseIcon}
                src={closeIcon}
                alt={'x'}
                height={'14px'}
            />
        </div>
    );
};

export default Toolbar;
