import React from 'react';
import clsx from 'clsx';

import styles from './ProbabilityBar.module.scss';

function ProbabilityBar({ probability = '0' }: { probability: string }) {
    let probabilityValueForBar = parseFloat(probability) ?? 0;
    probabilityValueForBar = probabilityValueForBar > 100 ? 100 : probabilityValueForBar;

    const displayValue = probabilityValueForBar <= 0 ? 'N/A' : `${probability}%`;

    return (
        <div className={styles.Probability}>
            <div
                className={clsx(styles.Value, {
                    [styles.NotAvailable]: probabilityValueForBar <= 0,
                })}
            >
                {displayValue}
            </div>

            <div className={styles.BarContainer}>
                <div
                    className={styles.BarFill}
                    style={{ width: `${probabilityValueForBar}%` }}
                ></div>
            </div>
        </div>
    );
}

export default ProbabilityBar;
