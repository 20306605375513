import { INVESTOR_ROUTES, LENDER_ROUTES } from './routesConst';

export type uiStyleType = {
    buttons?: {
        primary?: {
            textColour?: string;
            backgroundColour?: string;
        };
        secondary?: {
            textColour?: string;
            borderColour?: string;
            backgroundColour?: string;
        };
        tertiary?: {
            textColour?: string;
        };
    };
    tabs?: {
        active?: {
            textColour?: string;
            backgroundColour?: string;
        };
        inactive?: {
            textColour?: string;
            backgroundColour?: string;
        };
    };
    backgrounds?: {
        primary?: {
            backgroundColour?: string;
        };
        secondary?: {
            backgroundColour?: string;
        };
    };
    texts?: {
        primary?: {
            textColour?: string;
        };
    };
    borders?: {
        primary?: {
            borderColour?: string;
        };
    };
};

export enum ROOT_TYPE {
    BORROWER = 'borrower',
    INVESTOR = 'investor',
    LENDER = 'lender',
}

export const getRouteType = (
    currentPath: string,
): ROOT_TYPE.BORROWER | ROOT_TYPE.LENDER | ROOT_TYPE.INVESTOR => {
    if (Object.values(INVESTOR_ROUTES).some((route) => currentPath.startsWith(route))) {
        return ROOT_TYPE.INVESTOR;
    }
    if (Object.values(LENDER_ROUTES).some((route) => currentPath.startsWith(route))) {
        return ROOT_TYPE.LENDER;
    }
    return ROOT_TYPE.BORROWER;
};

export const defaultUiStyle: uiStyleType = {
    buttons: {
        primary: {
            textColour: '#FFFFFF',
            backgroundColour: '#6021b3',
        },
        secondary: {
            textColour: '#6021b3',
            borderColour: '#6021b3',
            backgroundColour: '#FFFFFF',
        },
        tertiary: {
            textColour: '#6021b3',
        },
    },
    tabs: {
        active: {
            textColour: '#6021b3',
            backgroundColour: '#f9f5ff',
        },
        inactive: {
            textColour: '#AAAAAA',
            backgroundColour: '#f7f7f7',
        },
    },
    backgrounds: {
        primary: {
            backgroundColour: '#6021b3',
        },
        secondary: {
            backgroundColour: '#f9f5ff',
        },
    },
    texts: {
        primary: {
            textColour: '#6021b3',
        },
    },
    borders: {
        primary: {
            borderColour: '#6021b3',
        },
    },
};

export const ONE_DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
