// src/components/ManageDealDrawer/ManageDealDrawerFooter.tsx

import React from 'react';
import styles from '../ManageDealDrawer.module.scss';
import { DEBT_PRODUCT_FILTERS } from '../DealDrawerConstants';

interface ManageDealDrawerFooterProps {
    currentConfig: any;
    disablePrimary: boolean;
    onPrimary: () => void;
    onSecondary: () => void;
    step: number;
    source: string;
    disableSecondary: boolean;
}

export const ManageDealDrawerFooter: React.FC<ManageDealDrawerFooterProps> = ({
    currentConfig,
    disablePrimary,
    onPrimary,
    onSecondary,
    disableSecondary,
    step,
    source,
}) => {
    if (!currentConfig?.ctas) return null;

    return (
        <div className={styles.DrawerFooter}>
            <div className={styles.CtaContainer}>
                {currentConfig.ctas.primary && (
                    <button
                        type="submit"
                        disabled={disablePrimary}
                        className={`${styles.Button} ${styles.Primary}`}
                    >
                        {currentConfig.ctas.primary.text}
                    </button>
                )}

                {currentConfig.ctas.secondary && (
                    <button
                        type="button"
                        disabled={disableSecondary}
                        className={`${styles.Button} ${styles.Secondary}`}
                        onClick={onSecondary}
                    >
                        {currentConfig.ctas.secondary.text}
                    </button>
                )}
            </div>
        </div>
    );
};
