import React, { useEffect } from 'react';

import styles from '../../ManageDealDrawer.module.scss';
import { convertToFirstLetterCapital, isEmpty } from '../../../../utils/utils';
import {
    CONFIG_STEP,
    CONFIG_TYPE,
    DEBT_PRODUCT_FILTERS,
    IMPORTANCE_LEVELS,
    UPLOAD_FILES_PREFIXES,
} from '../../DealDrawerConstants';
import Dropdown from '../../../_custom/Dropdown';
import MultiSelectDropdown from '../../../_custom/MultiSelectDropdown/MultiSelectDropdown';
import { Button, Input } from '../../../_custom';
import InvestorDealShare from '../../InvestorDealShare/InvestorDealShare';
import UploadCard from '../../../../modules/Company/UploadJourney/components/UploadCard/UploadCard';
import DealDrawerButtonComponents from '../../DealDrawerButtonComponents/DealDrawerButtonComponents';
import MultiSelectSearchDropdown from '../MultiSelectSearchDropdown/MultiSelectSearchDropdown';
import { getDataByConfig } from '../../ManageDealDrawerHelper';
import { useAppSelector } from '../../../../app/hooks';

interface InputRendererProps {
    sectionKey: string;
    fieldKey: string;
    config: any;
    formData: Record<string, any>;
    handleChange: (key: string, value: any) => void;
    dealDrawerState: any;
    lenderList: { id: string; name: string }[];
    loanTypeOptions: Record<string, any>;
    sectorList: any[];
    source: string;
    step: number;
    setFormData?: any;
    setDisablePrimary: (value: boolean) => void;
}

interface RangeValidationResult {
    isValid: boolean;
    errorMessage: string;
    importanceMissing: boolean;
}

export const InputRenderer: React.FC<InputRendererProps> = ({
    sectionKey,
    fieldKey,
    config,
    source,
    step,
    loanTypeOptions,
    handleChange,
    dealDrawerState,
    lenderList,
    formData,
    sectorList,
    setFormData,
    setDisablePrimary,
}) => {
    // Build form keys
    const formKey = sectionKey ? `${sectionKey}.${fieldKey}` : fieldKey;
    const importanceKey = sectionKey
        ? `${sectionKey}.importance${convertToFirstLetterCapital(fieldKey)}`
        : `importance${convertToFirstLetterCapital(fieldKey)}`;

    const user = useAppSelector((state) => state.user);
    const [options, setOptions] = React.useState<{ label: string; value: string }[]>([]);

    // Check if we need to show an importance dropdown
    const hasImportance = config.importance === true;

    const fetchData = async () => {
        const res = await getDataByConfig(
            {
                parentOrgId: user.userData.investorOrgId,
            },
            config.api,
            'GET',
        );
        if (res) {
            let temp: any = [];
            Object.keys(res.lenderMap.ADMIN).forEach((key) => {
                temp.push({ label: res?.lenderMap?.ADMIN[key], value: key });
            });
            setOptions(temp);
        }
    };

    const validateRange = (
        minValue: string | undefined,
        maxValue: string | undefined,
        hasImportance: boolean,
        importanceValue?: string,
    ): RangeValidationResult => {
        let isValid = true;
        let errorMessage = '';
        let importanceMissing = false;

        if (!isEmpty(minValue) && !isEmpty(maxValue)) {
            isValid = Number(minValue) <= Number(maxValue);
            errorMessage = isValid ? '' : 'Invalid range';
            if (isValid && hasImportance && !importanceValue) {
                importanceMissing = true;
            }
        } else if (hasImportance && importanceValue && (!minValue || !maxValue)) {
            isValid = false;
            errorMessage = 'Required';
        }
        if (!isValid) setDisablePrimary(true);
        else setDisablePrimary(false);

        return { isValid, errorMessage, importanceMissing };
    };

    useEffect(() => {
        if (config.api && source === DEBT_PRODUCT_FILTERS) {
            fetchData();
        }
    }, [config.api]);

    switch (config?.type) {
        case CONFIG_TYPE.SEARCH_DROPDOWN:
        case CONFIG_TYPE.DROPDOWN: {
            // Example logic for a basic dropdown
            const hasError = !!formData[formKey] && !formData[importanceKey];
            return (
                <div className={styles.Dflex} style={{ width: '100%' }}>
                    <Dropdown
                        enableSearch={config.type === CONFIG_TYPE.SEARCH_DROPDOWN}
                        selectedOption={{
                            id: formData[formKey],
                            label: formData[formKey],
                        }}
                        optionsList={(config.dropdownOptions || []).map((opt: any) => ({
                            id: opt._id,
                            label: opt.label,
                        }))}
                        placeholder={
                            config.isRequired
                                ? config.placeholder
                                : `${config.placeholder} (Optional)`
                        }
                        label={
                            config.isRequired
                                ? config.placeholder
                                : `${config.placeholder} (Optional)`
                        }
                        itemClickHandler={(option) => handleChange(formKey, option.id)}
                        style={hasImportance ? { width: '70%' } : { width: '100%' }}
                        disabled={dealDrawerState?.readonly}
                        errorMessage={config.errorMessage}
                    />
                    {hasImportance && (
                        <Dropdown
                            selectedOption={{
                                id: formData[importanceKey],
                                label: formData[importanceKey],
                            }}
                            listStyles={{ minWidth: '0' }}
                            placeholder={'Importance'}
                            style={{ width: '30%' }}
                            hasError={hasError}
                            errorMessage={'Required'}
                            optionsList={IMPORTANCE_LEVELS}
                            itemClickHandler={(option) => handleChange(importanceKey, option.id)}
                        />
                    )}
                </div>
            );
        }

        case CONFIG_TYPE.MULTIPLE_SELECT: {
            const isValid = formData[formKey] && !formData[importanceKey];
            const isAllDone = !formData[formKey] && formData[importanceKey];
            return (
                <div className={styles.Dflex} style={{ width: '100%' }}>
                    <MultiSelectDropdown
                        hasError={isAllDone}
                        errorMessage={'Required'}
                        WrapperStyle={hasImportance ? { width: '70%' } : { width: '100%' }}
                        selectedOptions={(formData[formKey] as string[]) || []}
                        options={(config.dropdownOptions || []).map((opt: any) => ({
                            id: opt._id,
                            label: opt.label,
                        }))}
                        placeholder={
                            config.isRequired
                                ? config.placeholder
                                : `${config.placeholder} (Optional)`
                        }
                        onChange={(selectedOptions) => handleChange(formKey, selectedOptions)}
                        disabled={dealDrawerState?.readonly}
                    />
                    {hasImportance && (
                        <Dropdown
                            selectedOption={{
                                id: formData[importanceKey],
                                label: formData[importanceKey],
                            }}
                            listStyles={{ minWidth: '0' }}
                            style={{ width: '30%' }}
                            placeholder={'Importance'}
                            hasError={isValid}
                            errorMessage={'Required'}
                            optionsList={IMPORTANCE_LEVELS}
                            itemClickHandler={(option) => handleChange(importanceKey, option.id)}
                        />
                    )}
                </div>
            );
        }

        case CONFIG_TYPE.RANGE: {
            let minKey = `minimum${convertToFirstLetterCapital(fieldKey)}`;
            let maxKey = `maximum${convertToFirstLetterCapital(fieldKey)}`;
            if (sectionKey) {
                minKey = `${sectionKey}.${minKey}`;
                maxKey = `${sectionKey}.${maxKey}`;
            }
            const { isValid, errorMessage, importanceMissing } = validateRange(
                formData[minKey],
                formData[maxKey],
                hasImportance,
                formData[importanceKey],
            );

            return (
                <div className={styles.Dflex} style={{ width: '100%' }}>
                    <div
                        style={hasImportance ? { width: '70%' } : { width: '100%' }}
                        className={styles.RangeInput}
                    >
                        <Input
                            type="number"
                            allowNegative={config.allowNegative ?? false}
                            allowDecimal={config.allowDecimal ?? true}
                            value={formData[minKey] ?? ''}
                            placeholder="Minimum"
                            onChange={(value) => handleChange(minKey, value)}
                            unit={config.unit}
                            style={{ marginTop: 0, width: '100%' }}
                            unitStyle={{ minWidth: '50%' }}
                            disabled={dealDrawerState?.readonly}
                            errorMessage={errorMessage}
                            isValid={isValid}
                        />
                        <Input
                            type="number"
                            allowNegative={config.allowNegative ?? false}
                            allowDecimal={config.allowDecimal ?? true}
                            value={formData[maxKey] ?? ''}
                            placeholder="Maximum"
                            onChange={(value) => handleChange(maxKey, value)}
                            unit={config.unit}
                            style={{ marginTop: 0, width: '100%' }}
                            unitStyle={{ minWidth: '50%' }}
                            disabled={dealDrawerState?.readonly}
                            errorMessage={errorMessage}
                            isValid={isValid}
                        />
                    </div>
                    {hasImportance && (
                        <Dropdown
                            selectedOption={{
                                id: formData[importanceKey],
                                label: formData[importanceKey],
                            }}
                            listStyles={{ minWidth: '0' }}
                            placeholder={'Importance'}
                            style={{ width: '30%' }}
                            label={'Importance'}
                            hasError={importanceMissing}
                            errorMessage={'Required'}
                            optionsList={IMPORTANCE_LEVELS}
                            itemClickHandler={(option) => handleChange(importanceKey, option.id)}
                        />
                    )}
                </div>
            );
        }

        case CONFIG_TYPE.RANGE_FILTER: {
            let minKey = `minimum${convertToFirstLetterCapital(fieldKey)}`;
            let maxKey = `maximum${convertToFirstLetterCapital(fieldKey)}`;
            if (sectionKey) {
                minKey = `${sectionKey}.${minKey}`;
                maxKey = `${sectionKey}.${maxKey}`;
            }
            let isDone = formData[minKey] || formData[maxKey];
            let { isValid, errorMessage, importanceMissing } = validateRange(
                formData[minKey],
                formData[maxKey],
                hasImportance,
                formData[importanceKey],
            );

            return (
                <div
                    className={isDone ? styles.DflexFiltersDone : styles.DflexFilters}
                    style={{ width: '100%' }}
                >
                    <div className={styles.InputLabel} style={{ width: '100%' }}>
                        {config.label}
                        <Button
                            text={'Clear'}
                            type={'reset'}
                            onClick={(e) => {
                                e.preventDefault();
                                handleChange(minKey, '');
                                handleChange(maxKey, '');
                            }}
                            variant={'white'}
                            style={{
                                visibility: isDone ? 'visible' : 'hidden',
                                marginTop: 0,
                                border: '1px solid #E7E7E7',
                                color: '#4D4D4D',
                                fontSize: '0.75rem',
                                padding: '0.25rem 0.75rem',
                            }}
                        />
                    </div>
                    <div
                        style={hasImportance ? { width: '70%' } : { width: '100%' }}
                        className={styles.RangeInput}
                    >
                        <Input
                            type="number"
                            allowNegative={config.allowNegative ?? false}
                            allowDecimal={config.allowDecimal ?? true}
                            value={formData[minKey]}
                            placeholder="Minimum"
                            onChange={(value) => {
                                if (value > 100) return;
                                handleChange(minKey, value);
                            }}
                            unit={config.unit}
                            style={{ marginTop: 0, width: '100%' }}
                            unitStyle={{ minWidth: '50%' }}
                            disabled={dealDrawerState?.readonly}
                            errorMessage={errorMessage}
                            isValid={isValid}
                        />
                        <Input
                            type="number"
                            allowNegative={config.allowNegative ?? false}
                            allowDecimal={config.allowDecimal ?? true}
                            value={formData[maxKey]}
                            placeholder="Maximum"
                            onChange={(value) => {
                                if (value > 100) return;
                                handleChange(maxKey, value);
                            }}
                            unit={config.unit}
                            style={{ marginTop: 0, width: '100%' }}
                            unitStyle={{ minWidth: '50%' }}
                            disabled={dealDrawerState?.readonly}
                            errorMessage={errorMessage}
                            isValid={isValid}
                        />
                    </div>
                </div>
            );
        }

        case CONFIG_TYPE.MULTIPLE_SELECT_API_FILTER: {
            const isDone = formData[formKey];
            return (
                <div
                    className={isDone ? styles.DflexFiltersDone : styles.DflexFilters}
                    style={{ width: '100%' }}
                >
                    <div className={styles.InputLabel} style={{ width: '100%' }}>
                        {config.label}
                        <Button
                            text={'Clear'}
                            type={'reset'}
                            onClick={(e) => {
                                e.preventDefault();
                                handleChange(formKey, '');
                            }}
                            variant={'white'}
                            style={{
                                visibility: isDone ? 'visible' : 'hidden',
                                marginTop: 0,
                                border: '1px solid #E7E7E7',
                                color: '#4D4D4D',
                                fontSize: '0.75rem',
                                padding: '0.25rem 0.75rem',
                            }}
                        />
                    </div>
                    <MultiSelectSearchDropdown
                        options={options}
                        selectedItems={(formData[formKey] as string[]) || []}
                        placeholder={'All'}
                        toggleSelection={(selectedOptions) => {
                            if (selectedOptions.length > 0) {
                                handleChange(formKey, selectedOptions);
                            } else {
                                handleChange(formKey, '');
                            }
                        }}
                    />
                </div>
            );
        }

        case CONFIG_TYPE.MULTIPLE_SELECT_API: {
            // This fetches data from an API (e.g., sector list or loan type options).
            // We assume you have them in `loanTypeOptions` or `sectorList`.
            let isValid = formData[formKey] && !formData[importanceKey];
            let isAllDone = !formData[formKey] && formData[importanceKey];

            let options: { id: string; label: string }[] = [];
            // Example logic: if BORROWER_PROFILE => sectorList
            // if deal or preferred => loanTypeOptions, etc.
            if (sectionKey === CONFIG_STEP.preferredTerms || sectionKey === CONFIG_STEP.dealTerms) {
                options = Object.values(loanTypeOptions).map((item: any) => ({
                    id: item.productName,
                    label: item.productName,
                }));
            } else if (sectionKey === CONFIG_STEP.borrowerProfile) {
                options = sectorList.map((sec: any) => ({
                    id: sec._id,
                    label: sec.label,
                }));
            }

            return (
                <div className={styles.Dflex} style={{ width: '100%' }}>
                    <MultiSelectDropdown
                        hasError={isAllDone}
                        errorMessage={'Required'}
                        WrapperStyle={hasImportance ? { width: '70%' } : { width: '100%' }}
                        selectedOptions={(formData[formKey] as string[]) || []}
                        options={options}
                        placeholder={
                            config.isRequired
                                ? config.placeholder
                                : `${config.placeholder} (Optional)`
                        }
                        onChange={(selectedOptions) => handleChange(formKey, selectedOptions)}
                        disabled={dealDrawerState?.readonly}
                    />
                    {hasImportance && (
                        <Dropdown
                            selectedOption={{
                                id: formData[importanceKey],
                                label: formData[importanceKey],
                            }}
                            listStyles={{ minWidth: '0' }}
                            style={{ width: '30%' }}
                            placeholder={'Importance'}
                            hasError={isValid}
                            errorMessage={'Required'}
                            optionsList={IMPORTANCE_LEVELS}
                            itemClickHandler={(option) => handleChange(importanceKey, option.id)}
                        />
                    )}
                </div>
            );
        }

        case CONFIG_TYPE.INVESTOR_TOGGLE:
        case CONFIG_TYPE.RECUR_INVESTOR_TOGGLE: {
            // Example using InvestorDealShare
            return (
                <InvestorDealShare
                    source={config.type}
                    lenderList={lenderList}
                    networkLabel={config.placeholder}
                    totalInvestors={lenderList.length}
                    showInvestorList={config.type === CONFIG_TYPE.INVESTOR_TOGGLE}
                    selectedInvestors={(formData.dealReceiverIds as string[]) || []}
                    updateSelectedInvestors={(shared) => handleChange('dealReceiverIds', shared)}
                    style={{ marginTop: '-24px' }}
                />
            );
        }

        case CONFIG_TYPE.UPLOAD: {
            // Example using UploadCard
            const existingFiles = (formData[formKey] || []).filter(
                (doc: any) => doc.documentName?.includes(UPLOAD_FILES_PREFIXES.ATTACHMENT_PREFIX),
            );

            return (
                <UploadCard
                    assesseeOrgId={dealDrawerState?.companyId as string}
                    categoryId={config?.documents?.components?.[0]?.categoryId}
                    flowId={16} // or pass from hook
                    existingFiles={existingFiles.map((doc: any) => ({
                        docId: doc.docId,
                        docName: doc.documentName,
                    }))}
                    metadataUpdate={(fileInfo) =>
                        handleChange(formKey, [
                            ...(formData[formKey] || []),
                            {
                                docId: fileInfo.docId,
                                documentName: `ATTACHMENT_${fileInfo.fileName}`,
                                action: 'SEND_TO_INVESTOR',
                            },
                        ])
                    }
                    fileDeleteHandler={(fileInfo) =>
                        handleChange(
                            formKey,
                            (formData[formKey] || []).filter(
                                (doc: any) => doc.docId !== fileInfo.docId,
                            ),
                        )
                    }
                    allowMultiple
                    disabled={dealDrawerState?.readonly}
                    disableDelete={dealDrawerState?.readonly}
                    style={hasImportance ? { width: '70%' } : { width: '100%' }}
                    fileNamePrefix={UPLOAD_FILES_PREFIXES.ATTACHMENT_PREFIX}
                />
            );
        }

        case CONFIG_TYPE.BUTTON: {
            // If you have custom button components or actions
            return (
                <DealDrawerButtonComponents
                    componentKey={fieldKey}
                    formData={formData}
                    config={config}
                    step={step}
                    source={source}
                    categoryId={config?.documents?.components?.[0]?.categoryId}
                    handleChange={handleChange}
                />
            );
        }

        default: {
            // Basic text/number input
            return (
                <div className={styles.Dflex} style={{ width: '100%' }}>
                    <Input
                        key={fieldKey}
                        type={config.type}
                        allowNegative={config.allowNegative ?? false}
                        allowDecimal={config.allowDecimal ?? true}
                        value={formData[formKey] ?? ''}
                        placeholder={
                            config.isRequired
                                ? config.placeholder
                                : `${config.placeholder} (Optional)`
                        }
                        onChange={(value) => handleChange(formKey, value)}
                        unit={config.unit}
                        style={
                            hasImportance
                                ? { width: '70%', marginTop: 0 }
                                : { width: '100%', marginTop: 0 }
                        }
                        disabled={dealDrawerState?.readonly}
                    />
                    {hasImportance && (
                        <Dropdown
                            selectedOption={{
                                id: formData[importanceKey],
                                label: formData[importanceKey],
                            }}
                            listStyles={{ minWidth: '0' }}
                            style={{ width: '30%' }}
                            label={'Importance'}
                            optionsList={IMPORTANCE_LEVELS}
                            itemClickHandler={(option) => handleChange(importanceKey, option.id)}
                        />
                    )}
                </div>
            );
        }
    }
};
