const BrokenPage = () => {
    return (
        <svg
            width="158"
            height="161"
            viewBox="0 0 158 161"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_8682_127982"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="158"
                height="159"
            >
                <path d="M158 0.429688H0V158.43H158V0.429688Z" fill="white" />
            </mask>
            <g mask="url(#mask0_8682_127982)">
                <path
                    d="M77.5261 39.4162L67.5555 30.4426C66.2471 29.2746 64.4815 28.6173 62.6399 28.6094H33.2862C20.0611 28.6094 9.34082 38.2576 9.34082 50.1602V120.819C9.34082 126.534 11.8644 132.016 16.3543 136.057C20.8445 140.098 26.9359 142.369 33.2862 142.369H111.795C125.021 142.369 135.741 132.721 135.741 120.819V62.8002C135.741 57.0849 133.217 51.6027 128.727 47.5615C124.237 43.5206 118.146 41.2494 111.795 41.2494H82.4417C80.6001 41.2415 78.8345 40.5842 77.5261 39.4162Z"
                    fill="#9D9D9D"
                />
                <path
                    d="M20.13 142.427C16.7567 142.425 13.5574 139.643 11.3974 134.835C9.23837 130.026 8 124.862 8 117.528V57.7434C9.00027 47.5919 13.8109 40.2731 19.3721 40.4415L72.4418 40.4389C75.8485 40.336 79.1004 41.0336 81.303 45.8573C83.5066 50.6809 84.4275 57.0805 83.8139 63.3003L80.3646 125.125C79.3643 135.276 74.5537 142.595 68.9925 142.427H20.13Z"
                    fill="#ebebeb"
                />
                <path
                    d="M65.459 140.861C61.8631 139.895 59.171 136.299 58.1106 131.045C57.0516 125.79 57.0655 120.456 58.9607 113.383L74.4094 55.7283C78.099 46.2241 85.119 40.5403 91.0045 42.2913L147.585 57.4493C151.244 58.3233 154.53 59.9251 155.632 65.2061C156.735 70.4874 156.063 76.9222 153.802 82.7451L134.149 141.382C130.459 150.886 123.439 156.57 117.553 154.819L65.459 140.861Z"
                    fill="#ebebeb"
                />
                <path
                    d="M32.0487 83.3741L32.2448 67.5753C32.2719 65.3941 35.2413 63.6622 38.8766 63.7074C42.512 63.7525 45.4375 65.5575 45.4104 67.7388L45.2144 83.5375C45.1873 85.7188 42.2179 87.4507 38.5825 87.4055C34.9471 87.3604 32.0216 85.5553 32.0487 83.3741Z"
                    fill="#9D9D9D"
                />
                <path
                    d="M82.0819 83.3741L82.278 67.5753C82.3051 65.3941 85.2745 63.6622 88.9098 63.7074C92.5452 63.7525 95.4707 65.5575 95.4437 67.7388L95.2476 83.5375C95.2205 85.7188 92.2511 87.4507 88.6157 87.4055C84.9803 87.3604 82.0548 85.5553 82.0819 83.3741Z"
                    fill="#9D9D9D"
                />
            </g>
            <path
                d="M52 108.43L68 108.43C70.209 108.43 72 111.34 72 114.93C72 118.519 70.209 121.43 68 121.43L52 121.43C49.791 121.43 48 118.519 48 114.93C48 111.34 49.791 108.43 52 108.43Z"
                fill="#9D9D9D"
            />
            <path
                d="M71.2562 108.399L89.2173 113.212C91.6971 113.876 92.9447 117.262 92.0037 120.774C91.0627 124.286 88.2893 126.594 85.8095 125.93L67.8484 121.117C65.3686 120.453 64.121 117.067 65.062 113.555C66.003 110.043 68.7764 107.734 71.2562 108.399Z"
                fill="#9D9D9D"
            />
            <path
                d="M86 45.4297L90 41.4297L91.5 35.4297L82.5 39.9297L75.5 43.9297V48.9297L70.5 51.9297L72 55.9297L70.5 58.9297L75.5 61.4297L70.5 67.4297L78.5 70.9297L75.5 64.4297L82.5 58.9297V53.9297L86 45.4297Z"
                fill="white"
            />
            <path
                d="M73.5 68.4297L77.5 64.4297L79 58.4297L70 62.9297L63 66.9297V71.9297L58 74.9297L59.5 78.9297L58 81.9297L63 84.4297L58 90.4297L66 93.9297L63 87.4297L70 81.9297V76.9297L73.5 68.4297Z"
                fill="white"
            />
            <path
                d="M68.5247 102.994L67.9323 97.3681L64.2124 92.4272L62.0477 102.254L60.7526 110.211L64.639 113.357L63.825 119.131L67.8779 120.482L69.266 123.535L74 122.93L75.8728 128.883L83.6265 124.867L76.6868 123.109L76.8159 114.208L72.9295 111.062L68.5247 102.994Z"
                fill="white"
            />
            <path
                d="M76.4858 134.646L80.0828 130.383L80.9996 124.432L72.4742 129.673L65.8913 134.233L66.3714 139.068L61.6831 142.463L63.5601 146.183L62.3553 149.233L67.5717 151.156L63.1715 157.452L71.4698 160.046L67.8598 154.057L74.2987 148.047L73.8185 143.211L76.4858 134.646Z"
                fill="white"
            />
        </svg>
    );
};

export default BrokenPage;
