import * as Effects from 'redux-saga/effects';
import { CONSTANTS } from './constants';
import * as apiService from './service';
import {
    addTokenToHeaders,
    JAVA_API_STATUS,
    SOMETHING_WRONG,
} from '../../../utils/axios-interceptor';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ORG_MASTER_STATUS, USER_TYPE } from '../../../utils/constants/user';
import { history, setRolesForUser } from '../../../utils/utils';
import {
    BASE_ROUTES,
    COMPANY_ROUTES,
    INVESTOR_ROUTES,
    LENDER_ROUTES,
} from '../../../utils/constants/routesConst';
import { INVESTOR_ROLES } from '../../investor/constants';
import { Mixpanel } from '../../../utils/mixpanel';
import {
    AICA_PARTNERS_LOGIN,
    AICA_PARTNERS_LOGINOTP,
    INVESTOR_LOG_IN,
    RRF_VIEW_AICA_REPORT,
    UNDERWRITER_SUMMON_AICA,
} from '../../../utils/constants/mixpanelEvents/investorEvents';
import {
    COMPANY_LOGIN,
    COMPANY_LOGIN_WITH_OTP,
} from '../../../utils/constants/mixpanelEvents/companyEvents';

const call = Effects.call;
const put = Effects.put;
const takeLatest = Effects.takeLatest;

function setRolesForMixpanel(role = '') {
    if (role === INVESTOR_ROLES.RESTRICTED_UNDERWRITER_ACCESS) {
        Mixpanel.people.set({
            role: 'Underwriter',
        });
        Mixpanel.register({
            role: 'Underwriter',
        });
        Mixpanel.track(UNDERWRITER_SUMMON_AICA);
    } else if (role === INVESTOR_ROLES.RESTRICTED_INVESTOR_ACCESS) {
        Mixpanel.people.set({
            role: 'RRF Investor',
        });
        Mixpanel.register({
            role: 'RRF Investor',
        });
        Mixpanel.track(RRF_VIEW_AICA_REPORT);
    }
}

export function handleDomainFetchError(referrer: string) {
    const domainMetadataReferrer = localStorage.getItem('domain_metadata_referrer');
    if (!domainMetadataReferrer && referrer) {
        localStorage.setItem('domain_metadata_referrer', referrer);
    }
    history.navigate(BASE_ROUTES.NOT_FOUND);
}

export function handleDomainSuccess() {
    const domainMetadataReferrer = localStorage.getItem('domain_metadata_referrer');
    if (domainMetadataReferrer) {
        history.navigate(domainMetadataReferrer);
    }

    localStorage.removeItem('domain_metadata_referrer');
}

export function handleRedirection(
    accountType,
    orgMasterStatus,
    summonAica = false,
    orgId,
    is404 = false,
    referrer = '',
) {
    if (is404) {
        handleDomainFetchError(referrer);
    }
    switch (accountType) {
        case USER_TYPE.INVESTOR:
        case USER_TYPE.ADMIN_TEAM:
        case USER_TYPE.PARTNER: {
            if (summonAica) {
                history.navigate(`${INVESTOR_ROUTES.APP}/${INVESTOR_ROUTES.PORTFOLIO}/${orgId}`);
            } else if (orgMasterStatus === ORG_MASTER_STATUS.ADDITIONAL_DETAIL_PENDING) {
                history.navigate(INVESTOR_ROUTES.PARTNER_SIGNUP_ADDITIONAL_DETAILS);
            } else {
                history.navigate(`${INVESTOR_ROUTES.APP}/${INVESTOR_ROUTES.SCAN}`);
            }
            return;
        }
        case USER_TYPE.COMPANY: {
            if (orgMasterStatus !== ORG_MASTER_STATUS.ADDITIONAL_DETAIL_COMPLETE) {
                history.navigate(BASE_ROUTES.ADDITIONAL_DETAILS);
            } else history.navigate(COMPANY_ROUTES.UPLOAD);
            return;
        }
        case USER_TYPE.LENDER:
            if (referrer) {
                history.navigate(`${LENDER_ROUTES.APP}/${referrer}`);
                return;
            }
            history.navigate(`${LENDER_ROUTES.APP}/${INVESTOR_ROUTES.DEALS}`);
            return;
        default:
            break;
    }
}

function registerMixpanelEvent(userData: any = {}, apiPayload: any = {}, isOtpLogin = false) {
    if (userData?.accountType === USER_TYPE.PARTNER) {
        const trackingData = {
            role: 'PARTNER',
            investorOrgId: apiPayload?.investorId,
            partnerAccessType: setRolesForUser(userData?.roles?.[0]),
            partnerId: userData?.userId,
            partnerDSAId: userData?.inviteCode,
            websiteUrl: window.location.href,
        };
        if (isOtpLogin) Mixpanel.track(AICA_PARTNERS_LOGINOTP, trackingData);
        else Mixpanel.track(AICA_PARTNERS_LOGIN, trackingData);
    } else if (userData?.accountType === USER_TYPE.INVESTOR) {
        const trackingData = {
            role: userData?.accountType,
            investorOrgId: apiPayload?.investorId,
            accessType: setRolesForUser(userData?.roles?.[0]),
            userId: userData?.userId,
            inviteCode: userData?.inviteCode,
            websiteUrl: window.location.href,
        };
        Mixpanel.track(INVESTOR_LOG_IN, trackingData);
    } else if (userData?.accountType === USER_TYPE.COMPANY) {
        const trackingData = {
            role: userData?.accountType,
            investorOrgId: apiPayload?.investorId,
            userId: userData?.userId,
            websiteUrl: window.location.href,
        };
        if (isOtpLogin) Mixpanel.track(COMPANY_LOGIN_WITH_OTP, trackingData);
        else Mixpanel.track(COMPANY_LOGIN, trackingData);
    }
}

function* createAccountV2(action) {
    try {
        yield put({
            type: CONSTANTS.SET_IS_CREATING_ACCOUNT_V2,
            payload: true,
        });
        const response = yield call(apiService.createV2, action.payload);
        const responseData = response.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                if (!action?.handlers?.isInviteCall)
                    yield put({
                        type: CONSTANTS.CREATE_ACCOUNT_V2_SUCCESS,
                        payload: response?.data?.user || {},
                    });
                // action.handlers?.onSuccess?.(response?.data?.user?.signUpToken);
                action.handlers?.onSuccess?.(response?.data);
                break;
            case JAVA_API_STATUS.ERROR:
                action.handlers?.onError?.();
                yield put({
                    type: CONSTANTS.SET_IS_CREATING_ACCOUNT_V2,
                    payload: false,
                });
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                break; // Add break statement here
            default:
                action.handlers?.onError?.();
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                yield put({
                    type: CONSTANTS.SET_IS_CREATING_ACCOUNT_V2,
                    payload: false,
                });
        }
    } catch (err: any) {
        action.handlers?.onError?.();
        yield put({
            type: CONSTANTS.SET_IS_CREATING_ACCOUNT_V2,
            payload: false,
        });
        console.error(err?.message || 'Some error occoured');
    }
}

function* verifyOtpV2(action) {
    try {
        yield put({
            type: CONSTANTS.SET_LOGGING_IN_V2,
            payload: true,
        });
        const accountType = action.payload.accountType;
        const response = yield call(apiService.loginV2, action.payload.apiPayload);
        const responseData = response.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                addTokenToHeaders(
                    axios,
                    action.payload.apiPayload.user?.investorId,
                    response.data.user.authToken,
                    response.data.user.userId,
                );
                const userData = {
                    accountType: response.data?.user?.accountType,
                    investorOrgId: action.payload.apiPayload.user.investorId,
                    primaryEmail: response.data?.user?.emailId,
                    userId: response.data?.user?.userId,
                    userName: response.data?.user?.name,
                };
                yield put({
                    type: CONSTANTS.VERIFY_OTP_SUCCESS_V2,
                    payload: {
                        data: {},
                        userData: userData,
                    },
                });
                if (response.data?.user?.inviteCode)
                    localStorage.setItem('invite_code', response.data?.user?.inviteCode);
                if (response.data?.user?.platformUserId)
                    localStorage.setItem('platform_user_id', response.data?.user?.platformUserId);

                const payload_fetch = {
                    accountType: response.data?.user?.accountType,
                    primaryEmail: response.data?.user?.emailId,
                    investorOrgId: action.payload.apiPayload.user.investorId,
                };
                registerMixpanelEvent(response.data?.user, action.payload.apiPayload.user, true);
                action?.handlers?.onSuccess?.(userData, response.data?.user?.inviteCode);
                yield call(getConfigAfterLogin, payload_fetch, userData);
                yield put({
                    type: CONSTANTS.SET_LOGGING_IN_V2,
                    payload: false,
                });

                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                yield put({
                    type: CONSTANTS.SET_LOGGING_IN_V2,
                    payload: false,
                });
        }
    } catch (err: any) {
        yield put({
            type: CONSTANTS.SET_LOGGING_IN_V2,
            payload: false,
        });
        console.error(err?.message || 'Some error occoured');
    }
}

function* LoginWithPasswordV2(action) {
    try {
        yield put({
            type: CONSTANTS.SET_LOGGING_IN_V2,
            payload: true,
        });
        const response = yield call(apiService.loginV2, action.payload);
        const responseData = response.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                addTokenToHeaders(
                    axios,
                    action.payload.user?.investorId,
                    response.data.user.authToken,
                    response.data.user.userId,
                );
                const userData = {
                    accountType: response.data?.user?.accountType,
                    investorOrgId: action.payload.user.investorId,
                    primaryEmail: response.data?.user?.emailId,
                    userId: response.data?.user?.userId,
                    userName: response.data?.user?.name,
                };
                yield put({
                    type: CONSTANTS.LOGIN_WITH_PASSWORD_V2_SUCCESS,
                    payload: {
                        data: {},
                        userData: userData,
                    },
                });
                if (response.data?.user?.inviteCode)
                    localStorage.setItem('invite_code', response.data?.user?.inviteCode);
                if (response.data?.user?.platformUserId)
                    localStorage.setItem('platform_user_id', response.data?.user?.platformUserId);
                // handleRedirection(accountType, orgMasterStatus);
                const payload_fetch = {
                    accountType: response.data?.user?.accountType,
                    primaryEmail: response.data?.user?.emailId,
                    investorOrgId: action.payload.user.investorId,
                };
                registerMixpanelEvent(response.data?.user, action.payload.user, false);
                yield call(getConfigAfterLogin, payload_fetch, userData);
                // localStorage.setItem('user_data', JSON.stringify(userData));

                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                yield put({
                    type: CONSTANTS.SET_LOGGING_IN_V2,
                    payload: false,
                });
        }
    } catch (err: any) {
        yield put({
            type: CONSTANTS.SET_LOGGING_IN_V2,
            payload: false,
        });
        console.error(err?.message || 'Some error occoured');
    } finally {
        action.payload?.onComplete?.();
    }
}

function* generatingOtpV2(action) {
    try {
        yield put({
            type: CONSTANTS.SET_GENERATING_OTP_V2,
            payload: true,
        });
        const response = yield call(apiService.generateOtpV2, action.payload);
        const responseData = response.data?.responseData;
        const userData = response.data?.user;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                yield put({
                    type: CONSTANTS.GENERATE_OTP_SUCCESS_V2,
                    payload: {},
                });
                action.payload?.onSuccess?.(userData);
                toast.success('OTP sent successfully');
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                action.payload?.onError?.();
                yield put({
                    type: CONSTANTS.SET_GENERATING_OTP_V2,
                    payload: false,
                });
                break;
        }
    } catch (err: any) {
        yield put({
            type: CONSTANTS.SET_GENERATING_OTP_V2,
            payload: false,
        });
        action.payload?.onError?.();
        console.error(err?.message || 'Some error occoured');
    }
}

function* getConfigAfterLogin(payload, user_data) {
    try {
        localStorage.removeItem('user_data');
        const response = yield call(apiService.fetchConfigAfterLoginService, payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                const userData = user_data;
                const updatedUserData = {
                    ...userData,
                    assesseeOrgId: response.data.assesseeOrgId,
                    investorPrimaryEmail: response.data.investorPrimaryEmail,
                    applicationStatus: response.data.status,
                    userName: response?.data?.userName,
                    lenderOrgId: response?.data?.lenderOrgId,
                };
                yield put({
                    type: CONSTANTS.FETCHING_CONFIG_AFTER_LOGIN_SUCCESS,
                    payload: updatedUserData,
                });
                localStorage.setItem('user_data', JSON.stringify(updatedUserData));
                sessionStorage.setItem('user_data', JSON.stringify(updatedUserData));
                if (response.data?.partnerOrgId)
                    localStorage.setItem('user_org_id', response.data?.partnerOrgId);
                if (response.data?.lenderOrgId)
                    localStorage.setItem('user_org_id', response.data?.lenderOrgId);
                if (response.data?.investorOrgId && localStorage.getItem('user_org_id') === null)
                    localStorage.setItem('user_org_id', response.data?.investorOrgId);

                handleRedirection(
                    updatedUserData.accountType,
                    updatedUserData.applicationStatus,
                    payload.summonAica,
                    payload.orgId,
                );

                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || 'Some error occoured');
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

function* fetchConfigAfterLogin(action) {
    try {
        const response = yield call(apiService.fetchConfigAfterLoginService, action.payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                action.handlers?.onSuccess?.(response.data);
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || 'Some error occoured');
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

function* fetchDomainMetadata(action) {
    try {
        yield put({
            type: CONSTANTS.SET_FETCHING_DOMAIN_METADATA_V2,
            payload: true,
        });
        const response = yield call(apiService.fetchDomainMetadataService, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                yield put({
                    type: CONSTANTS.FETCH_DOMAIN_METADATA_SUCCESS_V2,
                    payload: response?.data?.domainData || {},
                });
                handleDomainSuccess();
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                yield put({
                    type: CONSTANTS.SET_FETCHING_DOMAIN_METADATA_V2,
                    payload: false,
                });
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        handleRedirection('', '', false, '', true, action.payload?.referrer);
        yield put({
            type: CONSTANTS.SET_FETCHING_DOMAIN_METADATA_V2,
            payload: false,
        });
        console.error(err?.message || 'Some error occoured');
    }
}
function* sendResetLink(action) {
    try {
        const response = yield call(apiService.sendResetLinkV2, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                toast.success(
                    responseData?.responseMessage ||
                        'Password reset link sent. Please check your mail.',
                );
                action.payload?.onSuccess?.();
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                action.payload?.onError?.();
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        action.payload?.onError?.();
        console.error(err?.message || 'Some error occoured');
    }
}

function* resetPasswordV2(action) {
    try {
        const response = yield call(apiService.resetPasswordV2, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                toast.success(responseData?.responseMessage || 'Password reset successfully.');
                action.payload?.onSuccess?.(response.data?.user?.accountType);
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                action.payload?.onError?.();
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        action.payload?.onError?.();
        console.error(err?.message || 'Some error occoured');
    }
}
function* loginViaSummonAicaV2(action) {
    try {
        yield put({
            type: CONSTANTS.SET_LOGGING_IN_V2,
            payload: true,
        });
        const response = yield call(apiService.loginV2, action.payload.apiPayload);
        const responseData = response.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                addTokenToHeaders(
                    axios,
                    action.payload.apiPayload?.user?.investorId,
                    response.data.user.authToken,
                    response.data.user.userId,
                );
                const userData = {
                    accountType: response.data?.user?.accountType,
                    investorOrgId: action.payload.apiPayload.user.investorId,
                    primaryEmail: response.data.user.emailId,
                    userId: response.data?.user?.userId,
                    userName: response.data?.user?.name,
                    roles: response.data?.user?.roles,
                };
                setRolesForMixpanel(response.data?.user?.roles?.[0]);
                yield put({
                    type: CONSTANTS.LOGIN_WITH_PASSWORD_V2_SUCCESS,
                    payload: {
                        data: {},
                        userData: userData,
                    },
                });
                const summonAicaPayload = {
                    assesseeOrgId: action.payload.ssoOrgId,
                };
                const summonAicaResponse = yield call(apiService.summonAica, summonAicaPayload);
                const summonAicaResponseData = summonAicaResponse?.data?.responseData;

                const payload_fetch = {
                    accountType: action.payload.apiPayload.user.accountType,
                    primaryEmail: response.data.user.emailId,
                    investorOrgId: action.payload.apiPayload.user.investorId,
                    orgId: action.payload.ssoOrgId,
                    summonAica: true,
                    userRole: userData?.roles?.[0],
                };
                if (response.data?.user?.platformUserId)
                    localStorage.setItem('platform_user_id', response.data?.user?.platformUserId);
                yield call(getConfigAfterLogin, payload_fetch, userData);

                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                yield put({
                    type: CONSTANTS.SET_LOGGING_IN_V2,
                    payload: false,
                });
        }
    } catch (err: any) {
        yield put({
            type: CONSTANTS.SET_LOGGING_IN_V2,
            payload: false,
        });
        console.error(err?.message || 'Some error occoured');
    } finally {
        action.payload?.onComplete?.();
    }
}

function* investorSignup(action) {
    try {
        yield put({
            type: CONSTANTS.SET_IS_CREATING_ACCOUNT_V2,
            payload: true,
        });
        const response = yield call(apiService.investorSignup, action.payload.orgInfo);
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                if (
                    response.data?.domain &&
                    response.data?.subDomain &&
                    response.data?.signupToken
                ) {
                    toast.success(responseData?.responseMessage || 'Signup successful');
                    action.payload?.onSuccess?.(
                        response.data?.domain,
                        response.data?.subDomain,
                        response.data?.signupToken,
                    );
                    yield put({
                        type: CONSTANTS.SET_IS_CREATING_ACCOUNT_V2,
                        payload: false,
                    });
                } else {
                    toast.error(response.data?.message || SOMETHING_WRONG);
                    yield put({
                        type: CONSTANTS.SET_IS_CREATING_ACCOUNT_V2,
                        payload: false,
                    });
                }
                break;
            case JAVA_API_STATUS.ERROR:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                yield put({
                    type: CONSTANTS.SET_IS_CREATING_ACCOUNT_V2,
                    payload: false,
                });
                break;
            default:
                action.payload?.onError?.();
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                yield put({
                    type: CONSTANTS.SET_IS_CREATING_ACCOUNT_V2,
                    payload: false,
                });
        }
    } catch (err: any) {
        action.payload?.onError?.();
        console.error(err?.message || 'Some error occoured');
    }
}

function* addAssessee(action) {
    try {
        const response = yield call(apiService.addAssessee, action?.payload?.payload);
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                action?.payload?.handlers?.onSuccess();
                break;
            case JAVA_API_STATUS.ERROR:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                action?.payload?.handlers?.onError();
                break;
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                action?.payload?.handlers?.onError();
        }
    } catch (err: any) {
        action?.payload?.handlers?.onError();
        console.error(err?.message || 'Some error occoured');
    }
}

function* investorSignupLogin(action) {
    try {
        yield put({
            type: CONSTANTS.SET_LOGGING_IN_V2,
            payload: true,
        });
        const response = yield call(apiService.investorSignupLogin, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                if (response.data?.domain) {
                    toast.success(responseData?.responseMessage || 'Signup successful');
                    action.payload?.onSuccess?.(
                        response.data?.domain,
                        response.data?.subDomain,
                        action.payload?.emailId,
                    );
                    yield put({
                        type: CONSTANTS.SET_LOGGING_IN_V2,
                        payload: false,
                    });
                } else {
                    toast.error(response.data?.message || SOMETHING_WRONG);
                    yield put({
                        type: CONSTANTS.SET_LOGGING_IN_V2,
                        payload: false,
                    });
                }
                break;
            case JAVA_API_STATUS.ERROR:
                yield put({
                    type: CONSTANTS.SET_LOGGING_IN_V2,
                    payload: false,
                });
                break;
            default:
                action.payload?.onError?.();
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                yield put({
                    type: CONSTANTS.SET_LOGGING_IN_V2,
                    payload: false,
                });
        }
    } catch (err: any) {
        action.payload?.onError?.();
        console.error(err?.message || 'Some error occoured');
    }
}

function* logoutUser(action) {
    try {
        const response = yield call(apiService.logoutUserService);
        const responseData = response.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                action.handlers?.onSuccess?.();
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                action.handlers?.onError?.();
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        action.handlers?.onError?.();
        console.error(err?.message || 'Some error occoured');
    }
}

function* verifyEmail(action) {
    try {
        const response = yield call(apiService.verifyEmailService, action.payload);
        const responseData = response.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                action.handlers?.onSuccess?.();
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                action.handlers?.onError?.();
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        action.handlers?.onError?.();
        console.error(err?.message || 'Some error occoured');
    }
}

function* resendVerificationEmail(action) {
    try {
        const response = yield call(apiService.resendEmailVerificationService, action.payload);
        const responseData = response.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                action.handlers?.onSuccess?.();
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        action.handlers?.onError?.();
        console.error(err?.message || 'Some error occoured');
    }
}

export default function* actionWatcher() {
    yield takeLatest(CONSTANTS.FETCH_DOMAIN_METADATA_V2, fetchDomainMetadata);
    yield takeLatest(CONSTANTS.CREATE_ACCOUNT_V2, createAccountV2);
    yield takeLatest(CONSTANTS.LOGIN_WITH_OTP_V2, verifyOtpV2);
    yield takeLatest(CONSTANTS.LOGIN_WITH_PASSWORD_V2, LoginWithPasswordV2);
    yield takeLatest(CONSTANTS.GENERATE_OTP_V2, generatingOtpV2);
    yield takeLatest(CONSTANTS.FORGOT_PASSWORD_V2, sendResetLink);
    yield takeLatest(CONSTANTS.RESET_PASSWORD_V2, resetPasswordV2);
    yield takeLatest(CONSTANTS.LOGIN_FOR_SUMMON_AICA_V2, loginViaSummonAicaV2);
    yield takeLatest(CONSTANTS.INVESTOR_SIGNUP, investorSignup);
    yield takeLatest(CONSTANTS.INVESTOR_SIGNUP_LOGIN, investorSignupLogin);
    yield takeLatest(CONSTANTS.ASSESSEE_ADDITION, addAssessee);
    yield takeLatest(CONSTANTS.LOGOUT_USER, logoutUser);
    yield takeLatest(CONSTANTS.FETCHING_CONFIG_AFTER_LOGIN, fetchConfigAfterLogin);
    yield takeLatest(CONSTANTS.VERIFY_EMAIL, verifyEmail);
    yield takeLatest(CONSTANTS.RESEND_VERIFICATION_EMAIL, resendVerificationEmail);
}
