import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';

import { Input } from '../../../../../../common/_custom';
import {
    GREEN_BG_WHITE_TICK_CIRCLE,
    VERIFY_GREY_CIRCLE,
    VERIFY_PURPLE_CIRCLE,
    verifyOrange,
} from '../../../../../../assets/hostedassets';

import styles from '../DirectorDetails.module.scss';
import LoadingSpinner from '../../../../../../common/_custom/LoadingSpinner/LoadingSpinner';
import FetchFillIcon from '../../../../../../assets/Svg/FetchFilIcon';
import { ONE_DAY_IN_MILLISECONDS } from '../../../../../../utils/constants/commonConstants';

const renderAsset = (asset: string | React.ReactNode) => {
    if (typeof asset === 'string') {
        return <img src={asset} alt="" className={styles.VerifyIcon} />;
    }
    return asset;
};

interface DirectorDetails {
    directorName: string;
    phoneNo: string;
    consentStatus?: boolean;
    lastInviteSentAt?: string;
    isNewDir?: boolean;
    updatedAt?: number;
    cibilConsentStatus?: boolean;
}

const isTimeStampOneDayOld = (timestamp: number, director: DirectorDetails) => {
    const currentTime = new Date().getTime();
    const diff = currentTime - timestamp;
    const val = diff > ONE_DAY_IN_MILLISECONDS;
    return val;
};

interface DirectorInputFieldProps {
    director: DirectorDetails;
    index: number;
    handleChange: (e: string, index: number, name: string) => void;
    sendLink: (name: string, phoneNo: string, setDisableSendLink: Function, input: number) => void;
    errorMessage: string;
    isLoading?: boolean;
    isError: boolean;
    isInvestorSide?: boolean;
}

function DirectorInputField({
    director,
    index,
    handleChange,
    sendLink,
    errorMessage,
    isLoading,
    isError,
    isInvestorSide,
}: DirectorInputFieldProps) {
    const { FormContainer, Field, DisabledBannerText, BannerTextVerified, BannerText } = styles;

    const [disableSendLink, setDisableSendLink] = useState<boolean>(true);
    const reFetchSoftPull =
        isTimeStampOneDayOld(director.updatedAt ?? new Date().getTime(), director) &&
        isInvestorSide;

    const handleSendLinkClick = () => {
        sendLink(director.directorName, director.phoneNo, setDisableSendLink, index);
    };

    const renderFetchButton = () => {
        if (isLoading) {
            return <LoadingSpinner color="var(--primary-text-colour)" />;
        }
        return (
            <div className={styles.VerifyImg}>
                {renderAsset(
                    director.phoneNo.length !== 10 ? (
                        VERIFY_GREY_CIRCLE
                    ) : (
                        <FetchFillIcon
                            colour="var(--primary-text-colour)"
                            height="15"
                            width="15"
                            className={styles.VerifyIcon}
                        />
                    ),
                )}
                <span>{director.cibilConsentStatus ? 'Refetch' : 'Fetch'}</span>
            </div>
        );
    };

    const renderConsentState = () => {
        //refetch button for PNO when time is more than 24 hours
        if (director.consentStatus) {
            return reFetchSoftPull ? (
                <div
                    onClick={handleSendLinkClick}
                    className={clsx(BannerText, {
                        [DisabledBannerText]: !disableSendLink || director.phoneNo.length !== 10,
                    })}
                >
                    {renderFetchButton()}
                </div>
            ) : (
                <div className={BannerTextVerified}>
                    {renderAsset(GREEN_BG_WHITE_TICK_CIRCLE)}
                    <span>Fetched</span>
                </div>
            );
        }
        // Initial fetch button for non-consent state
        return (
            <div
                onClick={handleSendLinkClick}
                className={clsx(BannerText, {
                    [DisabledBannerText]: !disableSendLink || director.phoneNo.length !== 10,
                })}
            >
                {renderFetchButton()}
            </div>
        );
    };

    return (
        <div key={index} className={styles.dflex}>
            <div style={{ width: '40%' }} className={styles.FormContainer}>
                <div className={styles.Field}>{director.directorName}</div>
            </div>
            <div style={{ width: '60%' }} className={FormContainer}>
                <div className={Field}>
                    <Input
                        name="phoneNo"
                        type="text"
                        style={{ margin: 0 }}
                        inputStyle={{
                            backgroundColor: director.consentStatus ? '#ffffff' : '#f9f9f9',
                            borderColor: director.consentStatus ? '#D9D9D9' : 'transparent',
                            borderWidth: '0.5px',
                            borderStyle: 'solid',
                        }}
                        value={director.phoneNo}
                        onChange={(e) => handleChange(e, index, 'phoneNo')}
                        disabled={director.consentStatus}
                        placeholder="Director Mobile No."
                        errorMessage={errorMessage}
                        isValid={!isError}
                    />
                    {director.phoneNo && renderConsentState()}
                </div>
            </div>
        </div>
    );
}
export default DirectorInputField;
