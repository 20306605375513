import React, { useEffect, useState } from 'react';
import styles from './OnePager.module.scss';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import DealSummaryEditor from '../ManageDealDrawer/OnePagerGenerateButton/DealSummaryEditor/DealSummaryEditor';
import { initializeDatepickerDates } from '../../utils/dateUtils';
import {
    getOnePagerData,
    getOnePagerTemplateData,
    saveOnePagerTemplateData,
} from '../../store/investor/investorApiHelper';
import {
    initialiseDealSummaryEditor,
    updateTemplateDataWithPreferredTerms,
} from '../ManageDealDrawer/OnePagerGenerateButton/DealSummaryEditor/dealSummaryEditorInitializer';
import { closeOnePager, setOnePagerData } from '../../store/investor/action';
import { useParams } from 'react-router-dom';
import { Button, Modal } from '../_custom';

interface OnePagerPropsForCreateDeal {
    editorExistingData?: Object;
    metadataUpdate?: (fileInfo) => void;
    categoryId?: number;
    flowId?: number;
}

type OnePagerProps = OnePagerPropsForCreateDeal;

function OnePager({ editorExistingData, metadataUpdate, categoryId, flowId }: OnePagerProps) {
    const dispatch = useAppDispatch();
    const params = useParams();
    const [generated, setGenerated] = useState(false);

    // const [generating, setGenerating] = useState(false);
    const [editorInitialData, setEditorInitialData] = useState<any>(null);
    const [editorReady, setEditorReady] = useState(false);
    const [showRegenConfirmModal, setShowRegenConfirmModal] = useState(false);

    const existingEditorContent = localStorage.getItem('one_pager_content');
    const existingEditorContentOrgId = localStorage.getItem('one_pager_assessee');

    const companyMainImg = useAppSelector((state) => state.user.domainMetadata?.mainImageUrl);
    const investorOrgId = useAppSelector((state) => state.user.userData.investorOrgId);
    const onePagerState = useAppSelector((state) => state.investor.onePager);
    const assesseeOrgId = onePagerState.assesseeOrgId;
    const dealFormData = onePagerState.dealFormData;
    const source = onePagerState.source;

    // const isRegenerateRef = useRef<null | boolean>(null);

    useEffect(() => {
        return () => {
            dispatch(closeOnePager());
        };
    }, []);

    useEffect(() => {
        if (
            assesseeOrgId &&
            existingEditorContentOrgId &&
            existingEditorContent &&
            assesseeOrgId === existingEditorContentOrgId
        ) {
            const data = JSON.parse(existingEditorContent);
            data && setEditorInitialData(data);
        } else {
            fetchOnePagerTemplateData();
        }
    }, [assesseeOrgId, existingEditorContentOrgId, existingEditorContent]);

    useEffect(() => {
        if (editorInitialData) {
            setEditorReady(true);
        }
    }, [editorInitialData]);

    const fetchDataForOnePager = (isRegenerate = false) => {
        if (!isRegenerate) {
            setEditorReady(false);
        }
        dispatch(setOnePagerData({ loading: true }));
        const [fromDate, toDate] = initializeDatepickerDates('Monthly');
        getOnePagerData({
            orgId: assesseeOrgId as string,
            startDate: fromDate,
            endDate: toDate,
        })
            .then((data = {}) => {
                setEditorInitialData(initialiseDealSummaryEditor(data, dealFormData || {}));
            })
            .finally(() => {
                setTimeout(() => {
                    setEditorReady(true);
                    dispatch(setOnePagerData({ loading: false }));
                }, 200);
            });
    };

    const fetchOnePagerTemplateData = () => {
        setEditorReady(false);
        dispatch(setOnePagerData({ loading: true }));
        getOnePagerTemplateData({
            assesseeOrgId: assesseeOrgId as string,
            investorOrgId: investorOrgId,
        })
            .then((data) => {
                if (data?.length) {
                    if (dealFormData) {
                        setEditorInitialData(
                            updateTemplateDataWithPreferredTerms(data, dealFormData),
                        );
                    } else setEditorInitialData(data);
                    setTimeout(() => {
                        setEditorReady(true);
                        dispatch(setOnePagerData({ loading: false }));
                    }, 200);
                } else fetchDataForOnePager();
            })
            .catch(() => {});
    };

    const saveOnePagerTemplateDataForOrg = (editorContent) => {
        saveOnePagerTemplateData({
            assesseeOrgId: assesseeOrgId as string,
            investorOrgId: investorOrgId,
            onePagerData: editorContent || [],
        })
            .then((data) => {
                setTimeout(() => {
                    dispatch(closeOnePager());
                }, 1000);
            })
            .catch(() => {});
    };

    const onMetadataUpdateFunction = (fileInfo) => {
        metadataUpdate?.(fileInfo);
    };

    return (
        <div className={styles.OnePager}>
            {editorReady && (
                <DealSummaryEditor
                    source={source}
                    generating={onePagerState.loading}
                    openModal={editorReady}
                    closeModal={() => dispatch(closeOnePager())}
                    categoryId={categoryId}
                    flowId={flowId}
                    assesseeOrgId={assesseeOrgId}
                    metadataUpdate={onMetadataUpdateFunction}
                    editorInitialData={editorInitialData}
                    editorReady={editorReady}
                    saveOnePagerTemplateDataForOrg={saveOnePagerTemplateDataForOrg}
                    setShowRegenConfirmModal={setShowRegenConfirmModal}
                />
            )}

            {showRegenConfirmModal && (
                <Modal
                    open={showRegenConfirmModal}
                    onClose={() => setShowRegenConfirmModal(false)}
                    size="flexible"
                >
                    <div className={styles.ConfirmationModal}>
                        <div className={styles.Title}>Regenerate One Pager</div>
                        <div className={styles.Subtext}>
                            Are you sure you want to regenerate One pager? You will lose all the
                            changes made so far.
                        </div>
                        <div className={styles.Buttons}>
                            <Button
                                text={'Cancel'}
                                onClick={() => {
                                    setShowRegenConfirmModal(false);
                                }}
                                variant={'purpleinverted'}
                                style={{ marginTop: '0' }}
                            />
                            <Button
                                text={'Proceed Anyway'}
                                onClick={() => {
                                    setShowRegenConfirmModal(false);
                                    fetchDataForOnePager(true);
                                }}
                                variant={'primary'}
                                style={{ marginTop: '0' }}
                            />
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
}

export default OnePager;
