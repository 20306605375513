import { Dispatch, SetStateAction, useState } from 'react';
import MagicUploadFilesTable from './MagicUploadFilesTable/MagicUploadFilesTable';
import MagicUploadCenter from './MagicUploadCenter/MagicUploadCenter';
import { MagicUploadFileData } from '../useFetchDataForMU';
import { useAppSelector } from '../../../app/hooks';

type DrawerContentProps = {
    tabState: string;
    setTabState: Dispatch<SetStateAction<'new' | 'tagged' | 'all'>>;
    toggleDrawer: () => void;
    setFileData: Dispatch<SetStateAction<MagicUploadFileData[]>>;
    fileData: MagicUploadFileData[];
};

const DrawerContent = (props: DrawerContentProps) => {
    const { tabState, setTabState, toggleDrawer, fileData, setFileData } = props;
    const untaggedCount = useAppSelector((state) => state.investor.untaggedCount);

    return (
        <div style={{ position: 'relative', height: '100%' }}>
            {tabState === 'all' && (
                <div style={{ height: '30%' }}>
                    <MagicUploadCenter
                        fileData={fileData}
                        setFileData={setFileData}
                        tabState={tabState}
                        orientation={'vertical'}
                    />
                </div>
            )}
            <MagicUploadFilesTable
                fileData={fileData}
                setFileData={setFileData}
                tabState={tabState}
            />
        </div>
    );
};

export default DrawerContent;
