import React, { useEffect } from 'react';
import { getDataGapDocs, UpdateClarification } from '../../../MagicUpload/MagicUploadHelper';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../../../app/hooks';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { updateNeedInfoFileNumber } from '../../../../store/investor/action';
import { updateQueryParams } from '../../../../utils/searchParamUtils';
import { CLARIFICATION_TYPE } from '../../../MagicUpload/Utils/MagicUploadConstants';

// Define the structure of the API response
interface DataGapItem {
    primaryMessage: string;
    dataGapId: string;
    secondaryMessage: string;
    adminStatus: string; // "MARKED" or "UNMARKED"
    clarificationType: string;
    sampleFileLink?: string;
}

export interface DocumentItem {
    id: string; // Unique identifier for internal use
    name: string; // Maps to primaryMessage
    details: string; // Maps to secondaryMessage
    completed: boolean; // Derived from adminStatus
    clarificationType: string; // Maps to clarificationType
    sampleFileLink?: string;
}

// Custom hook
const useDataGapDocument = (
    source: string,
    docList: DocumentItem[],
    setDocList: React.Dispatch<React.SetStateAction<DocumentItem[]>>,
    setLastUpdatedAt?: React.Dispatch<React.SetStateAction<number>>,
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const user = useAppSelector((state) => state.user.userData);
    const dispatch = useDispatch();

    const fetchDocuments = async () => {
        if (setLoading) setLoading(true);
        try {
            await getDataGapDocs(
                {
                    assesseeOrgId: params.id ?? '',
                },
                {
                    onSuccess: (data) => {
                        const formattedData = data?.dataGapList
                            ?.map((item: DataGapItem) => ({
                                id: item.dataGapId,
                                name: item.primaryMessage,
                                details: item.secondaryMessage,
                                completed: item.adminStatus === 'MARKED',
                                clarificationType: item.clarificationType, // Ensure this field is available in the API data
                                sampleFileLink: item.sampleFileLink,
                            }))
                            .sort((a, b) => {
                                // First-level sort: `MARKED` items at the bottom
                                const completedDiff = Number(a.completed) - Number(b.completed);
                                if (completedDiff !== 0) return completedDiff;

                                // Second-level sort: `SYSTEM_GENERATED` items at the top
                                return (
                                    (b.clarificationType === 'SYSTEM_GENERATED' ? 1 : 0) -
                                    (a.clarificationType === 'SYSTEM_GENERATED' ? 1 : 0)
                                );
                            });
                        if (!data.isSendReminderEnabled) {
                            updateQueryParams(
                                searchParams,
                                setSearchParams,
                                {
                                    disableSendReminder: true,
                                },
                                location,
                            );
                        } else {
                            searchParams.delete('disableSendReminder');
                            updateQueryParams(searchParams, setSearchParams, {}, location);
                        }
                        if (setLastUpdatedAt) setLastUpdatedAt(data.lastUpdated);
                        dispatch(updateNeedInfoFileNumber(data.pendingCount));
                        setDocList(formattedData);
                    },
                    onError: () => {
                        toast.error('Failed to fetch pending documents');
                    },
                },
            );
        } catch (error) {
            console.error('Error fetching documents:', error);
        } finally {
            if (setLoading) setLoading(false);
        }
    };

    useEffect(() => {
        if (source === 'needInfo') fetchDocuments();
    }, [source]);

    const toggleDocumentCompletion = async (id: string, isDelete: boolean, item?: DocumentItem) => {
        let payload = {};
        if (!id) {
            payload = {
                dataGapInfo: {
                    clarificationType: item?.clarificationType,
                    primaryMessage: item?.name,
                    secondaryMessage: item?.details,
                    adminStatus: item?.completed ? 'DELETED' : 'MARKED',
                    sampleFileLink: item?.sampleFileLink,
                },
                adminStatus: item?.completed ? 'DELETED' : 'MARKED',
                updatedByUserId: user.userId,
                updatedByUserName: user.userName,
                assesseeOrgId: params.id as string,
            };
        } else {
            payload = {
                dataGapId: id,
                updateClarificationMessage: docList.find((doc) => doc.id === id)?.name ?? '',
                assesseeOrgId: params.id ?? '',
                adminStatus:
                    isDelete || item?.clarificationType === CLARIFICATION_TYPE.SYSTEM_GENERATED
                        ? 'DELETED'
                        : !docList.find((doc) => doc.id === id)?.completed
                        ? 'MARKED'
                        : 'UNMARKED',
                updatedByUserId: user.userId,
                updatedByUserName: user.userName,
            };
        }
        await UpdateClarification(payload, {
            onSuccess: (dataGapId) => {
                if (!isDelete) {
                    // Find the document using the item passed in the function as I don't have the id in this case and then update the completed status
                    if (!id) {
                        setDocList((prevList) =>
                            prevList.map((d) => {
                                if (d.name === item?.name) {
                                    return { ...d, completed: !d.completed, id: dataGapId };
                                }
                                return d;
                            }),
                        );
                    } else {
                        // Update the document list with the new completion status
                        setDocList((prevList) =>
                            prevList.map((d) =>
                                d.id === id ? { ...d, completed: !d.completed } : d,
                            ),
                        );
                    }
                } else {
                    setDocList((prevList) => prevList.filter((d) => d.id !== id));
                }
            },
            onError: () => {
                toast.error('Failed to mark clarification');
            },
        });
    };

    return { docList, toggleDocumentCompletion, fetchDocuments };
};

export default useDataGapDocument;
