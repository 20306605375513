import { API_STATUS, JAVA_API_STATUS } from '../../../../../../../utils/axios-interceptor';
import {
    downloadFilePresigned,
    downloadFileUsingURL,
    fetchSignedUrlToUpload,
    getDocMetaData,
    saveKycDocs,
    saveMetaDataInDocService,
    uploadFileApi,
} from './services';
import { toast } from 'react-toastify';

export const getSignedUrl = async (fileData: any, uploadConfig) => {
    const data = {
        fileName: fileData?.file?.name,
        fileSizeKb: fileData?.file?.size / 1_000,
        fileType: fileData.file.type,
        categoryId: uploadConfig.categoryId,
        mnemosyneOrgId: uploadConfig.mnemosyneOrgId,
        ...(!!uploadConfig?.accountNumber && { dk1: uploadConfig?.accountNumber }),
    };
    try {
        const res = await fetchSignedUrlToUpload(data, uploadConfig.assesseeOrgId);
        if (res?.data?.responseData?.responseCode === 'OK') {
            return res.data;
        }
        throw new Error(res?.data?.responseData?.responseMessage ?? 'Some error occurred');
    } catch (err) {
        console.error(err);
        return false;
    }
};

export const uploadFile = async (fileData: any, signedURL) => {
    try {
        const uploadResponse = await uploadFileApi(signedURL, fileData);
        return uploadResponse;
    } catch (err) {
        console.error(err);
        return {
            url: '',
        };
    }
};

export const saveDocMetaDataInDocService = async ({
    input,
    orgId,
}: {
    input: object;
    orgId: string;
}): Promise<any> => {
    try {
        const res = await saveMetaDataInDocService(input, orgId);
        if (res?.data?.responseData?.responseCode === API_STATUS.MNEMOSYNE_SUCCESS)
            return res?.data?.fileMetadata;
        throw new Error(res?.data?.responseData?.responseMessage ?? 'Some error occurred');
    } catch (err) {
        console.error('Error occurred in saveDocMetaDataInDocService : ' + err);
        // captureMessagesViaErrorLogger(err);
    }
};

export const downloadFile = async (
    docId,
    onSuccess = () => {},
    fileName?: string,
    onError = () => {},
    fileType = null,
) => {
    const response = await getPresignedUrl({ docId: Number(docId) });

    const url = new URL(response?.uri);
    const fileNameFromUrl = url?.pathname?.split('/')?.pop() || null;
    if (response) {
        const url = response.uri;
        // use the above url and create a blob and download the file
        const res = await fetch(url);
        if (res.status === 200) {
            const blob = await res.blob();
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName || fileNameFromUrl || 'file';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toast.success('File Downloaded Successfully');
        } else {
            toast.error('Error in downloading file');
        }
        if (typeof onSuccess === 'function') onSuccess?.();
    } else {
        onError?.();
        console.error('Error occurred while downloading file with docId: ' + docId);
    }
};

export const getPresignedUrl = async ({ docId }: { docId: number }): Promise<any> => {
    const getFileType = (mimeType: string): string => {
        if (mimeType === 'application/.pdf') return 'application/pdf';
        if (mimeType === 'application/text') return 'text/plain';
        return mimeType;
    };
    try {
        const res = await downloadFilePresigned(docId);
        if (res?.data?.responseData?.responseCode === API_STATUS.MNEMOSYNE_SUCCESS)
            return {
                uri: res?.data?.signedUrl,
                fileType: getFileType(res?.data?.mimeType),
            };
        throw new Error(res?.data?.responseData?.responseMessage ?? 'Some error occurred');
    } catch (err) {
        console.error(err);
    }
};

export const downloadFileUsingURLHelper = async (url: string, bucketName: string) => {
    try {
        const res: any = await downloadFileUsingURL(url, bucketName);
        if (res.status === 200) {
            return res.data.signedUrl;
        } else {
            toast.error('Error in downloading file');
        }
    } catch (err) {
        console.error(err);
    }
};
export const saveKycDocsHelper = async (data: any, handler: any) => {
    try {
        const res: any = await saveKycDocs(data);
        if (res.data.responseData.responseCode === JAVA_API_STATUS.SUCCESS) {
            handler?.onSuccess();
        } else {
            toast.error('Failed to upload file, Please Try Again');
        }
    } catch (err) {
        console.error(err);
    }
};

export const getDocMetaDataHelper = async (docId: string) => {
    try {
        const res: any = await getDocMetaData(docId);
        if (res.status === 200) {
            return res.data;
        } else {
            toast.error('Failed to fetch doc meta data, Please Try Again');
        }
    } catch (err) {
        console.error(err);
    }
};
