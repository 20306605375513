import React, { useState } from 'react';
import styles from './MagicUpload.module.scss';
import { useSearchParams } from 'react-router-dom';
import DrawerContent from './components/DrawerContent';
import useMagicUploadLogic from './useMagicUploadLogic';
import NeedInfo from '../NeedInfo/NeedInfo';
import {
    MagicUploadLoading,
    MagicUploadSelected,
    MagicUploadUnselected,
    needInfoSelected,
    needInfoUnselected,
} from '../../assets/hostedassets';
import closeIcon from '../_custom/SideDrawer/close.svg';
import { MAGIC_UPLOAD, NEED_INFO } from './Utils/MagicUploadConstants';
import MagicUploadTabSelector from './components/MagicUploadTabSelector/MagicUploadTabSelector';
import { MagicUploadFileData } from './useFetchDataForMU';
import AIText from '../AIText/AIText';
import { useAppSelector } from '../../app/hooks';
import { USER_TYPE } from '../../utils/constants/user';

type MagicUploadProps = {};
const MagicUpload: React.FC<MagicUploadProps> = (props: MagicUploadProps) => {
    const [searchParams] = useSearchParams();
    const [tabState, setTabState] = useState<'new' | 'tagged' | 'all'>('all');
    const [loading, setLoading] = useState(false);
    const [fileData, setFileData] = useState<MagicUploadFileData[]>([]);
    const { isVisible, toggleDrawer, drawerContent, changeDrawerContent } = useMagicUploadLogic();
    const user = useAppSelector((state) => state.user);
    const isPartner = user.userData.accountType === USER_TYPE.PARTNER;
    const permissions = useAppSelector((state) => state.investor.permissions);
    const showMu =
        (!isPartner && permissions.showMagicUpload) ||
        user.userData.accountType === USER_TYPE.COMPANY;
    return (
        <>
            <div
                data-testid="backdrop"
                className={`${styles.backdrop} ${isVisible ? styles.show : ''}`}
                onClick={toggleDrawer}
            ></div>
            <div className={`${styles.drawer} ${isVisible ? styles.show : ''}`}>
                <div className={styles.DrawerHeader}>
                    <div className={styles.DrawerContentSelector}>
                        <div className={styles.DrawerTabsContainer}>
                            {searchParams.get('showNeedInfo') === 'true' && (
                                <div
                                    onClick={() => changeDrawerContent(NEED_INFO)}
                                    className={
                                        drawerContent === NEED_INFO
                                            ? styles.ActiveDrawerTab
                                            : styles.DrawerTab
                                    }
                                >
                                    <div
                                        className={
                                            drawerContent === NEED_INFO
                                                ? styles.ActiveTab
                                                : styles.NotActiveTab
                                        }
                                    >
                                        <img src={needInfoSelected} alt="NeedInfo" />
                                        <span
                                            className={
                                                drawerContent === NEED_INFO
                                                    ? styles.ActiveText
                                                    : styles.Text
                                            }
                                        >
                                            Data Pending
                                        </span>
                                    </div>
                                </div>
                            )}
                            {showMu && (
                                <div
                                    onClick={() => changeDrawerContent(MAGIC_UPLOAD)}
                                    className={
                                        drawerContent === MAGIC_UPLOAD
                                            ? styles.ActiveDrawerTab
                                            : styles.DrawerTab
                                    }
                                >
                                    <div
                                        className={
                                            drawerContent === MAGIC_UPLOAD || loading
                                                ? styles.ActiveTab
                                                : styles.NotActiveTab
                                        }
                                    >
                                        <img
                                            src={
                                                drawerContent === MAGIC_UPLOAD
                                                    ? MagicUploadSelected
                                                    : loading
                                                    ? MagicUploadLoading
                                                    : MagicUploadSelected
                                            }
                                            alt="NeedInfo"
                                        />
                                        <span
                                            className={
                                                drawerContent === MAGIC_UPLOAD
                                                    ? styles.ActiveText
                                                    : styles.Text
                                            }
                                        >
                                            {loading && drawerContent !== MAGIC_UPLOAD ? (
                                                <AIText text={'Uploading'} />
                                            ) : (
                                                'Magic Upload'
                                            )}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div
                            className={`${styles.TabSlideRightContianer} ${
                                drawerContent === MAGIC_UPLOAD ? styles.showTabs : styles.hideTabs
                            }`}
                        >
                            <MagicUploadTabSelector tabState={tabState} setTabState={setTabState} />
                        </div>
                    </div>
                    <div className={styles.closeIcon}>
                        <img onClick={() => toggleDrawer()} src={closeIcon} alt="close" />
                    </div>
                </div>
                {drawerContent === NEED_INFO ? (
                    <NeedInfo
                        setLoading={setLoading}
                        toggleDrawer={toggleDrawer}
                        tabState={tabState}
                        fileData={fileData}
                        setFileData={setFileData}
                    />
                ) : (
                    <DrawerContent
                        setFileData={setFileData}
                        fileData={fileData}
                        setTabState={setTabState}
                        tabState={tabState}
                        toggleDrawer={toggleDrawer}
                    />
                )}
            </div>
        </>
    );
};

export default MagicUpload;
