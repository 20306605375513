import React, { useCallback, useEffect, useState } from 'react';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact } from 'slate-react';
import Toolbar from './Toolbar/Toolbar';
import { getBlock, getMarked, toggleMark } from './utils/SlateUtilityFunctions.js';
import withLinks from './plugins/withLinks.js';
import withTables from './plugins/withTable.js';
import withEmbeds from './plugins/withEmbeds.js';
import withEquation from './plugins/withEquation.js';
import styles from './TextEditor.module.scss';
import TextEditorShimmerLoader from './TextEditorShimmerLoader';

const Element = (props) => {
    return getBlock(props);
};
const Leaf = (props) => {
    let { attributes, children, leaf } = props;
    children = getMarked(leaf, children);
    return <span {...attributes}>{children}</span>;
};
const SlateEditor = ({
    source,
    editorContentRef,
    editorInitialData,
    targetRef,
    assesseeOrgId,
    generating,
    onClose,
}) => {
    const [editor] = useState(() =>
        withEquation(withHistory(withEmbeds(withTables(withLinks(withReact(createEditor())))))),
    );

    useEffect(() => {
        if (source === 'CREATE_DEAL') {
            localStorage.setItem('one_pager_content', JSON.stringify(editorInitialData));
            localStorage.setItem('one_pager_assessee', assesseeOrgId);
        }
        editor.children = editorInitialData;
        editor.onChange();
    }, [editorInitialData, source]);

    const handleEditorChange = (value) => {
        const isAstChange = editor.operations.some((op) => 'set_selection' !== op.type);
        if (isAstChange && source === 'CREATE_DEAL') {
            // Save the value to Local Storage.
            const content = JSON.stringify(value);
            // localStorage.setItem('one_pager_content', content);
            // localStorage.setItem('one_pager_assessee', assesseeOrgId);
        }
        editorContentRef.current = value;
    };

    const handleKeyDown = useCallback(
        (event) => {
            if (event.metaKey) {
                switch (event.key) {
                    case 'b': {
                        event.preventDefault();
                        toggleMark(editor, 'bold');
                        break;
                    }
                    case 'i': {
                        event.preventDefault();
                        toggleMark(editor, 'italic');
                        break;
                    }
                    case 'u': {
                        event.preventDefault();
                        toggleMark(editor, 'underline');
                        break;
                    }
                    case 's': {
                        event.preventDefault();
                        toggleMark(editor, 'strikethrough');
                        break;
                    }
                    default:
                        break;
                }
            }
        },
        [editor],
    );

    const renderElement = useCallback((props) => <Element {...props} />, []);

    const renderLeaf = useCallback((props) => {
        return <Leaf {...props} />;
    }, []);

    const [htmlAction, setHtmlAction] = useState({
        showInput: false,
        html: '',
        action: '',
        location: '',
    });

    const handleCodeToText = (partialState) => {
        setHtmlAction((prev) => ({
            ...prev,
            ...partialState,
        }));
    };

    return (
        <div className={styles.SlateWrapper}>
            <Slate editor={editor} initialValue={editorInitialData} onChange={handleEditorChange}>
                <Toolbar handleCodeToText={handleCodeToText} onClose={onClose} />
                {generating ? (
                    <TextEditorShimmerLoader />
                ) : (
                    <div
                        className={styles.EditorWrapper}
                        ref={targetRef}
                        id="element-to-be-downloaded-as-pdf"
                    >
                        <Editable
                            placeholder="Write your text here..."
                            renderElement={renderElement}
                            renderLeaf={renderLeaf}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                )}
            </Slate>
        </div>
    );
};

export default SlateEditor;
