import styles from './NeedInfo.module.scss';
import useNeedInfoDrawerLogic from './customLogic/useNeedInfoDrawerLogic';
import PendingDocuments from './Components/PendingDocuments/PendingDocuments';
import React, { useState } from 'react';
import ClarificationDrawer from './Components/ClarificationDrawer/ClarificationDrawer';
import { DocumentItem } from './Components/PendingDocuments/useDataGapDocument';
import MagicUploadCenter from '../MagicUpload/components/MagicUploadCenter/MagicUploadCenter';
import { MagicUploadFileData } from '../MagicUpload/useFetchDataForMU';
import dayjs from 'dayjs';
import { returnDaysAgo } from '../../utils/utils';
import { useAppSelector } from '../../app/hooks';
import { USER_TYPE } from '../../utils/constants/user';
import Button from '../_custom/Button/Button';
import CheckBox from '../CheckBox/CheckBox';
import infoIcon from '../../assets/info.svg';
import Tooltip from '../_custom/Tooltip/Tooltip';
import { useSearchParams } from 'react-router-dom';

type NeedInfoProps = {
    toggleDrawer: () => void;
    tabState: string;
    fileData: MagicUploadFileData[];
    setFileData: React.Dispatch<React.SetStateAction<MagicUploadFileData[]>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const NeedInfo = (props: NeedInfoProps) => {
    const [isMiniDrawerOpen, setMiniDrawerOpen] = useState(false);
    const [docList, setDocList] = useState<DocumentItem[]>([]);
    const [selectedDocument, setSelectedDocument] = useState<DocumentItem | null>(null);
    const [docFetching, setDocFetching] = useState(false);
    const pendingCount = useAppSelector((state) => state.investor.pendingDataCount);
    const {
        toggleMiniDrawer,
        communicationMethod,
        setCommunicationMethod,
        sendNotification,
        userList,
        loading,
    } = useNeedInfoDrawerLogic(setMiniDrawerOpen);
    const [lastUpdatedAt, setLastUpdatedAt] = useState<number>(dayjs().valueOf());
    const { tabState, fileData, setFileData, setLoading } = props;
    const user = useAppSelector((state) => state.user);
    const isPartner = user.userData.accountType === USER_TYPE.PARTNER;
    const permissions = useAppSelector((state) => state.investor.permissions);
    const [searchParams] = useSearchParams();
    const showMu =
        (!isPartner && permissions.showMagicUpload) ||
        user.userData.accountType === USER_TYPE.COMPANY;

    const returnDay = (lastUpdatedAt: number) => {
        let daysAgo = '';
        try {
            daysAgo = returnDaysAgo(dayjs(lastUpdatedAt).format('YYYY-MM-DD'));
        } catch (e) {
            console.log(e);
            daysAgo = '';
        }
        return daysAgo;
    };
    const tooltipContent = () => {
        if (userList.length === 0) return <div>No user added</div>;
        return userList.map((user) => {
            return (
                <div key={user.userId}>
                    <span style={{ color: '#4D4D4D' }}>{user.name}</span> (
                    {user.emailVerified ? (
                        user.emailId
                    ) : (
                        <span style={{ color: '#cbcbcb' }}>Email not verified</span>
                    )}{' '}
                    | {user.mobileNumber})
                </div>
            );
        });
    };

    return (
        <>
            <div className={styles.NeedInfo}>
                <PendingDocuments
                    setLastUpdatedAt={setLastUpdatedAt}
                    docList={docList}
                    setDocList={setDocList}
                    setSelectedDocument={setSelectedDocument}
                    toggleMiniDrawer={toggleMiniDrawer}
                    setLoading={setDocFetching}
                />
                {showMu && (
                    <MagicUploadCenter
                        componentStyle={{
                            height: '10%',
                            padding: 0,
                            margin: '0 1rem',
                            borderRadius: '8px',
                        }}
                        setLoading={setLoading}
                        fileData={fileData}
                        setFileData={setFileData}
                        tabState={tabState}
                        orientation={'horizontal'}
                        folderDimension={{
                            height: '20',
                            width: '20',
                        }}
                    />
                )}
                <div className={styles.footer}>
                    <div>
                        <Button
                            style={{ margin: '0', fontSize: '0.875rem', padding: '0.75rem 1rem' }}
                            variant={'secondary'}
                            text={'Add Clarifications'}
                            type={'button'}
                            onClick={toggleMiniDrawer}
                        />
                    </div>
                    <div className={styles.Dflex}>
                        <span className={styles.FooterText}>
                            Last Activity {returnDay(lastUpdatedAt)}
                        </span>
                        <Tooltip
                            content={tooltipContent()}
                            style={{ left: '1rem', width: 'max-content' }}
                            direction="top"
                            small={true}
                        >
                            <div
                                style={{ gap: '0.25rem', cursor: 'pointer' }}
                                className={styles.Dflex}
                            >
                                <img src={infoIcon} alt="score" height={'16px'} width={'16px'} />
                                <span className={styles.UserList}>User List</span>
                            </div>
                        </Tooltip>
                        <CheckBox
                            label={'Email'}
                            checked={communicationMethod.EMAIL}
                            onCheck={() => {
                                setCommunicationMethod({
                                    ...communicationMethod,
                                    EMAIL: !communicationMethod.EMAIL,
                                });
                            }}
                            variant={'primary'}
                            needPadding={communicationMethod.EMAIL}
                        />
                        <Button
                            style={{ margin: '0', fontSize: '0.875rem', padding: '0.75rem 1rem' }}
                            text={'Send Reminder'}
                            disabled={
                                loading ||
                                !communicationMethod.EMAIL ||
                                userList.length === 0 ||
                                docFetching ||
                                !pendingCount ||
                                pendingCount === 0 ||
                                searchParams.get('disableSendReminder') === 'true'
                            }
                            onClick={() => sendNotification()}
                            variant={'primary'}
                        />
                    </div>
                </div>
            </div>
            <ClarificationDrawer
                selectedDocument={selectedDocument}
                docList={docList}
                setDocList={setDocList}
                isMiniDrawerOpen={isMiniDrawerOpen}
                toggleMiniDrawer={toggleMiniDrawer}
            />
        </>
    );
};

export default NeedInfo;
