import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
    InternalAxiosRequestConfig,
} from 'axios';
import isEmpty from 'lodash/isEmpty';
import { NavigateFunction } from 'react-router-dom';

/**
 * @Description
 * This file intended to intercept incoming request and response.
 * Main utility will to authorize the user else redirect to login page.
 * or we want the error to display api failure and sentry logs can be put here.
 */

export const API_STATUS = {
    SUCCESS: 'Success',
    ERROR: 'Error',
    MNEMOSYNE_SUCCESS: 'OK',
};

export const JAVA_API_STATUS = {
    SUCCESS: 20,
    ERROR: 40,
};

export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const DELTE = 'DELETE';
export const SOMETHING_WRONG = 'Something went wrong';

export interface CustomRequest extends AxiosRequestConfig {
    isAuthRequired?: boolean;
}

export interface CommonResponse {
    status: 'Success' | 'Failed';
    data: object;
}

let source = axios.CancelToken.source();
export const uwBaseUrl = process.env.REACT_APP_UNDERWRITING_ENGINE_URL;
// export const uwBaseUrl = 'http://localhost:8000/underwriting'; //underwriting_engine local
export const coreBaseUrl = `${process.env.REACT_APP_API_URL}/api`;
export const polusBaseUrl = process.env.REACT_APP_POLUS_URL;
// export const polusBaseUrl = 'http://localhost:8084/polus/rest';
// export const polusLocalBaseUrl = 'http://localhost:8084/polus/rest';

export const bablerBaseUrl = process.env.REACT_APP_BABLER_URL;
// export const bablerBaseUrl = 'http://localhost:8081/babler/rest';

export const olumpusBaseUrl = process.env.REACT_APP_OLUMPUS_URL;
// export const olumpusBaseUrl = 'http://localhost:8083/olympus/rest';

export const cerberusBaseUrl = process.env.REACT_APP_CERBERUS_URL;
// export const cerberusBaseUrl = 'http://localhost:8085/cerberus/rest';
export const aicaBaseUrl = process.env.REACT_APP_AICA_URL;
export const aicaLocalBaseUrl = 'http://localhost:8080/aica/rest/';
export const mnemosyneBaseUrl = process.env.REACT_APP_MNEMOSYNE_API_URL;
// export const coreBaseUrl = 'http://localhost:8070'; //coreapi local
export const utilBaseUrl = process.env.REACT_APP_WEBUTILS_API_URL;

export interface CustomRequest extends AxiosRequestConfig {
    isAuthRequired?: boolean;
}

export interface CommonResponse {
    status: 'Success' | 'Failed';
    data: object;
}

const onRequest = (
    config: InternalAxiosRequestConfig<any> | Promise<InternalAxiosRequestConfig<any>>,
    navigate: NavigateFunction,
): InternalAxiosRequestConfig<any> | Promise<InternalAxiosRequestConfig<any>> => {
    return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    // captureMessagesViaErrorLogger(error.message);
    return Promise.reject(error);
};

const onResponse = (response: AxiosResponse, navigate: NavigateFunction): AxiosResponse => {
    return response;
};

const onResponseError = (error: AxiosError, navigate: NavigateFunction): Promise<AxiosError> => {
    if (error.response?.status === 401) {
        // source.cancel();
        if (location.pathname.includes('investor/')) navigate('/investor/login');
        else navigate('/login');
    }
    return Promise.reject(error);
};

export function applyInterceptor(
    axiosInstance: AxiosInstance,
    navigate: NavigateFunction,
): AxiosInstance {
    axiosInstance.defaults.baseURL = aicaBaseUrl;
    axiosInstance.defaults.cancelToken = source.token;
    axiosInstance.interceptors.request.use((req) => onRequest(req, navigate), onRequestError);
    axiosInstance.interceptors.response.use(
        (res) => onResponse(res, navigate),
        (err) => onResponseError(err, navigate),
    );

    // Pick from session storage if available
    let cerberusToken = sessionStorage.getItem('auth_token');
    let investorOrgId = sessionStorage.getItem('org_id');
    let userId = sessionStorage.getItem('user_id');

    // Otherwise pick from local storage
    if (!cerberusToken) cerberusToken = localStorage.getItem('auth_token');
    if (!investorOrgId) investorOrgId = localStorage.getItem('org_id');
    if (!userId) userId = localStorage.getItem('user_id');

    if (cerberusToken && investorOrgId && userId)
        addTokenToHeaders(axiosInstance, investorOrgId, cerberusToken, userId);
    return axiosInstance;
}

export function changeOrgIdHeaderForCharts(axiosInstance: AxiosInstance, orgId: string) {
    orgId && (axiosInstance.defaults.headers['x-organization-id'] = orgId);
}

export function addTokenToHeaders(
    axiosInstance: AxiosInstance,
    investeeOrgId?: string | null,
    cerberusToken?: string | null,
    userId?: string | null,
) {
    cerberusToken && (axiosInstance.defaults.headers['x-cerberus-token'] = cerberusToken);
    investeeOrgId && (axiosInstance.defaults.headers['x-organization-id'] = investeeOrgId);
    userId && (axiosInstance.defaults.headers['x-user-id'] = userId);

    const isSessionStorageEmpty = isEmpty(sessionStorage.getItem('auth_token'));
    if (isSessionStorageEmpty) {
        cerberusToken && localStorage.setItem('auth_token', cerberusToken);
        investeeOrgId && localStorage.setItem('org_id', investeeOrgId);
        userId && localStorage.setItem('user_id', userId);
    }
}
