import { useEffect, useState } from 'react';
import { isEmpty, isRestrictedUser } from '../../utils/utils';
import { FileNode } from './DataRoom';
import { getAllDocs, getAllDocsAica, getIsRecurCompany } from '../../store/dataRoom/apiHelper';
import { RESTRICTED_ROLES } from '../../store/investor/constants';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { RECUR_PNO_DOMAIN } from '../../store/user/constants';

const useCallApiToFetchFileData = (orgName?: string) => {
    const [fileData, setFileData] = useState<FileNode[] | null>(null);
    const [isFetchingData, setIsFetchingData] = useState(false);
    const user = useAppSelector((state) => state.user);
    // const [isRecurCompany, setIsRecurCompany] = useState<boolean | null>(null);
    const isUserRestricted = isRestrictedUser(user?.userData?.roles?.[0], RESTRICTED_ROLES);
    const params = useParams();
    const pnoId = user?.userData?.investorOrgId;

    // useEffect(() => {
    //     const domainDataRoom = process.env.RECUR_PNO_DOMAIN || RECUR_PNO_DOMAIN;
    //
    //     if (domainDataRoom && location.origin.includes(domainDataRoom)) {
    //         fetchCompanyData();
    //     } else {
    //         setIsRecurCompany(false);
    //     }
    // }, []);

    useEffect(() => {
        if (fileData === null && orgName) {
            fetchFiles();
        }
    }, [fileData, orgName]);

    // const fetchCompanyData = async () => {
    //     const { recurCompany } = await getIsRecurCompany({ assesseeOrgId: params?.id ?? '' });
    //     setIsRecurCompany(recurCompany);
    // };

    const fetchFiles = async () => {
        setIsFetchingData(true);
        await getAllDocsAica(
            {
                assesseeOrgId: params?.id ?? '',
                pnoId: pnoId ?? '',
            },
            {
                onSuccess: (data) => setFileData(data),
            },
        );
        setIsFetchingData(false);
    };

    return {
        fileData,
        isFetchingData,
        fetchFiles,
        // , isRecurCompany
    };
};

export default useCallApiToFetchFileData;
