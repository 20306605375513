import styles from '../../MagicUpload.module.scss';
import clsx from 'clsx';
import { useAppSelector } from '../../../../app/hooks';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { getUntaggedCount } from '../../MagicUploadHelper';
import { updateUntaggedMuFileNumber } from '../../../../store/investor/action';
import { useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

type MagicUploadTabSelectorProps = {
    tabState: string;
    setTabState: Dispatch<SetStateAction<'new' | 'tagged' | 'all'>>;
};

const MagicUploadTabSelector = (props: MagicUploadTabSelectorProps) => {
    const { tabState, setTabState } = props;
    const untaggedCount = useAppSelector((state) => state.investor.untaggedCount);
    const dispatch = useDispatch();
    const user = useAppSelector((state) => state.user);
    const params = useParams();
    const [searchParams] = useSearchParams();
    const isCompany = searchParams.get('isCompany') === 'true';
    const getUntaggedStatus = async () => {
        await getUntaggedCount(
            {
                pnoId: user.userData.investorOrgId,
                ...(isCompany && { orgId: user.userData.assesseeOrgId ?? params.id }),
            },
            {
                onSuccess: (res) => {
                    dispatch(updateUntaggedMuFileNumber(res));
                },
            },
        );
    };
    useEffect(() => {
        const fetchUntaggedStatus = async () => {
            await getUntaggedStatus(); // Await the async function
        };
        fetchUntaggedStatus(); // Call the async function
    }, []);
    return (
        <div className={styles.TabSelector}>
            <div
                onClick={() => setTabState('all')}
                className={clsx({
                    [styles.Tab]: true,
                    [styles.Active]: tabState === 'all',
                })}
            >
                All
            </div>
            <div
                onClick={() => setTabState('tagged')}
                className={clsx({
                    [styles.Tab]: true,
                    [styles.Active]: tabState === 'tagged',
                })}
            >
                Tagged
            </div>
            <div
                onClick={() => setTabState('new')}
                className={clsx({
                    [styles.Tab]: true,
                    [styles.Active]: tabState === 'new',
                })}
            >
                Untagged <span style={{ color: '#B4B4B4' }}>{untaggedCount}</span>
            </div>
        </div>
    );
};

export default MagicUploadTabSelector;
