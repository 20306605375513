import React from 'react';
import styles from './DebtProductOverview.module.scss';
import ProbabilityBar from '../ProbabilityBar/ProbabilityBar';
import { debtProdTermsError, debtProdTermsOk } from '../../../../assets/hostedassets';
import { DebtProductRowData, TermsObj } from '../../debtProductTypes';
import SideDrawer from '../../../../common/_custom/SideDrawer/SideDrawer';
import closeIcon from '../../../../common/_custom/Modal/close.svg';
import { fetchUserFromOrgId } from '../../../../store/investor/investorApiHelper';
import {
    fetchInternalSummonAicaData,
    InternalSummonAicaDataPayload,
    InternalSummonUserType,
} from '../../../../store/user/actions';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { getPlatformUserId, openUserSummonTab } from '../../../../utils/utils';
import { INVESTOR_ROUTES, LENDER_ROUTES } from '../../../../utils/constants/routesConst';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function DebtProductOverview({
    data,
    closeModal,
}: {
    data: DebtProductRowData;
    closeModal: Function;
}) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const platformData = useAppSelector((state) => state.user.domainMetadata);

    const overviewData = data?.details;
    const termsData = overviewData?.terms || {};
    const eligibilityData = overviewData?.eligibility || {};

    const getStatusIcon = (status) => {
        switch (status) {
            case true:
                return debtProdTermsOk;
            case false:
            default:
                return debtProdTermsError;
        }
    };

    const renderValueRow = (value: TermsObj) => {
        if (value?.TYPE === 'TEXT') {
            return (
                <div className={styles.Value}>
                    <div className={styles.CalcValue}>{value?.COMPANY_VALUE}</div>
                </div>
            );
        } else if (value?.MATCHED?.length || value?.UNMATCHED?.length) {
            return (
                <div className={styles.ValueRows}>
                    {value?.MATCHED?.map((str, i) => (
                        <div className={styles.ValueRow} key={i}>
                            <img src={debtProdTermsOk} alt="Matched" height={'14px'} />
                            {str}
                        </div>
                    ))}
                    {value?.UNMATCHED?.map((str, i) => (
                        <div className={styles.ValueRow} key={i}>
                            <img src={debtProdTermsError} alt="Not Matched" height={'14px'} />
                            {str}
                        </div>
                    ))}
                </div>
            );
        }
        return (
            <div className={styles.Value}>
                <img src={getStatusIcon(value.STATUS)} alt="Status" height={'14px'} />
                <div className={styles.CalcValue}>{value?.COMPANY_VALUE}</div>
                <div className={styles.OriginalValue}>/ {value?.PEC_VALUE}</div>
            </div>
        );
    };

    const summonUserEditPreferences = async () => {
        if (!data?.lenderId || data?.lenderId === data?.aggregatorId) {
            window.open(`${INVESTOR_ROUTES.APP}/${INVESTOR_ROUTES.MANAGE_ELIGIBILITY}`, '_blank');
            return;
        }
        const users = await fetchUserFromOrgId(data?.lenderId, InternalSummonUserType.LENDER);
        if (Array.isArray(users) && users.length) {
            const summonPayload: InternalSummonAicaDataPayload = {
                parentOrgId: platformData?.investorOrgId as string,
                parentEmailId: users[0].emailId,
                userId: users[0].userId,
                userType: InternalSummonUserType.LENDER,
                requesterUserId: getPlatformUserId(),
            };
            dispatch(
                fetchInternalSummonAicaData(summonPayload, {
                    onSuccess: (userData) => {
                        openUserSummonTab({
                            ...userData,
                            orgId: platformData?.investorOrgId,
                            referrer: LENDER_ROUTES.MANAGE_ELIGIBILITY,
                        });
                    },
                    onError: () => {
                        toast.error('Failed to summon user');
                    },
                }),
            );
        }
    };

    return (
        <SideDrawer width={'50%'} onClose={() => closeModal()} allowClose>
            <div className={styles.DebtProductOverview}>
                <div className={styles.Header}>
                    <span>
                        {data.productName || '-'}
                        {!!data?.lenderName && (
                            <span className={styles.LenderName}> | {data.lenderName}</span>
                        )}
                    </span>

                    <img
                        className={styles.CloseIcon}
                        src={closeIcon}
                        alt="close"
                        height="13px"
                        width="13px"
                        onClick={() => closeModal?.()}
                    />
                </div>

                <div className={styles.Body}>
                    {!!Object.keys(termsData)?.length && (
                        <div className={styles.Table}>
                            <div className={styles.TableHeader}>
                                <div className={styles.Metric}>Tentative Terms</div>
                                <ProbabilityBar probability={data?.probability} />
                            </div>
                            {Object.entries(termsData)?.map(([key, val]: [string, TermsObj]) => (
                                <div key={key} className={styles.Row}>
                                    <div className={styles.Metric}>{val.text}</div>

                                    {renderValueRow(val)}
                                </div>
                            ))}
                        </div>
                    )}

                    {!!Object.keys(eligibilityData)?.length && (
                        <div className={styles.Table}>
                            <div className={styles.TableHeader}>
                                <div className={styles.Metric}>Eligibility Criteria</div>
                            </div>
                            {Object.entries(eligibilityData)?.map(
                                ([key, val]: [string, TermsObj]) => (
                                    <div key={key} className={styles.Row}>
                                        <div className={styles.Metric}>{val.text}</div>

                                        {renderValueRow(val)}
                                    </div>
                                ),
                            )}
                        </div>
                    )}
                </div>
                <div className={styles.Footer}>
                    <button className={styles.EditPreferences} onClick={summonUserEditPreferences}>
                        Edit Investment Preferences
                    </button>
                </div>
            </div>
        </SideDrawer>
    );
}

export default DebtProductOverview;
