import { CONSTANTS } from './constants';
// import { Actions } from '../actiontypes';

//---------STORE ACTIONS-----------//

export function fetchConfigData(payload: { setDefaultTab: Function }) {
    return {
        type: CONSTANTS.GET_DASHBOARD_CONFIG,
        payload,
    };
}

export function fetchHeadlineData(
    payload: {
        tabName: string;
        startDate: string;
        endDate: string;
        tenure: string;
        reportId: string;
    },
    data: { isPolling: boolean },
) {
    return {
        type: CONSTANTS.GET_HEADLINE_DATA,
        payload,
        data,
    };
}

export function fetchGraphData(
    payload: {
        graphNames: string[];
        tabName: string;
        startDate: string;
        endDate: string;
        tenure: string | null;
        reportId: string;
    },
    data: {
        isPollingCall: boolean;
    },
) {
    return {
        type: CONSTANTS.GET_GRAPH_DATA,
        payload,
        data,
    };
}

export function resetIsFetched(payload: string) {
    return {
        type: CONSTANTS.RESET_IS_FETCHED_GRAPH,
        payload,
    };
}

export function intializeChartsAction(payload) {
    return {
        type: CONSTANTS.INITIALIZE_GRAPH_CARDS,
        payload,
    };
}

export function fetchAicaGradeData(payload: { investorOrgId: string; assesseeOrgId: string }) {
    return {
        type: CONSTANTS.GET_AICA_GRADE_DATA,
        payload,
    };
}

export function setShowAicaGradeDrawer(payload: boolean) {
    return {
        type: CONSTANTS.SET_SHOW_AICA_GRADE_DRAWER,
        payload,
    };
}

export function setDashboardConfig(payload: any) {
    return {
        type: CONSTANTS.GET_DASHBOARD_CONFIG_SUCCESS,
        payload,
    };
}
