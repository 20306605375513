import styles from './ClarificationDrawer.module.scss';
import clsx from 'clsx';
import useClarificationLogic from './useClarificationLogic';
import { DocumentItem } from '../PendingDocuments/useDataGapDocument';
import React from 'react';
import Button from '../../../_custom/Button/Button';

type ClarificationDrawerProps = {
    isMiniDrawerOpen: boolean;
    toggleMiniDrawer: () => void;
    docList: DocumentItem[];
    setDocList: React.Dispatch<React.SetStateAction<DocumentItem[]>>;
    selectedDocument: DocumentItem | null;
};

const ClarificationDrawer = ({
    isMiniDrawerOpen,
    toggleMiniDrawer,
    docList,
    setDocList,
    selectedDocument,
}: ClarificationDrawerProps) => {
    const {
        radioItems,
        editableRefs,
        handleKeyPress,
        handleDescriptionChange,
        saveClarification,
        cancelClarification,
        closeDrawer,
        disabled,
        handlePaste,
    } = useClarificationLogic(
        toggleMiniDrawer,
        isMiniDrawerOpen,
        docList,
        setDocList,
        selectedDocument,
    );

    return (
        <>
            {isMiniDrawerOpen && <div className={styles.backdrop} onClick={closeDrawer}></div>}
            <div
                className={clsx(styles.miniDrawer, {
                    [styles.open]: isMiniDrawerOpen,
                    [styles.closed]: !isMiniDrawerOpen,
                })}
            >
                <div className={styles.miniDrawerContent}>
                    <h4>Add Clarifications:</h4>
                    <div className={styles.radioList}>
                        {radioItems.map((item) => (
                            <div key={item.id} className={styles.radioItem}>
                                {/* Static Circle */}
                                <div className={styles.circle}></div>

                                {/* Editable Description */}
                                <div
                                    ref={(el) => (editableRefs.current[item.id] = el)}
                                    contentEditable
                                    className={styles.editable}
                                    suppressContentEditableWarning
                                    onInput={(e) => handleDescriptionChange(e, item.id)}
                                    onKeyDown={(e) => handleKeyPress(e, item.id)}
                                    onPaste={(e) => handlePaste(e, item.id)} // <-- Add this
                                    placeholder="Enter clarification description"
                                ></div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.buttonGroup}>
                        <Button
                            text={'Cancel'}
                            type={'button'}
                            variant={'secondary'}
                            onClick={cancelClarification}
                        ></Button>
                        <Button
                            text={'Save'}
                            type={'button'}
                            variant={'primary'}
                            onClick={saveClarification}
                            disabled={disabled}
                        ></Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClarificationDrawer;
