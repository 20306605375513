import styles from '../../../MagicUpload/components/FilteredHeader/FilteredHeader.module.scss';
import { useEffect, useRef, useState } from 'react';
import { useOutsideClick } from '../../../../utils/constants/hooks';
import { GREY_DROPDOWN_CARET } from '../../../../assets/hostedassets';

type MultiSelectSearchDropdownProps = {
    options: { label: string; value: string }[];
    selectedItems: string[];
    toggleSelection: (option: string[]) => void;
    placeholder?: string;
};

const MultiSelectSearchDropdown = (props: MultiSelectSearchDropdownProps) => {
    const { options, selectedItems, toggleSelection, placeholder } = props;
    const [showFilters, setShowFilters] = useState(false);
    const [searchText, setSearchText] = useState('');
    const dropdownItemRef = useRef<HTMLDivElement>(null);
    const [filteredDropdownOptions, setFilteredDropdownOptions] = useState<
        | {
              label: string;
              value: string;
          }[]
        | null
    >(null);
    const dropdownRef = useRef(null);
    useOutsideClick(dropdownRef, () => {
        setShowFilters(false);
    });

    const handleSelection = (option: { label: string; value: string }) => {
        if (selectedItems.some((item) => item === option.value)) {
            toggleSelection(selectedItems.filter((item) => item !== option.value));
        } else {
            toggleSelection([...selectedItems, option.value]);
        }
    };

    useEffect(() => {
        if (searchText) {
            setFilteredDropdownOptions(
                options.filter((option) =>
                    option.label.toLowerCase().includes(searchText.toLowerCase()),
                ),
            );
        } else {
            setFilteredDropdownOptions(options);
        }
    }, [searchText]);

    useEffect(() => {
        if (showFilters) {
            let selectedOptions = options.filter((option) =>
                selectedItems.some((item) => item === option.value),
            );
            let unselectedOptions = options.filter(
                (option) => !selectedItems.some((item) => item === option.value),
            );
            setFilteredDropdownOptions([...selectedOptions, ...unselectedOptions]);
            if (dropdownItemRef.current) {
                dropdownItemRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }, [options, showFilters]);

    return (
        <>
            <div
                ref={dropdownRef}
                style={{ width: '100%' }}
                onClick={() => setShowFilters(!showFilters)}
                className={styles.SearchBar}
            >
                {selectedItems.length > 0 ? (
                    <>
                        <div>
                            <span className={styles.SelectedItem}>
                                {options.find((option) => option.value === selectedItems[0])?.label}
                            </span>
                            {selectedItems.length > 1 && (
                                <span className={styles.AndMore}>
                                    +{selectedItems.length - 1} More
                                </span>
                            )}
                        </div>
                        <img
                            style={showFilters ? { transform: 'rotate(180deg)' } : {}}
                            className={styles.DownArrow}
                            src={GREY_DROPDOWN_CARET}
                            alt="caret"
                        />
                    </>
                ) : (
                    <>
                        <span className={styles.PlaceholderText}>{placeholder}</span>
                        <img
                            style={showFilters ? { transform: 'rotate(180deg)' } : {}}
                            className={styles.DownArrow}
                            src={GREY_DROPDOWN_CARET}
                            alt="caret"
                        />
                    </>
                )}

                <div
                    className={styles.FilteredDropdownContainer}
                    style={
                        showFilters
                            ? { maxHeight: '20rem', width: '100%' }
                            : { maxHeight: 0, width: '100%' }
                    }
                >
                    <div style={{ width: '100%' }} className={styles.DropdownOptionsWrapper}>
                        <input
                            type="text"
                            placeholder="Search"
                            className={styles.SearchInputFilter}
                            value={searchText}
                            onClick={(e) => e.stopPropagation()}
                            onMouseDown={(e) => e.stopPropagation()}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        <div className={styles.FilteredDropdownWrapper} ref={dropdownItemRef}>
                            {filteredDropdownOptions?.length &&
                            filteredDropdownOptions?.length > 0 ? (
                                filteredDropdownOptions?.map((option) => (
                                    <div
                                        key={option.value}
                                        className={styles.FilteredDropdownItem}
                                        onMouseDown={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            handleSelection(option);
                                        }}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={selectedItems?.some(
                                                (item) => item === option.value,
                                            )}
                                            readOnly
                                        />
                                        <label className={styles.DropdownLabel}>
                                            {option.label}
                                        </label>
                                    </div>
                                ))
                            ) : (
                                <div className={styles.NoData}>
                                    <span>No Results</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MultiSelectSearchDropdown;
