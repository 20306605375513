import { JAVA_API_STATUS } from '../../utils/axios-interceptor';
import { toast } from 'react-toastify';
import {
    callAddClarification,
    callNeedInfoReminder,
    callUpdateClarification,
    callUpdateDocMetadata,
    callUploadFile,
    deleteDocApi,
    fetchDataGapDocs,
    fetchDocList,
    fetchDocs,
    fetchDocStatus,
    fetchDuplicateFilesMap,
    fetchOrgDropdownList,
    fetchOrgList,
    fetchSourcesList,
    fetchUntaggedCount,
    fetchUserDropdownList,
    pollZipDocStatus,
} from './MagicUploadService';

export const getAllDocs = async (
    data: any,
    constraints: {
        pageNo: number;
        pageSize: number;
    },
    handler: any,
) => {
    try {
        const res: any = await fetchDocs(data, constraints);
        if (res.data.responseData.responseCode === JAVA_API_STATUS.SUCCESS) {
            handler?.onSuccess(res?.data?.docs);
        } else {
            toast.error('Failed to upload file, Please Try Again');
        }
    } catch (err) {
        console.error(err);
    }
};

export const getOrgList = async (
    data: {
        pnoId: string;
        searchText: string;
    },
    handler: any,
) => {
    try {
        const res: any = await fetchOrgList(data);
        if (res.data.responseData.responseCode === JAVA_API_STATUS.SUCCESS) {
            handler?.onSuccess(res.data?.orgData);
        } else {
            toast.error('Failed to fetch org list, Please Try Again');
        }
    } catch (err) {
        console.error(err);
    }
};

export const updateDocMetadata = async (data: any, handler: any) => {
    try {
        const res: any = await callUpdateDocMetadata(data);
        if (res.data.responseData.responseCode === JAVA_API_STATUS.SUCCESS) {
            handler?.onSuccess(res.data);
        } else {
            if (handler?.onError) handler?.onError();
            else toast.error('Failed to update file, Please Try Again');
        }
    } catch (err) {
        console.error(err);
    }
};

export const getDocList = async (data: any, handler: any) => {
    try {
        const res: any = await fetchDocList(data);
        if (res.data.responseData.responseCode === JAVA_API_STATUS.SUCCESS) {
            handler?.onSuccess(res.data?.documentList);
        } else {
            toast.error('Failed to fetch doc list, Please Try Again');
        }
    } catch (err) {
        console.error(err);
    }
};

export const uploadFile = async (data: any, handler: any) => {
    try {
        const res: any = await callUploadFile(data);
        if (res.data.responseData.responseCode === JAVA_API_STATUS.SUCCESS) {
            handler?.onSuccess(res.data.doc);
        } else {
            toast.error('Failed to upload file, Please Try Again');
        }
    } catch (err) {
        toast.error('Failed to upload file, Please Try Again');
        console.error(err);
    }
};

export const getDocStatus = async (data: any, handler: any) => {
    try {
        const res: any = await fetchDocStatus(data);
        if (res.data.responseData.responseCode === JAVA_API_STATUS.SUCCESS) {
            handler?.onSuccess(res.data.docs);
        } else {
            handler?.onError();
            toast.error('Failed to fetch doc status, Please Try Again');
        }
    } catch (err) {
        handler?.onError();
        console.error(err);
    }
};

export const getUntaggedCount = async (data: any, handler: any) => {
    try {
        const res: any = await fetchUntaggedCount(data);
        if (res.data.responseData.responseCode === JAVA_API_STATUS.SUCCESS) {
            handler?.onSuccess(res.data.data);
        } else {
            // toast.error('Failed to fetch untagged count, Please Try Again');
        }
    } catch (err) {
        console.error(err);
    }
};

export const deleteDoc = async (data: any, handler: any) => {
    try {
        const res: any = await deleteDocApi(data);
        if (res.data.responseData.responseCode === JAVA_API_STATUS.SUCCESS) {
            handler?.onSuccess();
        } else {
            toast.error('Failed to delete doc, Please Try Again');
        }
    } catch (err) {
        toast.error('Failed to delete doc, Please Try Again');
        console.error(err);
    }
};

export const fetchAllDocMap = async (data: any, handler: any) => {
    try {
        const res = await fetchDuplicateFilesMap(data);
        if (res.data.responseData.responseCode === JAVA_API_STATUS.SUCCESS) {
            handler?.onSuccess(res.data);
        } else {
            handler?.onError();
        }
    } catch (err) {
        console.error(err);
    }
};

export const pollZipDocStatuses = async (data: any, handler: any) => {
    try {
        const res = await pollZipDocStatus(data);
        if (res.data.responseData.responseCode === JAVA_API_STATUS.SUCCESS) {
            handler?.onSuccess(res.data.zipDocs);
        } else {
            handler?.onError();
        }
    } catch (err) {
        console.error(err);
    }
};

export const getOrgDropdownList = async (data: any, handler: any) => {
    try {
        const res = await fetchOrgDropdownList(data);
        if (res.data.responseData.responseCode === JAVA_API_STATUS.SUCCESS) {
            handler?.onSuccess(res.data.orgData);
        } else {
            handler?.onError();
        }
    } catch (err) {
        console.error(err);
    }
};

export const getSourceDropdownList = async (handler: any) => {
    try {
        const res = await fetchSourcesList();
        if (res.data.responseData.responseCode === JAVA_API_STATUS.SUCCESS) {
            handler?.onSuccess(res.data.sources);
        } else {
            handler?.onError();
        }
    } catch (err) {
        console.error(err);
    }
};

export const getUserDropdownList = async (params: any, handler: any) => {
    try {
        const res = await fetchUserDropdownList(params);
        if (res.data.responseData.responseCode === JAVA_API_STATUS.SUCCESS) {
            handler?.onSuccess(res.data.userData);
        } else {
            handler?.onError();
        }
    } catch (err) {
        console.error(err);
    }
};

export const getDataGapDocs = async (payload: any, handler: any) => {
    try {
        const res = await fetchDataGapDocs(payload);
        if (res.data.responseData.responseCode === JAVA_API_STATUS.SUCCESS) {
            handler?.onSuccess(res.data);
        } else {
            handler?.onError();
        }
    } catch (err) {
        console.error(err);
    }
};

export const AddClarificaiton = async (payload: any, handler: any) => {
    try {
        const res = await callAddClarification(payload);
        if (res.data.responseData.responseCode === JAVA_API_STATUS.SUCCESS) {
            handler?.onSuccess();
        } else {
            handler?.onError();
        }
    } catch (err) {
        console.error(err);
    }
};

export const UpdateClarification = async (payload: any, handler: any) => {
    try {
        const res = await callUpdateClarification(payload);
        if (res.data.responseData.responseCode === JAVA_API_STATUS.SUCCESS) {
            handler?.onSuccess(res.data.dataGapId);
        } else {
            handler?.onError();
        }
    } catch (err) {
        console.error(err);
    }
};

export const SendNeedInfoReminder = async (payload: any, handler: any) => {
    try {
        const res = await callNeedInfoReminder(payload);
        if (res.data.responseData.responseCode === JAVA_API_STATUS.SUCCESS) {
            handler?.onSuccess();
        } else {
            handler?.onError();
        }
    } catch (err) {
        console.error(err);
    }
};
