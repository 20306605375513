import useManageEligibilityStates from '../../modules/Investor/ManageEligibility/useManageEligibilityStates';
import { Modal } from '../_custom';
import styles from './DeleteModal.module.scss';

const DeleteModal = ({ isOpen, onClose, deleteFunction, heading, message }) => {
    return (
        <Modal open={isOpen} onClose={onClose} size="flexible">
            <div className={styles.DeleteContainer}>
                <div className={styles.MessageContainer}>
                    <div className={styles.Heading}>{heading}</div>
                    <div className={styles.Message}>{message}</div>
                </div>
                <div className={styles.ButtonContainer}>
                    <button className={styles.CancelButton} onClick={onClose}>
                        Cancel
                    </button>
                    <button className={styles.DeleteButton} onClick={deleteFunction}>
                        Delete
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default DeleteModal;
