export const sourceMap = {
    whats_app: 'WhatsApp',
    gmail: 'Gmail',
    app: 'Magic Upload',
};

export enum HEADER_TITLE {
    ORGANISATION_NAME = 'Organisation Name',
    SOURCE = 'Source',
    UPLOADED_BY = 'Uploaded By',
}

export enum KEY_NAME {
    ASSESSEE_ORGID_LIST = 'assesseeOrgIdList',
    SOURCES = 'sources',
    USER_IDS = 'userIds',
}
export const NEED_INFO = 'needInfo';
export const MAGIC_UPLOAD = 'magicUpload';

export const CLARIFICATION_TYPE = {
    SYSTEM_GENERATED: 'SYSTEM_GENERATED',
    USER_GENERATED: 'USER_GENERATED',
};

export const MIME_TYPES = {
    rar: 'application/x-compressed',
    zip: 'application/zip',
    pdf: 'application/pdf',
    oct_stream: 'application/octet-stream',
};

export const WRITE = 'WRITE';
