import React, { useEffect } from 'react';
import styles from './DebtProducts.module.scss';
import useLegacyBusinessDataFetch from '../DataRoom/useLegacyBusinessDataFetch';
import { createPortal } from 'react-dom';
import DashboardHeader from '../Dashboard/components/DashboardHeader/DashboardHeader';
import { Button } from '../../common/_custom';
import {
    caretRight,
    ctaGrey,
    debtProdFilters,
    debtProductsEmpty,
    editProfileIcon,
    pendingDataComplete,
    PendingDataIncomplete,
} from '../../assets/hostedassets';
import LoadingSpinner from '../../common/_custom/LoadingSpinner/LoadingSpinner';
import ManageDealDrawer from '../../common/ManageDealDrawer/ManageDealDrawer';
import { setDealDrawer, updateNeedInfoFileNumber } from '../../store/investor/action';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import clsx from 'clsx';
import ProbabilityBar from './components/ProbabilityBar/ProbabilityBar';
import DebtProductOverview from './components/DebtProductOverview/DebtProductOverview';
import Tooltip from '../../common/_custom/Tooltip/Tooltip';
import { DebtProductRowData } from './debtProductTypes';
import { useParams, useSearchParams } from 'react-router-dom';
import useDebtProductLogic from './useDebtProductLogic';
import { USER_TYPE } from '../../utils/constants/user';
import { getDataGapDocs } from '../../common/MagicUpload/MagicUploadHelper';
import { isEmpty } from '../../utils/utils';

function DebtProducts() {
    const dispatch = useAppDispatch();
    const params = useParams();
    const {
        exportData,
        isUserRestricted,
        selectedCompany,
        refreshReportStatus,
        downloadPDFStatus,
        goBackToPortfolio,
        unlockAicaReport,
    } = useLegacyBusinessDataFetch();
    const header = document.getElementById('header') ?? document.createElement('div');

    const {
        loading, //component loading state
        noPecExists, //true if there are no existing PECs
        companyMetricsDataExists,
        showDetailOverview, //used to store row data for opening modal
        debtProductsData, //data used to render table
        showEmptyScreen, //boolean to show empty screen
        dealDrawerState,
        setDebtProductsData,
        setShowDetailOverview,
        fetchPECDataAndDebtProductsData,
        fetchCompanyMetrics,
        //MIXPANEL
        registerMixpanelForRowExpand,
        registerMixpanelForUserHelpClick,
        registerMixpanelForEditProfileClicked,
        showFilters,
    } = useDebtProductLogic({ params, selectedCompany });
    const user = useAppSelector((state) => state.user);
    const investorData = useAppSelector((state) => state.investor);
    const debtFilters = useAppSelector((state) => state.company.debtProductsFilters);
    const permissions = investorData.permissions;
    const showDataGapCTA = permissions.showDataGap;
    const isDemoProfile = !!selectedCompany?.demoOrg;
    const [searchParams, setSearchParams] = useSearchParams();
    const pendingCount = useAppSelector((state) => state.investor.pendingDataCount);

    const getPendingDocumentsCount = async () => {
        await getDataGapDocs(
            {
                assesseeOrgId: params.id ?? '',
            },
            {
                onSuccess: (res) => {
                    dispatch(updateNeedInfoFileNumber(res.pendingCount));
                },
            },
        );
    };

    const openNeedInfoDrawer = () => {
        searchParams.set('needInfo', 'true');
        searchParams.set('showNeedInfo', 'true');
        searchParams.set('isCompany', 'true');
        setSearchParams(searchParams);
    };

    useEffect(() => {
        getPendingDocumentsCount();
    }, []);
    // const openCreatePECDrawer = () => {
    //     dispatch(
    //         setDealDrawer({
    //             open: true,
    //             drawerLabel: 'Create New Eligibility',
    //             isPec: true,
    //         }),
    //     );
    // };

    const renderHeaderButton = () => {
        if (user.userData.accountType === USER_TYPE.INVESTOR && !isDemoProfile && showDataGapCTA) {
            return (
                <>
                    <div
                        className={clsx({
                            [styles.PedingCTA]:
                                (pendingCount as number) > 0 || pendingCount === null,
                            [styles.PendingDone]: pendingCount === 0,
                        })}
                        onClick={() => openNeedInfoDrawer()}
                    >
                        <img
                            src={pendingCount === 0 ? pendingDataComplete : PendingDataIncomplete}
                            alt="pendingDataComplete"
                        />
                        <span>
                            {pendingCount === 0
                                ? 'No Data Pending'
                                : `${pendingCount ?? ''} Data Pending`}
                        </span>
                    </div>
                </>
            );
        }
        return <></>;
    };

    const renderEmptyScreen = () => {
        return (
            <div className={styles.Empty}>
                <img src={debtProductsEmpty} alt={''} height={'100px'} />
                {noPecExists ? (
                    <div className={styles.Text}>
                        {/*<span className={styles.Link} onClick={openCreatePECDrawer}>*/}
                        {/*    Add PEC*/}
                        {/*</span>*/}
                        &nbsp;Please set investment preferences first
                    </div>
                ) : companyMetricsDataExists ? (
                    <></>
                ) : (
                    <div className={styles.Text}>
                        <span
                            className={styles.Link}
                            onClick={() => {
                                dispatch(
                                    setDealDrawer({ open: true, drawerLabel: 'Edit Profile' }),
                                );
                                registerMixpanelForEditProfileClicked('Empty Screen');
                            }}
                        >
                            Edit Profile
                        </span>
                        &nbsp;to view products with probability
                    </div>
                )}
            </div>
        );
    };

    const showLoader = () => (
        <div className={styles.Loader}>
            <LoadingSpinner color="#6021B3" height={'40px'} />
            <div className={styles.Text}>Fetching Debt Products</div>
        </div>
    );

    const getSecurityTooltip = (securities: string[] = []) => {
        return (
            <div className={styles.SecuritiesTooltip}>
                {securities?.map((sec, i) => (
                    <div className={styles.SingleSecurity} key={sec}>
                        &bull;&nbsp;&nbsp;{sec}
                    </div>
                ))}
            </div>
        );
    };

    const renderRowItem = (headerId, rowData: DebtProductRowData, isParentRow = false) => {
        let renderComponent = <></>;
        switch (headerId) {
            case 'productName':
                renderComponent = (
                    <div
                        className={clsx(styles.Product, {
                            [styles.ProductSub]: !isParentRow,
                        })}
                    >
                        {(isParentRow ? rowData?.[headerId] : rowData?.['lenderName']) || '-'}
                        {isParentRow && !!rowData?.lenders?.length && (
                            <div className={styles.Count}>{rowData?.lenders?.length}</div>
                        )}
                    </div>
                );
                break;
            case 'select':
                renderComponent = <input type="checkbox" checked={false} onChange={(e) => {}} />;
                break;
            case 'caret':
                if (isParentRow)
                    renderComponent = (
                        <div
                            className={clsx(styles.CaretDiv, {
                                [styles.CaretDivHov]: rowData.expand,
                            })}
                        >
                            <img
                                className={clsx(styles.CaretImg, {
                                    [styles.ExpandedCaret]: rowData?.expand && isParentRow,
                                    [styles.DisabledCaret]: !rowData?.lenders?.length,
                                })}
                                src={caretRight}
                                height={'14px'}
                                alt={''}
                                style={{ marginTop: '4px' }}
                            />
                        </div>
                    );
                break;
            case 'probability':
                renderComponent = (
                    <div className={styles.ProbabilityRow} style={{ opacity: 1 }}>
                        <ProbabilityBar probability={rowData?.[headerId]} />
                        <img src={ctaGrey} alt={''} height={'12px'} />
                    </div>
                );
                break;
            case 'security': {
                const totalElements = rowData?.security?.length;
                renderComponent = totalElements ? (
                    <Tooltip
                        content={getSecurityTooltip(rowData?.security)}
                        direction={'top'}
                        fitContent
                    >
                        <div className={styles.SecurityContainer}>
                            {rowData?.security?.slice(0, 1)?.map((sec, i) =>
                                !!sec ? (
                                    <div key={i} className={styles.SecurityItem}>
                                        {sec}
                                    </div>
                                ) : (
                                    '-'
                                ),
                            )}
                            {totalElements > 1 && (
                                <div className={styles.More}>+{totalElements - 1} more</div>
                            )}
                        </div>
                    </Tooltip>
                ) : (
                    <>-</>
                );
                break;
            }

            default:
                renderComponent = rowData?.[headerId] ?? '-';
        }

        return (
            <td
                key={header.id}
                className={clsx({
                    [styles.RowItem]: true,
                    [styles.SelectCol]: headerId === 'select',
                    [styles.CaretCol]: headerId === 'caret',
                    [styles.ProductsCol]: headerId === 'productName',
                    [styles.ProbabilityCol]: headerId === 'probability',
                    [styles.SecurityCol]: headerId === 'security',
                    [styles.DisabledRowItem]: isParentRow && !rowData?.lenders?.length,
                })}
                onClick={(e) => {
                    switch (headerId) {
                        case 'caret':
                            e.stopPropagation();
                            expandOrCollapseRow(rowData, isParentRow);
                            break;
                        case 'probability':
                            e.stopPropagation();
                            setShowDetailOverview(rowData);
                            registerMixpanelForUserHelpClick(rowData, isParentRow);
                            break;
                        default:
                            break;
                    }
                }}
            >
                {renderComponent}
            </td>
        );
    };

    const expandOrCollapseRow = (rowData, isParentRow) => {
        if (isParentRow && rowData?.lenders?.length) {
            if (!rowData.expand) {
                registerMixpanelForRowExpand(rowData);
            }
            setDebtProductsData((existing: any) => ({
                ...existing,
                rows: existing.rows.map((row) => ({
                    ...row,
                    ...(row.productId === rowData.productId && {
                        expand: !row.expand,
                    }),
                })),
            }));
        }
    };

    return (
        <div className={styles.DebtProducts}>
            {createPortal(
                <DashboardHeader
                    exportData={exportData}
                    isUserRestricted={isUserRestricted}
                    selectedCompany={selectedCompany}
                    refreshReportStatus={refreshReportStatus}
                    downloadPDFStatus={downloadPDFStatus}
                    goBackToPortfolio={goBackToPortfolio}
                    renderHeaderButton={renderHeaderButton}
                    unlockAicaReport={unlockAicaReport}
                />,
                header,
            )}

            <div className={styles.Container}>
                <div className={styles.Top}>
                    <div className={styles.Title}>Debt Products</div>
                    <div className={styles.Right}>
                        {showFilters && (
                            <Button
                                text={isEmpty(debtFilters) ? 'Add Filters' : 'Filters Applied'}
                                onClick={() => {
                                    dispatch(
                                        setDealDrawer({
                                            open: true,
                                            drawerLabel: 'Filters',
                                            drawerSource: 'Debt Products Filters',
                                        }),
                                    );
                                }}
                                icon={debtProdFilters}
                                style={
                                    isEmpty(debtFilters)
                                        ? { marginTop: '0' }
                                        : {
                                              marginTop: 0,
                                              background: '#F2E8FF',
                                              border: '1px solid transparent',
                                          }
                                }
                                variant={'purpleinverted'}
                            />
                        )}
                        <Button
                            text={'Edit Profile'}
                            onClick={() => {
                                dispatch(
                                    setDealDrawer({ open: true, drawerLabel: 'Edit Profile' }),
                                );
                                registerMixpanelForEditProfileClicked('Kebab Menu');
                            }}
                            variant="purple"
                            style={{ marginTop: '0' }}
                            icon={editProfileIcon}
                        />
                    </div>
                </div>

                <div className={styles.Body}>
                    <div className={styles.TableContainer}>
                        <table className={styles.Table}>
                            <thead>
                                <tr className={styles.HeadersRow}>
                                    {debtProductsData?.headers?.map((header) => (
                                        <th
                                            key={header?.id}
                                            className={clsx({
                                                [styles.Header]: true,
                                                [styles.SelectCol]: header.id === 'select',
                                                [styles.CaretCol]: header.id === 'caret',
                                                [styles.ProductsCol]: header.id === 'productName',
                                                [styles.ProbabilityCol]:
                                                    header.id === 'probability',
                                                [styles.SecurityCol]: header.id === 'security',
                                            })}
                                        >
                                            {header.label}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            {!loading && !showEmptyScreen && (
                                <tbody className={styles.TableBody}>
                                    {debtProductsData?.rows?.map(
                                        (rowData: DebtProductRowData, i) => (
                                            <React.Fragment key={i}>
                                                <tr
                                                    key={`${i}-${rowData?.productId}`}
                                                    className={clsx(
                                                        styles.TableRow,
                                                        styles.ParentRow,
                                                        {
                                                            [styles.ExpandedRow]: rowData?.expand,
                                                        },
                                                    )}
                                                    onClick={() => {
                                                        expandOrCollapseRow(rowData, true);
                                                        registerMixpanelForUserHelpClick(
                                                            rowData,
                                                            true,
                                                        );
                                                    }}
                                                >
                                                    {debtProductsData?.headers?.map((header) => (
                                                        <React.Fragment key={header.id}>
                                                            {renderRowItem(
                                                                header.id,
                                                                rowData,
                                                                true,
                                                            )}
                                                        </React.Fragment>
                                                    ))}
                                                </tr>
                                                {rowData?.expand &&
                                                    rowData?.lenders?.map(
                                                        (lenderData: DebtProductRowData, j) => (
                                                            <tr
                                                                key={j}
                                                                className={clsx(
                                                                    styles.TableRow,
                                                                    styles.Subrow,
                                                                )}
                                                                onClick={() => {
                                                                    setShowDetailOverview(
                                                                        lenderData,
                                                                    );
                                                                    registerMixpanelForUserHelpClick(
                                                                        rowData,
                                                                        false,
                                                                    );
                                                                }}
                                                            >
                                                                {debtProductsData?.headers?.map(
                                                                    (header) => (
                                                                        <React.Fragment
                                                                            key={header.id}
                                                                        >
                                                                            {renderRowItem(
                                                                                header.id,
                                                                                lenderData,
                                                                                false,
                                                                            )}
                                                                        </React.Fragment>
                                                                    ),
                                                                )}
                                                            </tr>
                                                        ),
                                                    )}
                                            </React.Fragment>
                                        ),
                                    )}
                                </tbody>
                            )}
                        </table>
                        {loading && showLoader()}
                        {!loading && showEmptyScreen && renderEmptyScreen()}
                    </div>
                </div>
            </div>

            {dealDrawerState.open && (
                <ManageDealDrawer
                    source={dealDrawerState?.drawerSource ?? 'Debt Products'}
                    onClose={(payload) => {
                        fetchPECDataAndDebtProductsData(payload);
                        fetchCompanyMetrics();
                    }}
                />
            )}

            {showDetailOverview && (
                <DebtProductOverview
                    data={showDetailOverview}
                    closeModal={() => setShowDetailOverview(null)}
                />
            )}
        </div>
    );
}

export default DebtProducts;
