import axios from 'axios';
import { GET, polusBaseUrl } from '../../utils/axios-interceptor';
import { getTimeStamp } from '../../utils/dateUtils';

export function getDashboardConfig(payload) {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.GET_CONFIG.url,
        method: GET,
        params: API_ENDPOINTS.GET_CONFIG.params(payload),
    });
}

export function getHeadlineData(payload) {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.GET_HEADLINE_DATA.url,
        method: GET,
        params: API_ENDPOINTS.GET_HEADLINE_DATA.params(payload),
    });
}

export function getGraphData(payload) {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.GET_GRAPH_DATA.url,
        method: GET,
        params: API_ENDPOINTS.GET_GRAPH_DATA.params(payload),
    });
}

export function getAicaGradeDataService(payload) {
    return axios({
        baseURL: polusBaseUrl,
        url: API_ENDPOINTS.GET_AICA_SCORE_DATA.url,
        method: GET,
        params: API_ENDPOINTS.GET_AICA_SCORE_DATA.params(payload),
    });
}

const API_ENDPOINTS = {
    GET_CONFIG: {
        url: `/config/insights/dahboard`,
        method: GET,
        params: (payload) => ({
            requestId: getTimeStamp(),
            reportId: payload.reportId,
        }),
    },
    // cards changed to graphs
    GET_HEADLINE_DATA: {
        url: `/insights/tab/data`,
        method: GET,
        params: (payload) => ({
            requestId: getTimeStamp(),
            tabName: payload.tabName,
            startDate: payload.startDate,
            endDate: payload.endDate,
            tenure: payload.tenure,
            reportId: payload.reportId,
        }),
    },
    GET_GRAPH_DATA: {
        url: `/insights/tab/data`,
        method: GET,
        params: (payload) => ({
            requestId: getTimeStamp(),
            // multiple graphs can be passed as comma separated string
            graphs: payload.graphNames?.join(','),
            tabName: payload.tabName,
            startDate: payload.startDate,
            endDate: payload.endDate,
            tenure: payload.tenure,
            reportId: payload.reportId,
            directorId: payload?.directorId,
            type: payload?.type,
        }),
    },
    GET_AICA_SCORE_DATA: {
        url: '/score/fetch',
        method: GET,
        params: (payload) => ({
            requestId: getTimeStamp(),
            parentOrgId: payload.investorOrgId,
            orgId: payload.assesseeOrgId,
        }),
    },
};
