import React, { useState, useRef, useEffect } from 'react';
import { AddClarificaiton, UpdateClarification } from '../../../MagicUpload/MagicUploadHelper';
import { useParams } from 'react-router-dom';
import useDataGapDocument, { DocumentItem } from '../PendingDocuments/useDataGapDocument';
import { useAppSelector } from '../../../../app/hooks';
import { toast } from 'react-toastify';

const useClarificationLogic = (
    toggleMiniDrawer: () => void,
    isMiniDrawerOpen: boolean,
    docList: DocumentItem[],
    setDocList: React.Dispatch<React.SetStateAction<DocumentItem[]>>,
    selectedDocument: DocumentItem | null,
) => {
    const [radioItems, setRadioItems] = useState<
        {
            id: number | string;
            description: string;
            isExisting: boolean;
        }[]
    >([{ id: 1, description: '', isExisting: false }]);
    const [disabled, setDisabled] = useState(false);
    const { fetchDocuments } = useDataGapDocument('clarification', docList, setDocList);
    const editableRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});
    const params = useParams();
    const user = useAppSelector((state) => state.user.userData);

    // Focus input and place caret at the end
    const focusInput = (id: number | string) => {
        const element = editableRefs.current[id];
        if (element) {
            element.focus();
            // Optionally place cursor at the end
            const range = document.createRange();
            const selection = window.getSelection();
            range.selectNodeContents(element);
            range.collapse(false);
            selection?.removeAllRanges();
            selection?.addRange(range);
        }
    };

    useEffect(() => {
        if (selectedDocument) {
            // Prefill logic here, e.g., setting initial values in editable refs
            setRadioItems([
                {
                    id: selectedDocument.id,
                    description: selectedDocument.name,
                    isExisting: true,
                },
            ]);
            focusInput(selectedDocument.id);
        }
    }, [selectedDocument]);
    // Automatically focus the first input when drawer opens
    useEffect(() => {
        if (isMiniDrawerOpen) setTimeout(() => editableRefs.current[1]?.focus(), 100);
    }, [isMiniDrawerOpen]);

    // Close the drawer and reset all states
    const closeDrawer = () => {
        setRadioItems([{ id: 1, description: '', isExisting: false }]);
        toggleMiniDrawer();
    };

    const checkifNoClarification = () => {
        setDisabled(
            // Check if all items are empty
            radioItems.every((item) => item.description.trim() === '') ||
                // Check if all items are existing
                radioItems.every((item) => item.isExisting) ||
                // Check if all items are existing and empty
                radioItems.every((item) => item.isExisting && item.description.trim() === ''),
        );
    };

    useEffect(() => {
        checkifNoClarification();
    }, [radioItems]);

    // Save clarifications
    const saveClarification = async () => {
        const validItems = radioItems.filter(
            (item) => item.description.trim() !== '' && !item.isExisting,
        );
        const editedItems = radioItems.filter(
            (item) => item.description.trim() !== '' && item.isExisting,
        );
        if (validItems.length > 0) {
            await AddClarificaiton(
                {
                    addClarification: validItems.map((item) => ({
                        clarificationMessage: item.description,
                    })),
                    assesseeOrgId: params.id ?? '',
                },
                {
                    onSuccess: () => {
                        toggleMiniDrawer();
                        fetchDocuments();
                        setRadioItems([{ id: 1, description: '', isExisting: false }]); // Reset state
                    },
                },
            );
        }
        if (editedItems.length > 0) {
            updateClarification(editedItems[0], false);
        }
    };

    const updateClarification = async (
        item: { id: number | string; description: string; isExisting: boolean },
        isDeleted,
    ) => {
        const payload = {
            dataGapId: item.id,
            updateClarificationMessage: item.description,
            assesseeOrgId: params.id ?? '',
            adminStatus: docList[item.id]?.completed ? 'MARKED' : 'UNMARKED',
            updatedByUserId: user.userId,
            updatedByUserName: user.userName,
            isDeleted: isDeleted,
        };
        await UpdateClarification(payload, {
            onSuccess: () => {
                toggleMiniDrawer();
                fetchDocuments();
                setRadioItems([{ id: 1, description: '', isExisting: false }]); // Reset state
            },
            onError: () => {
                toast.error('Failed to update clarification');
            },
        });
    };

    // Cancel clarification and reset
    const cancelClarification = () => {
        setRadioItems([{ id: 1, description: '', isExisting: false }]);
        toggleMiniDrawer();
    };

    // Define handlePaste in your custom hook:
    const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>, id: number | string) => {
        e.preventDefault(); // Prevent the default browser paste
        const currentIndex = radioItems.findIndex((item) => item.id === id);
        if (currentIndex === -1) return;

        // Get whatever text is currently in the editable field
        const existingText = editableRefs.current[id]?.textContent || '';

        // Get the pasted text as plain text
        const pastedText = e.clipboardData.getData('text/plain');

        // Combine them -- or you could decide to overwrite existingText
        const combinedText = existingText + pastedText;

        // Split by newlines
        const lines = combinedText.split(/\r?\n/).filter(Boolean);
        // If you want to allow blank lines, remove .filter(Boolean)

        // Prepare a new array of items
        let newItems = [...radioItems];

        // Replace the current item text with the first line
        newItems[currentIndex] = {
            ...newItems[currentIndex],
            description: lines[0] || '',
        };

        // Create new items for the remaining lines
        for (let i = 1; i < lines.length; i++) {
            const newItemId = newItems.length + 1; // or use a unique ID generator
            newItems.push({
                id: newItemId,
                description: lines[i],
                isExisting: false,
            });
        }

        // Update state
        setRadioItems(newItems);

        // Optionally place focus on the last added item, or remain on the current
        const lastItemId = newItems[newItems.length - 1].id;
        setTimeout(() => focusInput(lastItemId), 0);
    };

    // Handle Enter (add new row) and Backspace (delete current row)
    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>, id: number | string) => {
        const currentIndex = radioItems.findIndex((item) => item.id === id);
        const currentDescription = editableRefs.current[id]?.textContent || '';

        // 1. Handle Up / Down arrows
        if (e.key === 'ArrowUp') {
            e.preventDefault();
            // Focus the previous item if it exists
            if (currentIndex > 0) {
                focusInput(radioItems[currentIndex - 1].id);
            }
            return;
        }
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            // Focus the next item if it exists
            if (currentIndex < radioItems.length - 1) {
                focusInput(radioItems[currentIndex + 1].id);
            }
            return;
        }

        // 2. Handle Enter (add new line)
        if (e.key === 'Enter') {
            e.preventDefault();
            const newItemId = radioItems.length + 1;
            setRadioItems((prev) => [
                ...prev,
                { id: newItemId, description: '', isExisting: false },
            ]);
            setTimeout(() => focusInput(newItemId), 0);
            return;
        }

        // 3. Handle Backspace (delete line if empty)
        if (e.key === 'Backspace' && currentDescription.trim() === '' && radioItems.length > 1) {
            e.preventDefault();
            const previousItem = radioItems[currentIndex - 1];
            setRadioItems((prev) => prev.filter((item) => item.id !== id));
            if (previousItem) setTimeout(() => focusInput(previousItem.id), 0);
            return;
        }
    };

    // Update description in the state
    const handleDescriptionChange = (e: React.FormEvent<HTMLDivElement>, id: number | string) => {
        const newDescription = e.currentTarget.textContent || '';
        setRadioItems((prev) =>
            prev.map((item) => (item.id === id ? { ...item, description: newDescription } : item)),
        );
    };

    // Sync contentEditable text content with state
    useEffect(() => {
        radioItems.forEach((item) => {
            if (editableRefs.current[item.id]) {
                editableRefs.current[item.id]!.textContent = item.description;
            }
        });
    }, [radioItems]);

    return {
        radioItems,
        editableRefs,
        handleKeyPress,
        handleDescriptionChange,
        focusInput,
        saveClarification,
        cancelClarification,
        closeDrawer,
        disabled,
        updateClarification,
        handlePaste,
    };
};

export default useClarificationLogic;
