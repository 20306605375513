import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getAllDocs, getDataGapDocs } from './MagicUploadHelper';
import { useAppSelector } from '../../app/hooks';
import { updateNeedInfoFileNumber } from '../../store/investor/action';
import { useDispatch } from 'react-redux';

const useMagicUploadLogic = () => {
    const [params, setParams] = useSearchParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const param = useParams();
    const [isVisible, setIsVisible] = useState(false);
    const [drawerContent, setDrawerContent] = useState<'needInfo' | 'magicUpload'>('magicUpload');

    useEffect(() => {
        const magicUpload = params.get('magicUpload') === 'true';
        const needInfo = params.get('needInfo') === 'true';

        if (magicUpload) {
            setDrawerContent('magicUpload');
            setIsVisible(true);
        } else if (needInfo) {
            setDrawerContent('needInfo');
            setIsVisible(true);
        }
    }, [params]);

    const changeDrawerContent = (content: 'needInfo' | 'magicUpload') => {
        setDrawerContent(content);
        // change the url
        if (content === 'needInfo') {
            params.set('needInfo', 'true');
            params.delete('magicUpload');
        } else {
            params.set('magicUpload', 'true');
            params.delete('needInfo');
        }
        setParams(params, { state: location.state });
    };

    const getPendingDocumentsCount = () => {
        getDataGapDocs(
            {
                assesseeOrgId: param.id ?? '',
            },
            {
                onSuccess: (res) => {
                    dispatch(updateNeedInfoFileNumber(res.pendingCount));
                },
            },
        );
    };

    const toggleDrawer = () => {
        setIsVisible(false);
        if (params.get('needInfo') === 'true') {
            getPendingDocumentsCount();
        }
        setTimeout(() => {
            params.delete('magicUpload');
            params.delete('isCompany');
            params.delete('needInfo');
            params.delete('showNeedInfo');
            params.delete('disableSendReminder');
            setParams(params, { state: location.state });
        }, 500);
    };

    return {
        isVisible,
        toggleDrawer,
        setIsVisible,
        drawerContent,
        changeDrawerContent,
    };
};

export default useMagicUploadLogic;
