import axios from 'axios';

export function fetchDataByConfig(payload: any, url: string, httpMethod: string) {
    return axios({
        url: API_ENDPOINTS[url],
        method: httpMethod,
        [httpMethod === 'POST' ? 'data' : 'params']: {
            ...payload,
        },
    });
}

const API_ENDPOINTS = {
    GET_ALL_LENDERS: '/account/getAllLenders',
};
