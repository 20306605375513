// src/components/ManageDealDrawer/ManageDealDrawerHeader.tsx

import React from 'react';
import styles from '../ManageDealDrawer.module.scss';

type ManageDealDrawerHeaderProps = {
    drawerLabel: string;
    investorName?: string;
};

export const ManageDealDrawerHeader: React.FC<ManageDealDrawerHeaderProps> = ({
    drawerLabel,
    investorName,
}) => {
    return (
        <div className={styles.DrawerHeading}>
            <div className={styles.DrawerHeadingTitle}>
                {drawerLabel}
                {investorName && (
                    <span className={styles.CompanyName}>
                        <span className={styles.VerticalSeparator}>|</span>
                        {investorName}
                    </span>
                )}
            </div>
        </div>
    );
};
