import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../../../app/hooks';
import { Button, Input } from '../../../../common/_custom';
import Form from '../../../../common/_custom/Form/Form';
import {
    EMAIL,
    EMAIL_PHONE_ERROR_MSG,
    PASSWORD,
    PASSWORD_ERROR_MSG,
} from '../../../../utils/constants/companyLogin';
import { companyFormValidations } from '../../../../utils/constants/formValidations';
import { BASE_ROUTES } from '../../../../utils/constants/routesConst';
import { CompanyLoginData, INDIA_COUNTRY_CODE_DATA } from '../CompanyLogin';
import styles from './CompanyLoginForm.module.scss';

interface CompanyLoginFormProps {
    formData: CompanyLoginData;
    handleFormChange: Function;
    handleSubmitClick: Function;
    handleLoginWithOtpClick: Function;
    handleForgotPasswordClick: Function;
}

function CompanyLoginForm({
    formData,
    handleFormChange,
    handleSubmitClick,
    handleLoginWithOtpClick,
    handleForgotPasswordClick,
}: CompanyLoginFormProps) {
    const navigate = useNavigate();

    const [loginUsingOtp, setLoginUsingOtp] = useState(true);
    const loggingIn = useAppSelector((state) => state.user.loggingIn);
    const [queryParams, setQueryParams] = useSearchParams();
    const queryEmail = queryParams.get('email');

    const isLoginUsingInvite = !!(queryEmail && companyFormValidations.isEmailValid(queryEmail));
    const [errors, setErrors] = useState({
        [EMAIL]: false,
        [PASSWORD]: false,
    });

    useEffect(() => {
        if (queryEmail && companyFormValidations.isEmailValid(queryEmail))
            handleFormChange(queryEmail, EMAIL);
    }, [queryEmail]);

    const changeValue = (val, key) => {
        setErrors((errors) => ({
            ...errors,
            [key]: false,
        }));
        handleFormChange(val, key);
    };

    const onSubmit = () => {
        setErrors({
            [EMAIL]:
                !companyFormValidations.isEmailValid(formData[EMAIL]) &&
                !companyFormValidations.isPhoneValid(formData[EMAIL], INDIA_COUNTRY_CODE_DATA),
            [PASSWORD]: !companyFormValidations.isPasswordValid(formData[PASSWORD]),
        });
        const allValid =
            (companyFormValidations.isEmailValid(formData[EMAIL]) ||
                companyFormValidations.isPhoneValid(formData[EMAIL], INDIA_COUNTRY_CODE_DATA)) &&
            companyFormValidations.isPasswordValid(formData[PASSWORD]);
        if (allValid) handleSubmitClick();
    };

    return (
        <div className={styles.CompanyLoginFormContainer}>
            <div className={styles.Title}>Getting Started!</div>
            <Form>
                <Input
                    placeholder="Enter Email / Phone Number"
                    value={formData[EMAIL]}
                    onChange={(val: string) => changeValue(val.trim(), EMAIL)}
                    errorMessage={EMAIL_PHONE_ERROR_MSG}
                    isValid={!errors[EMAIL]}
                    disabled={isLoginUsingInvite}
                />
                {!isLoginUsingInvite && !loginUsingOtp && (
                    <Input
                        placeholder="Password"
                        type="password"
                        value={formData[PASSWORD]}
                        onChange={(val: string) => changeValue(val, PASSWORD)}
                        errorMessage={PASSWORD_ERROR_MSG}
                        isValid={!errors[PASSWORD]}
                    />
                )}

                <div
                    className={styles.Buttons}
                    style={{ marginTop: isLoginUsingInvite ? '0' : '' }}
                >
                    {loginUsingOtp && (
                        <Button
                            text="Login With OTP"
                            loadingText="Please Wait"
                            onClick={() => handleLoginWithOtpClick()}
                            variant={'primary'}
                            disabled={loggingIn}
                        />
                    )}

                    {!isLoginUsingInvite && (
                        <Button
                            type="button"
                            text={loginUsingOtp ? 'Login with Password' : 'Login with OTP'}
                            variant="secondary"
                            onClick={() => {
                                if (loginUsingOtp) setLoginUsingOtp((val) => !val);
                                else handleLoginWithOtpClick();
                            }}
                        />
                    )}

                    {!isLoginUsingInvite && !loginUsingOtp && (
                        <Button
                            text="Login With Password"
                            loadingText="Please Wait"
                            onClick={onSubmit}
                            disabled={isLoginUsingInvite}
                            loading={loggingIn}
                            variant="primary"
                        />
                    )}
                </div>

                {!isLoginUsingInvite && (
                    <>
                        {!loginUsingOtp && (
                            <button
                                className={styles.Forgot}
                                onClick={() => handleForgotPasswordClick()}
                            >
                                Forgot Password?
                            </button>
                        )}

                        <div className={styles.Signup}>
                            Need an account?&nbsp;
                            <button
                                className={styles.Create}
                                onClick={() => navigate(BASE_ROUTES.HOME)}
                            >
                                Create Account.
                            </button>
                        </div>
                    </>
                )}
            </Form>
        </div>
    );
}

export default CompanyLoginForm;
