import useFetchDataForMU, { MagicUploadFileData } from '../../useFetchDataForMU';
import styles from './MagicUploadFilesTable.module.scss';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ErrorStateFileIconMU, fileIconDataRoom } from '../../../../assets/hostedassets';
import CircularSearchBar from '../CircularSearchBar/CircularSearchBar';
import CircularSelect from '../CircularSelect/CircularSelect';
import { deleteDoc, getDocStatus, pollZipDocStatuses } from '../../MagicUploadHelper';
import { useSearchParams } from 'react-router-dom';
import AIText from '../../../AIText/AIText';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../../../app/hooks';
import MagicUploadPreviewer from '../MagicUploadPreviewer/MagicUploadPreviewer';
import { random } from 'lodash';
import dayjs from 'dayjs';
import { Mixpanel } from '../../../../utils/mixpanel';
import { Player } from '@lottiefiles/react-lottie-player';
import { PASSWORD_PROTECTION_STATUS } from '../../Utils/PasswordProtectionUtils';
import EnterPassword from '../EnterPassword/EnterPassword';
import { getSubdomain, isEmpty } from '../../../../utils/utils';
import FilteredHeader from '../FilteredHeader/FilteredHeader';
import FilteredPills from '../FilteredPills/FilteredPills';
import ThreeDotsIcon from '../../../../assets/Svg/ThreeDotsIcon';
import { getSourceIcon, SourceType } from '../../Utils/MagicUploadUtils';
import OpenMuOrgIcon from '../../../../assets/Svg/OpenMUOrgIcon';

const INGESTED_DATA_TYPES = [
    'Bank Statements',
    'Audited Financials',
    'Provisional Financials',
    'Debt Schedule',
    'GSTR3B Report',
];

const MAX_SIMULTANEOUS_ANIMATIONS = 5; // Limit the number of animations

const MagicUploadFilesTable = ({
    tabState,
    fileData,
    setFileData,
}: {
    tabState: string;
    fileData: MagicUploadFileData[];
    setFileData: React.Dispatch<React.SetStateAction<MagicUploadFileData[]>>;
}) => {
    const [queryParams, setQueryParams] = useSearchParams();
    const {
        orgList,
        setSearchText,
        setIndex,
        index,
        selectOrg,
        documentList,
        selectDocType,
        fetchFileData,
        downloadTheFile,
        getDocumentList,
        getUntaggedStatus,
        setUpdatedFileData,
        submitPassword,
    } = useFetchDataForMU(tabState, fileData, setFileData, true);
    const investorOrgId = useAppSelector((state) => state.user.userData.investorOrgId);
    const [loading, setLoading] = useState({});
    const [loaderWidth, setLoaderWidth] = useState({});
    const loggedInUser = useAppSelector((state) => state.user.userData);
    const [showOptions, setShowOptions] = useState<number | null>(null);
    const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const zipPollingIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const [docIdToView, setDocIdToView] = useState('');
    const [docName, setDocName] = useState('');
    const [processingList, setProcessingList] = useState<string[]>([]);
    const [zipProcessingList, setZipProcessingList] = useState<string[]>([]);
    const dropdownRef = useRef<HTMLTableDataCellElement>(null);
    const isCompany = queryParams.get('isCompany') === 'true';
    const [animationQueue, setAnimationQueue] = useState<string[]>([]);
    const [filters, setFilters] = useState<{
        assesseeOrgIdList: { value: string; label: string }[];
        userIds: { value: string; label: string }[];
        sources: { value: string; label: string }[];
    }>({
        assesseeOrgIdList: [],
        userIds: [],
        sources: [],
    });

    // Pagination states
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);

    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const fetchMoreData = () => {
        if (isLoadingMore || !hasMore) return;
        setIsLoadingMore(true);
        fetchFileData(page + 1, filters)
            .then(({ isFetched, hasMore }) => {
                setPage((prevPage) => prevPage + 1);
                setHasMore(hasMore);
                setIsLoadingMore(false);
            })
            .catch(() => {
                setHasMore(false);
                setIsLoadingMore(false);
            });
    };

    const ignoreNextFiltersEffect = useRef(false);

    useEffect(() => {
        const userId =
            sessionStorage.getItem('platform_user_id') || localStorage.getItem('platform_user_id');

        // Reset page and hasMore
        setPage(0);
        setHasMore(true);

        // Determine new filters based on tabState
        const newFilters = !isCompany
            ? {
                  assesseeOrgIdList: [],
                  userIds: [{ value: userId || '', label: loggedInUser?.userName || '' }],
                  sources: [],
              }
            : { assesseeOrgIdList: [], userIds: [], sources: [] };

        // Set flag to ignore the next filters effect
        ignoreNextFiltersEffect.current = true;
        setFilters(newFilters);

        // Fetch data immediately with new filters
        fetchFileData(0, newFilters);
    }, [tabState]); // Dependencies: tabState, isCompany, loggedInUser

    useEffect(() => {
        if (ignoreNextFiltersEffect.current) {
            ignoreNextFiltersEffect.current = false;
            return;
        }
        fetchFileData(0, filters);
        setPage(0);
        setHasMore(true);
    }, [filters]); // Dependency: filters

    const handleScroll = () => {
        if (scrollContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 50) {
                fetchMoreData();
            }
        }
    };

    useEffect(() => {
        const scrollDiv = scrollContainerRef.current;
        if (scrollDiv) {
            scrollDiv.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (scrollDiv) {
                scrollDiv.removeEventListener('scroll', handleScroll);
            }
        };
    }, [isLoadingMore, hasMore, filters]);

    useEffect(() => {
        if (fileData?.length > 0) {
            fileData?.forEach((file) => {
                if (file?.status !== 'tagged' && file?.status !== 'pre-tagged') {
                    if (loading[file?.id]) return;
                    setLoading((prev) => ({ ...prev, [file?.id]: true }));
                    setLoaderWidth((prev) => ({ ...prev, [file?.id]: 0.5 }));
                }
            });
        }
    }, [fileData]);

    useEffect(() => {
        const timeouts: NodeJS.Timeout[] = [];
        fileData?.forEach((file) => {
            if (file?.status === 'pre-tagged') {
                const timeout = setTimeout(() => {
                    setUpdatedFileData({
                        [file?.id]: {
                            ...file,
                            status: 'tagged',
                        },
                    });
                }, 2500);
                timeouts.push(timeout);
                // Update the animation queue
                setAnimationQueue((prev) => prev.filter((id) => id !== file.id));
            }
        });
        return () => {
            timeouts.forEach(clearTimeout);
        };
    }, [processingList, fileData]);

    // Update animation queue when fileData changes
    useEffect(() => {
        let isSubscribed = true;
        const preTaggedFiles = fileData
            ?.filter((file) => file.status === 'pre-tagged')
            ?.slice(0, MAX_SIMULTANEOUS_ANIMATIONS);
        console.log('preTaggedFiles', preTaggedFiles);

        if (preTaggedFiles?.length > 0 && isSubscribed) {
            setAnimationQueue(preTaggedFiles.map((file) => file.id));
        } else {
            if (isSubscribed) {
                setAnimationQueue([]);
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [fileData]);
    const renderThreeDotsOptions = useCallback(
        (file) => {
            return (
                <>
                    <span
                        onMouseDown={async (e) => {
                            e.preventDefault();
                            await downloadTheFile(file?.docId, file?.fileName);
                        }}
                    >
                        Download
                    </span>
                    <span
                        onMouseDown={(e) => {
                            e.preventDefault();
                            setDocIdToView(file?.docId);
                            setDocName(file?.fileName);
                        }}
                    >
                        View
                    </span>
                    <span
                        onMouseDown={async (e) => {
                            e.preventDefault();
                            await deleteDocHandler(file?.id, false);
                        }}
                        style={{ color: '#DF666A' }}
                    >
                        Delete
                    </span>
                </>
            );
        },
        [downloadTheFile],
    );

    useEffect(() => {
        if (pollingIntervalRef.current) {
            clearInterval(pollingIntervalRef.current as NodeJS.Timeout);
        }
        setProcessingList([]);
    }, [tabState]);

    useEffect(() => {
        if (investorOrgId) getDocumentList();
    }, [investorOrgId]);

    const handleClickOutside = useCallback(
        (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowOptions(null);
            }
        },
        [dropdownRef],
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    const pollZipStatus = useCallback(
        async (docIds) => {
            await pollZipDocStatuses(
                { ids: docIds },
                {
                    onSuccess: (res) => {
                        docIds.forEach(async (docId) => {
                            res[docId]?.ids?.forEach((id) => {
                                setProcessingList((prev: any) => {
                                    if (!prev.includes(id)) {
                                        return [...prev, id];
                                    }
                                    return prev;
                                });
                            });
                            if (res?.[docId]?.complete) {
                                setUpdatedFileData({
                                    [docId]: undefined,
                                });
                                await deleteDocHandler(docId, true);
                                setZipProcessingList((prev) => prev.filter((id) => id !== docId));
                            }
                        });
                    },
                },
            );
        },
        [setUpdatedFileData, setProcessingList, setZipProcessingList],
    );

    const pollStatus = useCallback(
        async (docIds) => {
            await getDocStatus(
                { ids: docIds },
                {
                    onSuccess: (res) => {
                        if (res.length > 0) {
                            res.forEach((doc) => {
                                if (doc.status === 'tagged') {
                                    setProcessingList((prev) => prev.filter((id) => id !== doc.id));
                                    // Update the file data
                                    setUpdatedFileData({
                                        [doc.id]: {
                                            ...doc,
                                            status: 'pre-tagged',
                                            lastUpdated: dayjs(doc.lastUpdated).format(
                                                'DD MMM YYYY',
                                            ),
                                        },
                                    });
                                    // Update the animation queue
                                    setAnimationQueue((prev) => prev.filter((id) => id !== doc.id));
                                    if (doc.orgName) {
                                        Mixpanel.track('Company Name Tagged', {
                                            fileName: doc.fileName,
                                            'Company Name': doc.orgName,
                                            source: 'Website',
                                            uploadedBy: doc.uploadedBy,
                                            'Tagged By': 'System',
                                            AicaDomain: getSubdomain(),
                                        });
                                    }
                                    if (doc.docType) {
                                        Mixpanel.track('Document Type Tagged', {
                                            fileName: doc.fileName,
                                            'Document Type': doc.docType,
                                            source: 'Website',
                                            uploadedBy: doc.uploadedBy,
                                            'Tagged By': 'System',
                                            AicaDomain: getSubdomain(),
                                        });
                                    }
                                    getUntaggedStatus();
                                    // Remove the file from the loading list
                                    setLoading((prev) => {
                                        const newLoading = { ...prev };
                                        delete newLoading[doc.id];
                                        return newLoading;
                                    });
                                } else {
                                    setUpdatedFileData({
                                        [doc.id]: {
                                            ...doc,
                                            status: 'processing',
                                            lastUpdated: dayjs(doc.lastUpdated).format(
                                                'DD MMM YYYY',
                                            ),
                                        },
                                    });
                                }
                            });
                        } else {
                            clearInterval(pollingIntervalRef.current as NodeJS.Timeout);
                        }
                        // Increase the loader width of all the files
                        setLoaderWidth((prev) => {
                            const newLoaderWidth = { ...prev };
                            docIds.forEach((id) => {
                                if (newLoaderWidth[id] < 75) {
                                    newLoaderWidth[id] += random(3.5, 10);
                                } else if (newLoaderWidth[id] > 75 && newLoaderWidth[id] < 100) {
                                    newLoaderWidth[id] += random(0.1, 1.9);
                                }
                            });
                            return newLoaderWidth;
                        });
                    },
                    onError: () => {
                        clearInterval(pollingIntervalRef.current as NodeJS.Timeout);
                    },
                },
            );
        },
        [
            fileData,
            getUntaggedStatus,
            setUpdatedFileData,
            setProcessingList,
            setLoading,
            setLoaderWidth,
            pollingIntervalRef,
        ],
    );

    const deleteDocHandler = useCallback(
        async (docId, hideToast) => {
            await deleteDoc(
                { id: docId },
                {
                    onSuccess: () => {
                        if (hideToast) {
                            setZipProcessingList((prev) => prev.filter((id) => id !== docId));
                            return;
                        }
                        setUpdatedFileData({ [docId]: undefined });
                        !hideToast && toast.success('Document Deleted Successfully');
                    },
                },
            );
        },
        [setUpdatedFileData],
    );

    useEffect(() => {
        if (fileData?.length > 0) {
            fileData?.forEach((file) => {
                if (
                    file.status !== 'tagged' &&
                    file.status !== 'pre-tagged' &&
                    file.id &&
                    !file.id.includes('temp') &&
                    ![
                        PASSWORD_PROTECTION_STATUS.PASSWORD_PROTECTED,
                        PASSWORD_PROTECTION_STATUS.PASSWORD_INVALID,
                    ].includes(file.passwordProtectionStatus ?? '') &&
                    !file.zip &&
                    !processingList.includes(file.id)
                ) {
                    setProcessingList((prev: string[]) => [...prev, file.id]);
                } else if (
                    file.zip &&
                    file.id &&
                    !file.id.includes('temp') &&
                    ![
                        PASSWORD_PROTECTION_STATUS.PASSWORD_PROTECTED,
                        PASSWORD_PROTECTION_STATUS.PASSWORD_INVALID,
                    ].includes(file.passwordProtectionStatus ?? '')
                ) {
                    setZipProcessingList((prev: string[]) =>
                        prev.includes(file.id) ? prev : [...prev, file.id],
                    );
                }
            });
        }
    }, [fileData, processingList]);

    useEffect(() => {
        if (zipProcessingList.length > 0) {
            if (zipPollingIntervalRef.current) {
                clearInterval(zipPollingIntervalRef.current);
            }
            zipPollingIntervalRef.current = setInterval(() => {
                pollZipStatus(zipProcessingList);
            }, 1000);
        } else {
            if (zipPollingIntervalRef.current) {
                clearInterval(zipPollingIntervalRef.current);
            }
        }
        return () => {
            if (zipPollingIntervalRef.current) {
                clearInterval(zipPollingIntervalRef.current);
            }
        };
    }, [zipProcessingList, pollZipStatus]);

    useEffect(() => {
        if (processingList.length > 0) {
            if (pollingIntervalRef.current) {
                clearInterval(pollingIntervalRef.current);
            }
            pollingIntervalRef.current = setInterval(() => {
                pollStatus(processingList);
            }, 1000);
        } else {
            if (pollingIntervalRef.current) {
                clearInterval(pollingIntervalRef.current);
            }
        }
        return () => {
            if (pollingIntervalRef.current) {
                clearInterval(pollingIntervalRef.current);
            }
        };
    }, [processingList, pollStatus]);

    useEffect(() => {
        setFileData([]);
    }, [tabState, setFileData]);

    const viewReport = useCallback((id) => {
        if (!id) return;
        let url = `${window.location.origin}/investor/app/data-room/${id}?fetchType=all&page=1&type=scan&tab=dataRoom`;
        window.open(url, '_blank');
    }, []);

    const handleShowOptions = useCallback(
        (index) => {
            if (showOptions !== index) {
                setShowOptions(index);
            } else {
                setShowOptions(null);
            }
        },
        [showOptions],
    );

    const getOrgName = useCallback(
        (orgName, file, rowIndex) => {
            const isPreTagged =
                file?.status === 'pre-tagged' || (file?.status === 'processing' && orgName);
            const shouldAnimate = animationQueue.includes(file.id);
            const animationClass = shouldAnimate ? styles.successAnimation : '';
            const animationDelay = `${rowIndex * 0.1}s`;

            if (orgName) {
                return (
                    <div
                        style={
                            {
                                position: 'relative',
                                '--animation-delay': animationDelay,
                            } as React.CSSProperties
                        }
                        className={`${isPreTagged ? styles.AnimationWrapper : ''}`}
                    >
                        {isPreTagged && (
                            <div className={isPreTagged ? styles.AnimatedImage : styles.dNone}>
                                <Player
                                    src={
                                        'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/json/SuccessAnimationMU.json'
                                    }
                                    style={{
                                        height: '1.5rem',
                                        width: '1.5rem',
                                    }}
                                    autoplay
                                    keepLastFrame
                                    speed={4}
                                />
                            </div>
                        )}
                        <div className={isPreTagged ? styles.SuccessAnimatedPill : ''}>
                            <span
                                onClick={(e) => {
                                    e.stopPropagation();
                                    viewReport(file?.orgId);
                                }}
                                title={orgName}
                                className={styles.OrgNamePill}
                            >
                                <OpenMuOrgIcon
                                    colour={'var(--primary-text-colour)'}
                                    height="22"
                                    width="22"
                                />
                                <span className={styles.OrgNameText}>{orgName}</span>
                            </span>
                        </div>
                    </div>
                );
            } else {
                const errorAnimationClass = shouldAnimate ? styles.errorAnimation : '';
                return (
                    <div
                        style={
                            {
                                position: 'relative',
                                '--animation-delay': animationDelay,
                            } as React.CSSProperties
                        }
                        className={`${isPreTagged ? styles.AnimationErrorWrapper : ''}`}
                    >
                        {isPreTagged && (
                            <div className={isPreTagged ? styles.AnimatedImageError : styles.dNone}>
                                <Player
                                    src={
                                        'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/json/ErrorAnimationMU.json'
                                    }
                                    style={{
                                        height: '1.5rem',
                                        width: '1.5rem',
                                    }}
                                    autoplay
                                    keepLastFrame
                                    speed={4}
                                />
                            </div>
                        )}
                        <div className={isPreTagged ? styles.ErrorAnimatedPill : ''}>
                            <CircularSearchBar
                                onAddLeadSuccess={(data) => selectOrg(data, file)}
                                onSelect={async (option) => {
                                    return await selectOrg(option, file);
                                }}
                                options={index === rowIndex ? orgList : []}
                                placeholder={'Search Name'}
                                showList={index === rowIndex}
                                onChange={(value) => {
                                    setSearchText(value);
                                }}
                                onFocus={() => {
                                    setIndex(rowIndex);
                                }}
                            />
                        </div>
                    </div>
                );
            }
        },
        [animationQueue, orgList, selectOrg, setSearchText, setIndex, viewReport],
    );

    const getDocType = useCallback(
        (docType, file, rowIndex, isLoading) => {
            const isPreTagged =
                (file?.status === 'pre-tagged' && !docType) ||
                (file?.status === 'processing' && docType);
            const shouldAnimate = animationQueue.includes(file.id);
            const animationClass = shouldAnimate ? styles.successAnimation : '';
            const animationDelay = `${rowIndex * 0.1}s`;

            return (
                <div
                    style={
                        {
                            position: 'relative',
                            '--animation-delay': animationDelay,
                        } as React.CSSProperties
                    }
                    className={
                        isPreTagged
                            ? docType
                                ? styles.AnimationWrapper
                                : styles.AnimationErrorWrapper
                            : ''
                    }
                >
                    {isPreTagged && (
                        <div
                            className={
                                isPreTagged
                                    ? docType
                                        ? styles.AnimatedImage
                                        : styles.AnimatedImageError
                                    : styles.dNone
                            }
                        >
                            <Player
                                src={
                                    docType
                                        ? 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/json/SuccessAnimationMU.json'
                                        : 'https://fl-fe-assets.s3.ap-south-1.amazonaws.com/json/ErrorAnimationMU.json'
                                }
                                style={{
                                    height: '1.5rem',
                                    width: '1.5rem',
                                }}
                                autoplay
                                keepLastFrame
                                speed={4}
                            />
                        </div>
                    )}
                    <div
                        className={
                            isPreTagged
                                ? docType
                                    ? styles.SuccessAnimatedPill
                                    : styles.ErrorAnimatedPill
                                : ''
                        }
                    >
                        <CircularSelect
                            placeholder={'Search Data Type'}
                            option={documentList}
                            onSelect={async (option) => {
                                return await selectDocType(option, file);
                            }}
                            selectedOption={docType}
                            isFinalized={tabState === 'tagged'}
                            disabled={isLoading}
                        />
                    </div>
                </div>
            );
        },
        [animationQueue, documentList, selectDocType, tabState],
    );

    const getLoadingText = useCallback((file) => {
        if (file.zip) {
            return 'Unzipping Document';
        }
        if (file.id.includes('temp')) {
            return 'Analyzing Document';
        }
        if (file.docType && !file.orgName) {
            return 'Identifying Organisation';
        }

        if (!file.docType && !file.orgName) {
            return 'Identifying Doc Type';
        }

        if (file.docType && file.orgName) {
            return 'Identifying Organisation';
        }

        return 'Analyzing Document';
    }, []);

    const emptyDataState = useCallback(() => {
        return (
            <>
                {Array.from({ length: tabState !== 'all' ? 20 : 7 }).map((_, index) => {
                    return (
                        <tr className={styles.MURowEmpty} key={index}>
                            {isCompany ? (
                                <td style={{ width: '20.5rem' }}>&nbsp;</td>
                            ) : (
                                <td style={{ width: '20rem' }}>&nbsp;</td>
                            )}
                            {!isCompany && <td style={{ width: '310px' }}>&nbsp;</td>}
                            <td style={{ width: '310px' }}>&nbsp;</td>
                            <td style={{ width: '7.1rem' }}>&nbsp;</td>
                            <td style={{ width: '9.5rem' }}>&nbsp;</td>
                            <td style={{ width: '8.688rem' }}>&nbsp;</td>
                            <td style={{ width: '3rem' }}>&nbsp;</td>
                        </tr>
                    );
                })}
            </>
        );
    }, []);

    const memoizedFileData = useMemo(() => {
        return fileData?.map((file, index) => {
            if (
                [
                    PASSWORD_PROTECTION_STATUS.PASSWORD_PROTECTED,
                    PASSWORD_PROTECTION_STATUS.PASSWORD_INVALID,
                ].includes(file?.passwordProtectionStatus ?? '')
            ) {
                return (
                    <tr
                        style={{ position: 'relative', cursor: 'pointer' }}
                        className={styles.MURow}
                        key={`${file.fileName}-${index}`}
                    >
                        <td title={file.fileName}>
                            <span
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.25rem',
                                }}
                            >
                                <img src={ErrorStateFileIconMU} alt={'File Icon'} />
                                <span style={{ color: '#DF666A' }} className={styles.TruncatedText}>
                                    {file.fileName}
                                </span>
                            </span>
                        </td>
                        <td>
                            <EnterPassword
                                key={file.id}
                                submitPassword={submitPassword}
                                setUpdatedFileData={setUpdatedFileData}
                                file={file}
                            />
                        </td>
                        {!isCompany && <td style={{ width: '310px' }}></td>}
                        <td>
                            <span>{getSourceIcon(file.source as SourceType)}</span>
                        </td>
                        <td>
                            <span title={file.uploadedBy} className={styles.TruncateText}>
                                {file.uploadedBy}
                            </span>
                        </td>
                        <td>
                            <span>{file.lastUpdated}</span>
                        </td>
                    </tr>
                );
            }
            if (file.status !== 'tagged' && file.status !== 'pre-tagged') {
                return (
                    <tr
                        key={`${file.fileName}-${index}`}
                        className={styles.MURow}
                        style={{ position: 'relative' }}
                    >
                        <td title={file.fileName}>
                            <div
                                style={{ width: `${loaderWidth[file.id]}%` }}
                                className={styles.ProgressBackground}
                            />
                            <span
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.25rem',
                                }}
                            >
                                <span className={styles.TruncatedText}>{file?.fileName}</span>
                            </span>
                        </td>
                        {!isCompany && (
                            <td style={{ width: '310px' }}>
                                <span style={{ width: '100%' }}>
                                    {file?.orgName ? (
                                        getOrgName(file?.orgName ?? '', file, index)
                                    ) : (
                                        <AIText
                                            text={getLoadingText(file)}
                                            style={{
                                                marginLeft: '0.5rem',
                                                fontFamily: 'Gilroy-Semibold',
                                                fontSize: '0.875rem',
                                            }}
                                            loadingDotsStyle={{
                                                color: 'var(--primary-text-colour)',
                                                fontSize: '0.875rem',
                                            }}
                                            showLoadingDots
                                        />
                                    )}
                                </span>
                            </td>
                        )}
                        <td style={{ width: '310px' }}>
                            <span style={{ width: '100%' }}>
                                {file.docType
                                    ? getDocType(file?.docType ?? '', file, index, true)
                                    : isCompany && (
                                          <AIText
                                              text={getLoadingText(file)}
                                              style={{
                                                  marginLeft: '0.5rem',
                                                  fontFamily: 'Gilroy-Semibold',
                                                  fontSize: '0.875rem',
                                              }}
                                              loadingDotsStyle={{
                                                  color: 'var(--primary-text-colour)',
                                                  fontSize: '0.875rem',
                                              }}
                                              showLoadingDots
                                          />
                                      )}
                            </span>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                );
            }
            return (
                <tr
                    style={{ position: 'relative', cursor: 'pointer' }}
                    className={styles.MURow}
                    key={`${file.fileName}-${index}`}
                >
                    <td
                        onClick={(e) => {
                            e.stopPropagation();
                            setDocIdToView(file.docId.toString());
                            setDocName(file.fileName);
                        }}
                        title={file.fileName}
                    >
                        <span
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.25rem',
                            }}
                        >
                            <img src={fileIconDataRoom} alt={'File Icon'} />
                            <span className={styles.TruncatedText}>{file.fileName}</span>
                        </span>
                    </td>
                    {!isCompany && (
                        <td style={{ width: '310px' }}>
                            {getOrgName(file?.orgName ?? '', file, index)}
                        </td>
                    )}
                    <td style={{ width: '310px' }}>
                        {getDocType(file?.docType ?? '', file, index, false)}
                    </td>
                    <td>
                        <span>{getSourceIcon(file.source as SourceType)}</span>
                    </td>
                    <td>
                        <span title={file.uploadedBy} className={styles.TruncateText}>
                            {file.uploadedBy}
                        </span>
                    </td>
                    <td>
                        <span>{file.lastUpdated}</span>
                    </td>
                    <td
                        ref={dropdownRef}
                        style={{ position: 'relative', cursor: 'pointer' }}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleShowOptions(index);
                        }}
                    >
                        <ThreeDotsIcon
                            colour={'var(--primary-text-colour)'}
                            style={{ cursor: 'pointer' }}
                            height="14"
                            width="14"
                        />
                        {showOptions === index && (
                            <div className={styles.MoreOptions}>{renderThreeDotsOptions(file)}</div>
                        )}
                    </td>
                </tr>
            );
        });
    }, [
        fileData,
        loaderWidth,
        showOptions,
        getOrgName,
        getDocType,
        getLoadingText,
        handleShowOptions,
        dropdownRef,
    ]);

    const FilterOptions = (items: { label: string; value: string }[], key: string) => {
        scrollContainerRef.current?.scrollTo({ top: 0 });
        setPage(0);
        setFilters((prev) => {
            const updatedFilters = { ...prev, [key]: items };
            if (JSON.stringify(prev) !== JSON.stringify(updatedFilters)) {
                return updatedFilters; // Update state only if filters actually change
            }
            return prev;
        });
    };

    return (
        <>
            <FilteredPills filteredPills={filters} setFilteredPills={setFilters} />
            <div
                ref={scrollContainerRef}
                style={
                    tabState === 'all'
                        ? { height: '55vh', overflowY: 'auto', padding: '0 0 1rem 0' }
                        : { height: '80vh', overflowY: 'auto', padding: '0 0 1rem 0' }
                }
                className={styles.MagicUploadTableWrapper}
            >
                <table className={styles.MagicUploadTable}>
                    <colgroup>
                        <col style={{ width: '20rem' }} />
                        {!isCompany && <col style={{ width: '310px' }} />}
                        <col style={{ width: '310px' }} />
                        <col style={{ width: '7.1rem' }} />
                        <col style={{ width: '9.5rem' }} />
                        <col style={{ width: '8.688rem' }} />
                        <col style={{ width: '3rem' }} />
                    </colgroup>
                    <thead style={{ position: 'sticky', top: '0', zIndex: '10' }}>
                        <tr className={styles.MUHeader}>
                            <th>File Name</th>
                            {!isCompany && (
                                <FilteredHeader
                                    onFilter={FilterOptions}
                                    title={'Organisation Name'}
                                    items={filters?.assesseeOrgIdList ?? []}
                                    disabled={false}
                                />
                            )}
                            <th>Data Type</th>
                            <FilteredHeader
                                onFilter={FilterOptions}
                                title={'Source'}
                                items={filters?.sources ?? []}
                                disabled={false}
                            />
                            <FilteredHeader
                                onFilter={FilterOptions}
                                title={'Uploaded By'}
                                items={filters?.userIds ?? []}
                                disabled={false}
                            />
                            <th>Last Updated</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className={styles.MUBody}>
                        {isEmpty(fileData) ? emptyDataState() : memoizedFileData}
                        {isLoadingMore && (
                            <tr>
                                <td
                                    colSpan={7}
                                    style={{ textAlign: 'center', padding: '2rem 0 1rem 0' }}
                                >
                                    <span style={{ width: '100%' }}>
                                        <AIText
                                            text={'Loading more data'}
                                            style={{
                                                marginLeft: '0.5rem',
                                                fontFamily: 'Gilroy-Semibold',
                                                fontSize: '0.875rem',
                                            }}
                                            loadingDotsStyle={{
                                                color: 'var(--primary-text-colour)',
                                                fontSize: '0.875rem',
                                            }}
                                            showLoadingDots
                                        />
                                    </span>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <MagicUploadPreviewer
                docIdToView={docIdToView}
                setDocIdToView={setDocIdToView}
                docName={docName}
            />
        </>
    );
};

export default React.memo(MagicUploadFilesTable);
