import styles from './CheckBox.module.scss';

type CheckBoxProps = {
    label: string;
    checked: boolean;
    onCheck: (checked: boolean) => void;
    variant: 'primary' | 'secondary';
    needPadding?: boolean;
};

const CheckBox = (props: CheckBoxProps) => {
    const { label, checked, onCheck, variant, needPadding } = props;
    return (
        <div className={`${styles.CheckBox} ${needPadding ? styles.NeedPadding : ''}`}>
            <input
                type="checkbox"
                id={label}
                checked={checked}
                onChange={(e) => onCheck(e.target.checked)}
            />
            <label htmlFor={label} className={styles[variant]}>
                {label}
            </label>
        </div>
    );
};

export default CheckBox;
