import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { fetchCompanyUploadConfig, fetchUploadJourneyData } from '../../../store/company/action';
// import { COMPANY_UPLOAD_TABS } from '../../../store/company/constants';
import { USER_TYPE } from '../../../utils/constants/user';
import { clearLocalStorageOnLogout } from '../../../utils/utils';
import UploadJourney from '../UploadJourney/UploadJourney';
import styles from './UploadData.module.scss';
import { MagicUploadBtnIconWhite, warnIconOrange } from '../../../assets/hostedassets';
import { Mixpanel } from '../../../utils/mixpanel';
import { COMPANY_LOGOUT } from '../../../utils/constants/mixpanelEvents/companyEvents';
import { toast } from 'react-toastify';
import { getUntaggedCount } from '../../../common/MagicUpload/MagicUploadHelper';
import { updateUntaggedMuFileNumber } from '../../../store/investor/action';
import { resendVerificationEmail } from '../../../store/user/userV2/actions';
import LogoutIcon from '../../../assets/Svg/Logouticon';

function UploadData({
    isInvestor = false,
    userType,
    assesseeOrgId = '',
    closeDrawer,
    overrideSelectedStep,
}: {
    overrideSelectedStep?: string;
    isInvestor?: boolean;
    userType?: string;
    assesseeOrgId?: string;
    closeDrawer?: () => void;
}) {
    const dispatch = useAppDispatch();
    let [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { id } = useParams();
    const investorData = useAppSelector((state) => state.investor);
    const companyConfig = useAppSelector((state) => state.company.config);
    const domainMetadata = useAppSelector((state) => state.user.domainMetadata);
    const uploadJourneyData = useAppSelector((state) => state.company.uploadJourneyData);
    const user = useAppSelector((state) => state.user);
    const userData = user.userData;
    const untaggedCount = useAppSelector((state) => state.investor.untaggedCount);
    const fetchingUploadJourneyData = useAppSelector(
        (state) => state.company.fetchingUploadJourneyData,
    );
    const selectedAssesseeOrg =
        searchParams.get('id') || assesseeOrgId || userData.assesseeOrgId || id;
    const [selectedOrg, setSelectedOrg] = useState<any>(null);

    useEffect(() => {
        if (
            (userData.assesseeOrgId || selectedAssesseeOrg) &&
            userData.investorOrgId &&
            ((!isInvestor && userData.investorPrimaryEmail) ||
                (isInvestor && userData.primaryEmail))
        )
            fetchConfigAndUploadData();
        //eslint-disable-next-line
    }, [
        userData.assesseeOrgId,
        userData.primaryEmail,
        userData.investorOrgId,
        userData.investorPrimaryEmail,
        isInvestor,
        selectedAssesseeOrg,
    ]);

    useEffect(() => {
        const companiesList = investorData?.portfolio?.companiesList;

        if (selectedAssesseeOrg && companiesList?.length) {
            const selectedOrg = companiesList?.find(
                (company) => company?.assesseeOrgId === selectedAssesseeOrg,
            );
            selectedOrg && setSelectedOrg(selectedOrg);
        }
    }, [selectedAssesseeOrg, investorData]);

    const getUntaggedStatus = () => {
        getUntaggedCount(
            {
                pnoId: user.userData.investorOrgId,
                orgId: user.userData.assesseeOrgId,
            },
            {
                onSuccess: (res) => {
                    dispatch(updateUntaggedMuFileNumber(res));
                },
            },
        );
    };

    useEffect(() => {
        if (user) getUntaggedStatus();
    }, [user]);

    const fetchConfigAndUploadData = () => {
        dispatch(
            fetchCompanyUploadConfig({
                accountType: userType ?? '',
                primaryEmai: userData.primaryEmail, // isInvestor ? userData.primaryEmail : userData.investorPrimaryEmail,
                investorOrgId: userData.investorOrgId,
                configName: isInvestor
                    ? 'AICA_BORROWER_ONBOARDING_DATA_BY_LENDER'
                    : 'AICA_BORROWER_ONBOARDING_DATA_BY_BORROWER',
                assesseeOrgId: selectedAssesseeOrg || userData.assesseeOrgId || '',
            }),
        );
    };
    const Logout = () => {
        Mixpanel.track(COMPANY_LOGOUT);
        navigate('/login');
        clearLocalStorageOnLogout();
    };

    const fetchUploadData = () => {
        dispatch(
            fetchUploadJourneyData({
                accountType: userType,
                // primaryEmai: userData.primaryEmail,
                // investorOrgId: userData.investorOrgId,
                assesseeOrgId: userData.assesseeOrgId || selectedAssesseeOrg || '',
            }),
        );
    };

    useEffect(() => {
        if (fetchingUploadJourneyData) return;
        let timeoutId;
        if (uploadJourneyData === null) {
            timeoutId = setTimeout(() => {
                toast.error('Unauthorized access. Logging out user.');
                clearLocalStorageOnLogout();
                navigate('/login');
            }, 2000);
        }
        return () => clearTimeout(timeoutId);
    }, [uploadJourneyData, fetchingUploadJourneyData]);

    const resendEmail = () => {
        dispatch(
            resendVerificationEmail(
                {
                    user: {
                        emailId: userData.primaryEmail,
                        investorId: userData.investorOrgId,
                        accountType: userData.accountType,
                        accountTypes: [userData.accountType],
                    },
                    platformId: domainMetadata?.productName ?? '',
                },
                {
                    onSuccess: () => {
                        toast.success('Verification email sent successfully');
                    },
                },
            ),
        );
    };

    return (
        <div className={styles.UploadDataWrap}>
            {userData?.emailVerified === false && (
                <div className={styles.EmailNotVerified}>
                    <img className={styles.WarnIcon} src={warnIconOrange} alt="Warning" />
                    Please verify your email to start receiving important updates.{' '}
                    <button className={styles.ResendVerificationCta} onClick={resendEmail}>
                        Resend verification email
                    </button>
                </div>
            )}
            <div
                className={clsx(
                    {
                        [styles.NotCompany]: userData?.accountType !== USER_TYPE.COMPANY,
                    },
                    styles.UploadData,
                )}
            >
                {!isInvestor && (
                    <div className={styles.Left}>
                        <img src={domainMetadata?.mainImageUrl} alt="logo" width="40%" />
                    </div>
                )}

                <div className={styles.Right}>
                    {isInvestor ? (
                        <div className={styles.TopInvestor}>
                            Upload data {selectedOrg?.orgName ? `for ${selectedOrg?.orgName}` : ''}
                        </div>
                    ) : (
                        <div className={styles.Top}>
                            <div>
                                <div className={styles.Title}>
                                    Welcome {userData?.userName || ''}
                                </div>
                                <div className={styles.Subtext}>
                                    Upload data across all tabs to proceed with application{' '}
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                                <div style={{ position: 'relative' }}>
                                    <div
                                        className={styles.MagicUploadButton}
                                        onClick={() => {
                                            searchParams.set('magicUpload', 'true');
                                            searchParams.set('isCompany', 'true');
                                            setSearchParams(searchParams);
                                        }}
                                    >
                                        <img
                                            src={MagicUploadBtnIconWhite}
                                            alt={'MagicUploadIcon'}
                                            height={'16px'}
                                            width={'16px'}
                                        />
                                        <span className={styles.Text}>Magic Upload</span>
                                    </div>
                                    {untaggedCount > 0 && (
                                        // <img
                                        //     src={MUExclamationRed}
                                        //     alt={'exclamation'}
                                        //     className={styles.ExclamationIcon}
                                        // />
                                        <div className={styles.redDot}>{untaggedCount}</div>
                                    )}
                                </div>
                                <div className={styles.Logout} onClick={Logout}>
                                    <LogoutIcon
                                        color={'var(--secondary-button-text)'}
                                        width={'16'}
                                        height={'16'}
                                    />
                                    Logout
                                </div>
                            </div>
                        </div>
                    )}

                    <div
                        className={clsx(styles.UploadContainer, {
                            [styles.UploadContainerInvestor]: isInvestor,
                        })}
                    >
                        <UploadJourney
                            overrideSelectedStep={overrideSelectedStep}
                            companyConfig={companyConfig}
                            userType={userType}
                            assesseeOrgId={selectedAssesseeOrg}
                            fetchOnboardingData={fetchUploadData}
                            isInvestor={isInvestor}
                            closeDrawer={closeDrawer}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UploadData;
