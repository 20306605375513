import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../common/_custom';
import BrokenPage from './BrokenPage';

function DomainNotFound() {
    const navigate = useNavigate();
    return (
        <div
            style={{
                height: '100vh',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div
                style={{
                    border: '1px solid #e5e5e5',
                    background: 'white',
                    padding: '30px',
                    borderRadius: '8px',
                    height: '45%',
                    width: '25%',

                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                }}
            >
                <BrokenPage />
                <div style={{ fontSize: '16px', fontWeight: '600', color: '#9D9D9D' }}>
                    Oops! Looks like this page is broken
                </div>
                <div
                    style={{
                        fontSize: '16px',
                        fontWeight: '600',
                        textAlign: 'center',
                        marginTop: '1.5rem',
                    }}
                >
                    Please Refresh
                </div>
                <Button
                    text="Refresh"
                    style={{ width: '100%' }}
                    onClick={() => {
                        navigate('/investor');
                        document.location.reload();
                    }}
                />
            </div>
        </div>
    );
}

export default DomainNotFound;
