// src/components/ManageDealDrawer/useManageDealDrawer.ts

import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

// Redux hooks & actions (replace paths with your own)
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    createDeal,
    createInvestmentPreference,
    getAllInvestmentPreferences,
    getDealData,
    getInvestmentPreference,
    getLenderDealData,
    getMetricsForDealCreation,
    getPecMatchingData,
    setDealDrawer,
    updateInvestmentPreference,
} from '../../store/investor/action';
import {
    fetchAllAvailableProducts,
    fetchCompanySectorsList,
    getDealFormConfig,
} from '../../store/investor/apiHelpers';
import {
    getCompanyMetrics,
    getDebtProductsData,
    updateCompanyMetrics,
} from '../../store/debtProducts/apiHelper_debtProducts';

// Utilities (replace paths with your own)
import {
    flatten,
    unflatten,
    isEmpty,
    getPlatformUserId,
    getSubdomain,
    convertToFirstLetterCapital,
} from '../../utils/utils';
import { initializeDatepickerDates } from '../../utils/dateUtils';
import { updateQueryParams } from '../../utils/searchParamUtils';
import { Mixpanel } from '../../utils/mixpanel';
import { useWindowUnloadEffect } from '../../utils/constants/hooks';

// Constants
import {
    AICA_COMPANY_PROFILE,
    AICA_CREATE_DEAL_DETAILS,
    AICA_GET_COMPANY_SECTOR_LIST,
    AICA_INVESTMENT_PREFERENCE,
    CONFIG_STEP,
    IMPORTANCE_LEVELS,
    EXCLUDED_LABEL_TYPES,
    UPLOAD_FILES_PREFIXES,
    USER_TYPE,
    DEBT_PRODUCT_FILTERS,
    PEC_STATUS,
    AICA_YOUR_PROFILE,
    PROFILE,
    PROFILE_BUTTON,
} from './DealDrawerConstants';
import { fetchConfigByConfigName } from '../../store/investor/investorApiHelper';
import { setDebtProductsFilters } from '../../store/company/action';

interface UseManageDealDrawerProps {
    source: string;
    onClose?: Function;
}

export function useManageDealDrawer({ source, onClose }: UseManageDealDrawerProps) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const urlParams = useParams();
    const [params, setParams] = useSearchParams();

    // Redux states
    const dealDrawerState = useAppSelector((state) => state.investor.dealDrawer);
    const loggedInUser = useAppSelector((state) => state.user.userData);
    const platformData = useAppSelector((state) => state.user.domainMetadata);
    const user = useAppSelector((state) => state.user);
    const userData = user?.userData;

    // Local states
    const [formConfig, setFormConfig] = useState<any>(null);
    const [stepperConfig, setStepperConfig] = useState<any>(null);
    const [formData, setFormData] = useState<{ [key: string]: any }>({});
    const [step, setStep] = useState<number>(1);
    const [disablePrimary, setDisablePrimary] = useState<boolean>(true);
    const [disableSecondary, setDisableSecondary] = useState<boolean>(step === 1);
    const [lenderList, setLenderList] = useState<{ id: string; name: string }[]>([]);
    const [preFilledDataFetched, setPreFilledDataFetched] = useState<boolean>(false);
    const [loadingPrefilledData, setLoadingPrefilledData] = useState<boolean>(false);
    const [animationClass, setAnimationClass] = useState<string>('');
    const [investorName, setInvestorName] = useState('');
    const [investmentPreferenceId, setInvestmentPreferenceId] = useState<string | null>(null);
    const [isFromComplete, setIsFromComplete] = useState<boolean>(false);
    const [restrictedMap, setRestrictedMap] = useState<Map<string, boolean>>(new Map());
    const [loanTypeOptions, setLoanTypeOptions] = useState<any>({});
    const [loanTypeNameToIdMap, setLoanTypeNameToIdMap] = useState(new Map<string, string>());
    const debtProductFilters = useAppSelector((state) => state.company.debtProductsFilters);
    const [duplicate, setDuplicate] = useState(false);
    const [filterType, setFilterType] = useState('');
    const [sectorList, setSectorList] = useState<any[]>([]);

    const subDomain = getSubdomain();
    const userOrgId =
        sessionStorage.getItem('user_org_id') ?? localStorage.getItem('user_org_id') ?? '';
    const isLender = userData?.accountType === USER_TYPE.LENDER;
    const flowId = 16;

    // Refs
    const dealDrawerBodyRef = useRef<any>(null);
    const stepContainerRef = useRef<any>(null);

    // -------------------------------------
    // REUSABLE FUNCTIONS / SIDE EFFECTS
    // -------------------------------------

    // 1. Create a map of required form keys by scanning the entire `formConfig`.
    const createMapOfRequiredVsSectionKeys = () => {
        const requiredMap = new Map<string, boolean>();
        if (!formConfig) return;

        for (const currentStepConfig of formConfig) {
            for (const config of currentStepConfig.config) {
                const sectionKey = config?.sectionKey;
                for (const key in config?.config) {
                    const fieldConfig = config.config[key];
                    let formKey = sectionKey ? `${sectionKey}.${key}` : key;

                    if (fieldConfig.isRequired) {
                        if (fieldConfig.type === 'range') {
                            let minKey = `minimum${convertToFirstLetterCapital(key)}`;
                            let maxKey = `maximum${convertToFirstLetterCapital(key)}`;
                            if (sectionKey) {
                                minKey = `${sectionKey}.${minKey}`;
                                maxKey = `${sectionKey}.${maxKey}`;
                            }
                            requiredMap.set(minKey, true);
                            requiredMap.set(maxKey, true);
                        } else {
                            requiredMap.set(formKey, true);
                        }
                    }
                }
            }
        }
        setRestrictedMap(requiredMap);
    };

    // 2. Check if the entire form is complete by comparing `formData` with `restrictedMap`.
    const checkCompleteForm = () => {
        let isValid = true;
        for (const [key] of restrictedMap) {
            if (!formData[key]) {
                isValid = false;
                break;
            }
        }
        setIsFromComplete(isValid);
    };

    // 3. Validate the current step (enable/disable primary button).
    const validateForm = () => {
        if (!formConfig) return;
        const currentStepConfig = formConfig?.[step - 1];
        if (!currentStepConfig) return;

        const currentStepKeys: string[] = [];
        for (const config of currentStepConfig?.config ?? []) {
            const sectionKey = config?.sectionKey;
            for (const key in config.config) {
                const fieldConfig = config.config[key];
                if (!fieldConfig.isRequired) continue;

                let formKey = sectionKey ? `${sectionKey}.${key}` : key;

                // if it's a range
                if (fieldConfig.type === 'range') {
                    let minKey = `minimum${convertToFirstLetterCapital(key)}`;
                    let maxKey = `maximum${convertToFirstLetterCapital(key)}`;
                    let importanceKey = `importance${convertToFirstLetterCapital(key)}`;
                    if (sectionKey) {
                        minKey = `${sectionKey}.${minKey}`;
                        maxKey = `${sectionKey}.${maxKey}`;
                        importanceKey = `${sectionKey}.${importanceKey}`;
                    }
                    currentStepKeys.push(minKey, maxKey);
                    if (fieldConfig.importance) {
                        currentStepKeys.push(importanceKey);
                    }
                }
                // if there's "importance"
                else if (fieldConfig.importance) {
                    let importanceKey = `importance${convertToFirstLetterCapital(key)}`;
                    if (sectionKey) {
                        importanceKey = `${sectionKey}.${importanceKey}`;
                    }
                    currentStepKeys.push(formKey, importanceKey);
                } else {
                    currentStepKeys.push(formKey);
                }
            }
        }
        // all values must be present in formData
        const isFormValid = currentStepKeys.every(
            (key) => key in formData && !isEmpty(formData[key]),
        );
        setDisablePrimary(!isFormValid);
    };

    // 4. Close the drawer
    const closeDrawer = (payload?: any) => {
        params.delete('investmentPreferenceId');
        params.delete('Duplicate');
        updateQueryParams(params, setParams, {});
        dispatch(setDealDrawer({ open: false }));
        if (source === DEBT_PRODUCT_FILTERS) {
            params.set('filters', 'true');
            updateQueryParams(params, setParams, {});
            onClose?.(payload ?? debtProductFilters);
        } else {
            onClose?.(debtProductFilters);
        }
    };

    // 5. Generic handle change
    const handleChange = (key: string, value: any) => {
        // in case the value is empty we need to delete the key
        if (value === '' || value === null) {
            setFormData((prev) => {
                const updatedData = { ...prev };
                delete updatedData[key];
                return updatedData;
            });
        } else {
            setFormData((prev) => ({ ...prev, [key]: value }));
        }
    };

    // 6. Move to next / previous step
    const navigateStep = (newStep: number, animation: string) => {
        setAnimationClass(animation);
        setTimeout(() => {
            setStep(newStep);
        }, 300);
    };

    const handlePrimary = () => {
        if (source === DEBT_PRODUCT_FILTERS) {
            let payload: any = unflatten(formData);
            if (!isEmpty(payload)) payload.filters.filterType = filterType;
            dispatch(setDebtProductsFilters(payload));
            closeDrawer(payload);
            return;
        }
        if (source === 'Debt Products') {
            // Update metrics, then navigate
            companyMetricsUpdate(() => {
                if (step === formConfig?.length) {
                    closeDrawer();
                } else {
                    navigateStep(step + 1, 'slide-down');
                }
            });
            return;
        } else if (source === PROFILE_BUTTON) {
            createOrUpdatePEC(() => {
                setAnimationClass('slide-down');
            }, false);
            return;
        }

        if (dealDrawerState.isPec) {
            // create or update PEC
            createOrUpdatePEC(() => navigateStep(step + 1, 'slide-down'), false);
            return;
        }

        // normal "Create Deal" flow
        const currentConf = formConfig?.[step - 1];
        if (currentConf?.step === CONFIG_STEP.SELECT_INVESTORS) {
            handleCreateDeal();
            return;
        }

        if (step + 1 > formConfig.length) return;
        navigateStep(step + 1, 'slide-down');
    };

    const handleSecondary = () => {
        if (source === DEBT_PRODUCT_FILTERS) {
            setFormData({});
            dispatch(setDebtProductsFilters({}));
        }
        if (step <= 1) return;
        navigateStep(step - 1, 'slide-up');
    };

    const handleStepperClick = (index: number) => {
        const direction = index > step ? 'slide-up' : 'slide-down';
        if (dealDrawerState.isPec) {
            createOrUpdatePEC(() => navigateStep(index, direction), true);
        } else {
            navigateStep(index, direction);
        }
    };

    // 7. Create or Update "PEC"
    const createOrUpdatePEC = (navigationFunction: () => void, isStepper: boolean) => {
        setLoadingPrefilledData(true);
        const payload: any = unflatten(formData);
        payload.aggregatorId = platformData?.investorOrgId;
        payload.lenderId = userOrgId;
        payload.activityBy = loggedInUser?.userName;
        payload.type =
            userData?.accountType === USER_TYPE.INVESTOR ? 'ADMIN' : userData?.accountType;
        payload.status = isFromComplete ? 'COMPLETE' : 'PENDING';

        payload.products = payload?.preferredTerms?.loanTypes
            ?.map((productName: string) => loanTypeNameToIdMap.get(productName))
            ?.filter(Boolean);

        const isUpdate =
            (investmentPreferenceId && !params.has('Duplicate')) ||
            (source === PROFILE_BUTTON && formData?.id);

        if (isUpdate) {
            // updating existing
            payload.investmentPreferenceId = investmentPreferenceId;
            Mixpanel.track('PEC Edited', {
                userEmail: userData?.primaryEmail,
                PNOId: userData?.investorOrgId,
                PECId: investmentPreferenceId,
                LoanTypes: payload.products,
                MetricsUpdated: payload,
                UpdatedBy: userData?.userName,
                LastUpdated: new Date().toISOString(),
            });

            dispatch(
                updateInvestmentPreference(payload, {
                    onSuccess: () => {
                        setLoadingPrefilledData(false);
                        if (
                            !isStepper &&
                            formConfig?.[step - 1]?.ctas?.primary?.text === 'Save & Close'
                        ) {
                            closeDrawer();
                            const meesage = getToastMessages(source);
                            toast.success(meesage.success);
                            return;
                        }
                        navigationFunction();
                    },
                    onError: () => {
                        setLoadingPrefilledData(false);
                        const meesage = getToastMessages(source);
                        toast.error(meesage.error);
                    },
                }),
            );
        } else {
            // creating new
            payload.investmentPreferenceType = source === PROFILE_BUTTON ? PROFILE : PEC_STATUS.PEC;
            if (params.has('Duplicate')) {
                Mixpanel.track(PEC_STATUS.PEC_DUPLICATED, {
                    userEmail: userData?.primaryEmail,
                    PNOId: userData?.investorOrgId,
                    LoanTypes: payload.products,
                    DuplicatedBy: userData?.userName,
                    DuplicationTime: new Date().toISOString(),
                });
            } else {
                Mixpanel.track(PEC_STATUS.PEC_CREATED, {
                    userEmail: userData?.primaryEmail,
                    PNOId: userData?.investorOrgId,
                    PNOName: subDomain,
                    LoanTypes: payload.products,
                    CreationTime: new Date().toISOString(),
                });
            }
            dispatch(
                createInvestmentPreference(payload, {
                    onSuccess: (res) => {
                        if (source === PROFILE_BUTTON) {
                            setLoadingPrefilledData(false);
                            closeDrawer();
                            const messages = getToastMessages(source);
                            toast.success(messages.success);
                            return;
                        }
                        params.set('investmentPreferenceId', res?.investmentPreferenceId);
                        setParams(params);
                        if (params.has('Duplicate')) {
                            params.delete('Duplicate');
                            updateQueryParams(params, setParams, {});
                        }
                        setInvestmentPreferenceId(res?.investmentPreferenceId);
                        navigateStep(step + 1, 'slide-down');
                        setLoadingPrefilledData(false);
                    },
                    onError: () => {
                        setLoadingPrefilledData(false);
                        const messages = getToastMessages(source);
                        toast.error(messages.error);
                    },
                }),
            );
        }
    };

    // 8. Update "Company Metrics" (Debt Products flow)
    const companyMetricsUpdate = (navigationFunction: () => void) => {
        updateCompanyMetrics({
            orgId: urlParams.id as string,
            parentOrgId: userData?.investorOrgId,
            companyMetrics: unflatten(formData),
        })
            .then(() => {
                navigationFunction?.();
            })
            .catch((err) => {
                console.error(err);
            });
    };

    // 9. Create Deal
    const handleCreateDeal = () => {
        const payload: any = unflatten(formData);
        payload.dealProposerId = platformData?.investorOrgId;
        payload.dealBeneficiaryId = dealDrawerState?.companyId;
        payload.dealCreatedBy = loggedInUser?.userName;
        payload.owner = {
            name: loggedInUser?.userName,
            userId: getPlatformUserId(),
            activityBy: loggedInUser?.userName,
            activityDate: new Date().toISOString(),
        };
        if (payload?.dealTerms?.dealNotes) {
            payload.dealTerms.dealNotes = [
                {
                    note: payload.dealTerms.dealNotes,
                    noteBy: loggedInUser?.userName,
                    noteDate: new Date().toISOString(),
                },
            ];
        }
        dispatch(
            createDeal(payload, {
                onSuccess: () => {
                    closeDrawer();
                    navigate('/investor/app/track', { state: { scrollPosition: 0 } });
                },
            }),
        );
    };

    // 10. Reset local storage
    const resetEditorDataInLocalStorage = () => {
        localStorage.removeItem('one_pager_content');
        localStorage.removeItem('one_pager_assessee');
    };

    // 11. Get toast messages
    const getToastMessages = (source: string) => {
        switch (source) {
            case PROFILE_BUTTON:
                return {
                    success: 'Profile Details saved successfully',
                    error: 'Failed to update profile details',
                };
            default:
                return {
                    success: 'Investment Preferences saved successfully',
                    error: 'Failed to update investment preference',
                };
        }
    };

    // -------------------------------------
    // HOOKS
    // -------------------------------------

    useWindowUnloadEffect(() => {
        resetEditorDataInLocalStorage();
    }, true);

    useEffect(() => {
        return () => {
            resetEditorDataInLocalStorage();
        };
    }, []);

    useEffect(() => {
        createMapOfRequiredVsSectionKeys();
    }, [formConfig]);

    useEffect(() => {
        checkCompleteForm();
    }, [formData, restrictedMap]);

    useEffect(() => {
        validateForm();
    }, [step, formData, formConfig]);

    // Check for "Duplicate" param
    useEffect(() => {
        if (params.has('Duplicate')) {
            setDuplicate(true);
        }
    }, [params]);

    useEffect(() => {
        if (source === DEBT_PRODUCT_FILTERS) {
            setDisableSecondary(isEmpty(formData));
        }
    }, [formData, source]);

    useEffect(() => {
        if (duplicate) {
            params.delete('investmentPreferenceId');
            updateQueryParams(params, setParams, {});
        }
    }, [duplicate, params, setParams]);

    // Load the form config
    useEffect(() => {
        if (source === DEBT_PRODUCT_FILTERS) {
            fetchConfigByConfigName({
                accountType: user.userData.accountType,
                investorOrgId: user.userData.investorOrgId,
                primaryEmail: user.userData.primaryEmail,
                assesseeOrgId: urlParams.id as string,
                userRole: user.userData?.roles?.[0],
                configName: 'AICA_DEBT_PRODUCT',
            }).then((res) => {
                if (res) {
                    setFilterType(res.additionalDetailsConfig.filters.filterType);
                    setFormConfig(res?.additionalDetailsConfig.filters.pages ?? []);
                    setStepperConfig([]);
                }
            });
            return;
        }
        if (!platformData || !loggedInUser) return;
        getDealFormConfig(
            {
                accountType: loggedInUser.accountType,
                investorOrgId: platformData?.investorOrgId,
                primaryEmail: loggedInUser?.primaryEmail,
                configName: dealDrawerState?.isPec
                    ? AICA_INVESTMENT_PREFERENCE
                    : source === 'Debt Products'
                    ? AICA_COMPANY_PROFILE
                    : source === PROFILE_BUTTON
                    ? AICA_YOUR_PROFILE
                    : AICA_CREATE_DEAL_DETAILS,
            },
            {
                onSuccess: (configData, orgName) => {
                    if (orgName) setInvestorName(orgName);
                    setFormConfig(configData?.pages ?? []);
                    setStepperConfig(configData?.stepper ?? []);
                },
            },
        );
    }, [platformData, loggedInUser, dealDrawerState?.isPec, source]);

    // Fetch data, prefill, etc. (examples)
    // Example: if there's a dealId, getDealData, etc.
    useEffect(() => {
        if (!dealDrawerState.dealId || dealDrawerState.isPec) return;
        dispatch(
            getDealData(
                { dealId: dealDrawerState.dealId },
                {
                    onSuccess: (dealData) => {
                        const dealNotes = dealData?.dealTerms?.dealNotes?.[0]?.note;
                        if (dealNotes) {
                            dealData.dealTerms.dealNotes = dealNotes;
                        }
                        setFormData(flatten(dealData));
                    },
                },
            ),
        );
    }, [dealDrawerState.dealId, dealDrawerState.isPec, dispatch]);

    useEffect(() => {
        if (!dealDrawerState.lenderDealId) return;
        dispatch(
            getLenderDealData(
                { dealId: dealDrawerState.lenderDealId },
                {
                    onSuccess: (dealData) => {
                        const dealNotes = dealData?.dealTerms?.dealNotes?.[0]?.note;
                        if (dealNotes) {
                            dealData.dealTerms.dealNotes = dealNotes;
                        }
                        setFormData(flatten(dealData));
                    },
                },
            ),
        );
    }, [dealDrawerState.lenderDealId, dispatch]);

    // If an existing PEC ID is in the query param
    useEffect(() => {
        if (!dealDrawerState.isPec && source !== PROFILE_BUTTON) return;

        const prefId = investmentPreferenceId || params.get('investmentPreferenceId') || '';
        if (!prefId && source !== PROFILE_BUTTON) return;

        setInvestmentPreferenceId(prefId);

        // For regular PEC flow
        if (prefId && source !== PROFILE_BUTTON) {
            dispatch(
                getInvestmentPreference(
                    { investmentPreferenceId: prefId },
                    {
                        onSuccess: (data: any) => {
                            if (params.has('Duplicate')) {
                                data.preferredTerms = { ...data.preferredTerms, loanTypes: [] };
                            }
                            setFormData(flatten(data));
                        },
                    },
                ),
            );
            return;
        } else if (source === PROFILE_BUTTON && !loadingPrefilledData) {
            setLoadingPrefilledData(true);
            const requestPayload = {
                aggregatorId: userData?.investorOrgId,
                investmentPreferenceType: PROFILE,
                investmentPreferenceId: prefId ?? '',
                lenderIds: isLender && userData?.lenderOrgId ? [userData.lenderOrgId] : [userOrgId],
            };

            dispatch(
                getAllInvestmentPreferences(
                    { requestPayload },
                    {
                        onSuccess: (data: any) => {
                            if (data?.[0]) {
                                setFormData(flatten(data[0]));
                                setInvestmentPreferenceId(data[0]?.id);
                            }
                            setLoadingPrefilledData(false);
                        },
                        onError: () => {
                            setLoadingPrefilledData(false);
                        },
                    },
                ),
            );
        }
    }, [dealDrawerState.isPec, params, dispatch, source, userData, isLender, userOrgId]);
    const fetchLoanTypes = async () => {
        const isDebtProducts = source === 'Debt Products';
        let payload: any = {
            aggregatorId: userData?.investorOrgId,
        };
        if (investmentPreferenceId && !params.has('Duplicate')) {
            payload.investmentPreferenceId = investmentPreferenceId;
        }
        if (isLender) {
            payload.lenderId = userData?.lenderOrgId;
        }
        try {
            const response = await fetchAllAvailableProducts(payload, isDebtProducts);
            if (response) {
                setLoanTypeOptions(response);
            }
        } catch (error) {
            console.error('Error fetching loan types:', error);
        }
    };

    // LoanTypes, Sectors, etc.
    useEffect(() => {
        if (
            (dealDrawerState.isPec &&
                formConfig?.[step - 1]?.step === CONFIG_STEP.PREFERRED_TERMS) ||
            (source === 'Debt Products' && formConfig?.[step - 1]?.step === CONFIG_STEP.DEAL_TERMS)
        ) {
            fetchLoanTypes();
        }
    }, [formConfig?.[step - 1]]);

    // Build the name->id map once we have loanTypeOptions
    useEffect(() => {
        if (loanTypeOptions) {
            const productMap = new Map<string, string>();
            Object.values(loanTypeOptions).forEach((product: any) => {
                productMap.set(product.productName, product.productId);
            });
            setLoanTypeNameToIdMap(productMap);
        }
    }, [loanTypeOptions]);

    // Example: fetch Sectors
    useEffect(() => {
        const fetchCompanySector = async () => {
            try {
                const response = await fetchCompanySectorsList();
                if (response) {
                    setSectorList(response?.[AICA_GET_COMPANY_SECTOR_LIST]?.data ?? []);
                }
            } catch (error) {
                console.error('Error fetching company sectors:', error);
            }
        };
        if (formConfig?.[step - 1]?.step === CONFIG_STEP.BORROWER_PROFILE) {
            fetchCompanySector();
        }
    }, [formConfig, step]);

    // Example: fetch default metrics if it's not PEC flow
    useEffect(() => {
        if (!platformData || !formConfig || dealDrawerState.isPec) return;

        // If source = "Debt Products Filters"
        if (source === DEBT_PRODUCT_FILTERS) {
            // make a deep copy of debtProductFilters
            let payload = JSON.parse(JSON.stringify(debtProductFilters ?? {}));
            // remove filterType from payload.filters
            if (payload?.filters?.filterType) delete payload?.filters?.filterType;
            setFormData(flatten(payload));
            return;
        }
        // If source = "Debt Products"
        if (source === 'Debt Products' && !preFilledDataFetched) {
            setLoadingPrefilledData(true);
            getCompanyMetrics({ assesseeOrgId: urlParams.id as string })
                .then((data) => {
                    setLoadingPrefilledData(false);
                    if (Object.keys(data)?.length) {
                        setFormData(flatten(data));
                    }
                    setPreFilledDataFetched(true);
                })
                .catch(() => setLoadingPrefilledData(false));
        }
        // if normal deal flow, e.g. step = BORROWER_PROFILE
        else if (
            formConfig?.[step - 1]?.step === CONFIG_STEP.BORROWER_PROFILE &&
            isEmpty(dealDrawerState.dealId) &&
            isEmpty(dealDrawerState.lenderDealId) &&
            !preFilledDataFetched
        ) {
            const [from, to] = initializeDatepickerDates('Yearly');
            setLoadingPrefilledData(true);
            dispatch(
                getMetricsForDealCreation(
                    {
                        parentOrgId: platformData.investorOrgId,
                        orgId: dealDrawerState.companyId as string,
                        startDate: from,
                        endDate: to,
                    },
                    {
                        onSuccess: (metricsData) => {
                            setLoadingPrefilledData(false);
                            setFormData(flatten(metricsData));
                            setPreFilledDataFetched(true);
                        },
                        onError: () => {
                            setLoadingPrefilledData(false);
                        },
                    },
                ),
            );
        }

        // If step = SELECT_INVESTORS
        else if (formConfig?.[step - 1]?.step === CONFIG_STEP.SELECT_INVESTORS) {
            const createDealPayload: any = unflatten(formData);
            createDealPayload.dealProposerId = platformData?.investorOrgId;
            createDealPayload.dealBeneficiaryId = dealDrawerState?.companyId;
            createDealPayload.dealCreatedBy = loggedInUser?.userName;
            if (createDealPayload.dealTerms?.dealNotes) {
                createDealPayload.dealTerms.dealNotes = [
                    {
                        note: createDealPayload.dealTerms.dealNotes,
                        noteBy: loggedInUser?.userName,
                        noteDate: new Date().toISOString(),
                    },
                ];
            }
            dispatch(
                getPecMatchingData(createDealPayload, {
                    onSuccess: (pecMatchingData) => {
                        setLenderList(pecMatchingData);
                    },
                }),
            );
        }
    }, [
        formConfig,
        step,
        dealDrawerState,
        debtProductFilters,
        source,
        preFilledDataFetched,
        platformData,
        loggedInUser,
        dispatch,
        urlParams,
    ]);

    // Optional for animation class
    useEffect(() => {
        if (animationClass) {
            const timer = setTimeout(() => {
                setAnimationClass('fade-in');
            }, 600);
            return () => clearTimeout(timer);
        }
    }, [animationClass]);

    // -------------------------------------
    // RETURN HOOK DATA
    // -------------------------------------
    return {
        // State
        formData,
        setFormData,
        step,
        disablePrimary,
        disableSecondary,
        animationClass,
        loadingPrefilledData,
        formConfig,
        stepperConfig,
        investorName,
        lenderList,
        loanTypeOptions,
        sectorList,
        dealDrawerState,
        isFromComplete,
        source,

        // Refs
        dealDrawerBodyRef,
        stepContainerRef,

        // Methods
        closeDrawer,
        handlePrimary,
        handleSecondary,
        handleStepperClick,
        setDisablePrimary,
        handleChange,
    };
}
