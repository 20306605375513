import { JAVA_API_STATUS } from '../../utils/axios-interceptor';
import { toast } from 'react-toastify';

import {
    fetchCompanyMetricsService,
    fetchDebtProductsDataService,
    updateCompanyMetricsService,
} from './service_debtProducts';

export async function getCompanyMetrics(payload: { assesseeOrgId: string }) {
    try {
        let response = await fetchCompanyMetricsService(payload);
        const responseData = response?.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                return response?.data?.companyMetrics || {};
            case JAVA_API_STATUS.ERROR:
                toast.error(responseData?.responseMessage || 'Some error occurred');
                return null;
        }
    } catch (err) {
        console.error('Error in fetching company metrics', err);
        return null;
    }
}

export async function updateCompanyMetrics(payload: {
    orgId: string;
    parentOrgId: string;
    companyMetrics: Object;
}) {
    try {
        let response = await updateCompanyMetricsService(payload);
        const responseData = response?.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                return response?.data?.data || {};
            case JAVA_API_STATUS.ERROR:
                toast.error(responseData?.responseMessage || 'Some error occurred');
                return null;
        }
    } catch (err) {
        console.error('Error in updating company metrics', err);
        return null;
    }
}

export async function getDebtProductsData(payload: {
    orgId: string;
    aggregatorId: string;
    filters?: any;
}) {
    try {
        let response = await fetchDebtProductsDataService(payload);
        const responseData = response.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                return response?.data?.responseBody?.data || [];
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error('Error in fetching debt products data');
                return [];
        }
    } catch (err) {
        console.error('Error in fetching debt products data', err);
        return [];
    }
}
