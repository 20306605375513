import React, { useState, useEffect, useRef } from 'react';
import styles from './PendingDocuments.module.scss';
import ThreeDotsIcon from '../../../../assets/Svg/ThreeDotsIcon';
import useDataGapDocument, { DocumentItem } from './useDataGapDocument';
import { CLARIFICATION_TYPE } from '../../../MagicUpload/Utils/MagicUploadConstants';
import { useOutsideClick } from '../../../../utils/constants/hooks';
import DeleteModal from '../../../DeleteModal/DeleteModal';

type PendingDocumentProps = {
    docList: DocumentItem[];
    setDocList: React.Dispatch<React.SetStateAction<DocumentItem[]>>;
    setSelectedDocument: (doc: DocumentItem) => void;
    toggleMiniDrawer: () => void;
    setLastUpdatedAt: React.Dispatch<React.SetStateAction<number>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const PendingDocuments = (props: PendingDocumentProps) => {
    const {
        docList,
        setDocList,
        setSelectedDocument,
        toggleMiniDrawer,
        setLastUpdatedAt,
        setLoading,
    } = props;
    const { toggleDocumentCompletion } = useDataGapDocument(
        'needInfo',
        docList,
        setDocList,
        setLastUpdatedAt,
        setLoading,
    );
    const [openMenuDocId, setOpenMenuDocId] = useState<string | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [showDeleteModal, setShowDeleteModal] = useState<string | null>(null);
    useOutsideClick(containerRef, () => setOpenMenuDocId(null));
    const toggleMenu = (docId: string) => {
        setOpenMenuDocId((prev) => (prev === docId ? null : docId));
    };

    const handleEdit = (doc: DocumentItem) => {
        setSelectedDocument(doc);
        toggleMiniDrawer();
        setOpenMenuDocId(null);
    };

    const insertLinkIntoText = (text: string, link: string | undefined) => {
        // this function will return a jsx element with the link inserted into the text where ${LINK} is found
        if (!link) return text;
        const splitText = text.split('${LINK}');
        return (
            <>
                {splitText[0]}
                <a className={styles.DynamicLink} href={link} target={'_blank'} rel="noreferrer">
                    Link
                </a>
                {splitText[1]}
            </>
        );
    };

    const handleSelect = async (doc) => {
        if (doc.clarificationType === CLARIFICATION_TYPE.SYSTEM_GENERATED) {
            await toggleDocumentCompletion(doc.id, false, doc);
            return;
        }
        await toggleDocumentCompletion(doc.id, false);
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>List of pending data and clarifications</div>
            <ul className={styles.docList}>
                {docList.map((doc) => (
                    <li
                        key={doc.id}
                        className={`${styles.listItem} ${doc.completed ? styles.completed : ''}`}
                    >
                        <div className={styles.radioWrapper} onClick={() => handleSelect(doc)}>
                            <input
                                type="radio"
                                checked={doc.completed}
                                readOnly
                                className={styles.radioButton}
                            />
                        </div>
                        <div className={styles.textWrapper} onClick={() => handleSelect(doc)}>
                            <span className={styles.docName}>
                                <strong>{doc.name}</strong>
                            </span>
                            <span className={styles.docDetails}>
                                {insertLinkIntoText(doc.details, doc.sampleFileLink)}
                            </span>
                        </div>
                        <div className={styles.iconWrapper}>
                            <div
                                className={styles.iconTrigger}
                                onClick={(e) => {
                                    e.stopPropagation(); // prevent triggering toggleDocumentCompletion
                                    if (
                                        doc.clarificationType ===
                                        CLARIFICATION_TYPE.SYSTEM_GENERATED
                                    )
                                        return;
                                    toggleMenu(doc.id);
                                }}
                            >
                                <ThreeDotsIcon
                                    colour={'var(--primary-text-colour)'}
                                    style={
                                        doc.clarificationType ===
                                        CLARIFICATION_TYPE.SYSTEM_GENERATED
                                            ? {
                                                  filter: 'grayscale(1)',
                                                  opacity: 0.5,
                                                  cursor: 'not-allowed',
                                              }
                                            : { cursor: 'pointer' }
                                    }
                                    height="14"
                                    width="14"
                                />
                            </div>
                            {openMenuDocId === doc.id && (
                                <div ref={containerRef} className={styles.dropdownMenu}>
                                    <div
                                        className={styles.dropdownItem}
                                        onClick={() => handleEdit(doc)}
                                    >
                                        Edit
                                    </div>
                                    <div
                                        className={styles.dropdownItemDelete}
                                        onClick={() => {
                                            setShowDeleteModal(doc.id);
                                            setOpenMenuDocId(null);
                                        }}
                                    >
                                        Delete
                                    </div>
                                </div>
                            )}
                        </div>
                    </li>
                ))}
            </ul>
            <DeleteModal
                isOpen={showDeleteModal !== null}
                deleteFunction={async () => {
                    await toggleDocumentCompletion(showDeleteModal as string, true);
                    setShowDeleteModal(null);
                }}
                onClose={() => setShowDeleteModal(null)}
                heading="Delete"
                message="Are you sure you want to delete this pending data from the list?"
            />
        </div>
    );
};

export default PendingDocuments;
