import * as Effects from 'redux-saga/effects';
import * as apiService from '../service';
import * as creditBureauService from './service';
import CONSTANTS, { CHART_NAMES } from './constants';
import { toast } from 'react-toastify';
import { JAVA_API_STATUS, SOMETHING_WRONG } from '../../../utils/axios-interceptor';
import { takeEvery, call, put } from 'redux-saga/effects';
import { ActionWithHandlers } from '../../../types/actions/types';
const takeLatest: any = Effects.takeLatest;

function* fetchChartData(action: any) {
    const graphName = action?.payload?.graphNames?.[0] || '--';

    try {
        let data;
        switch (graphName) {
            // case CHART_NAMES.REPAYMENTS_OVERVIEW:
            //     data = require('./sample_json/repaymentsOverview.json');
            //     break;
            // case CHART_NAMES.REPAYMENTS_OVERVIEW_HISTORY:
            //     data = require('./sample_json/repaymentsOverviewPie.json');
            //     break;
            // case CHART_NAMES.ACCOUNTS_WITH_DELAYS:
            //     data = require('./sample_json/accountsWithDelays.json');
            //     break;
            // case CHART_NAMES.DPDS_BIFURCATION:
            //     data = require('./sample_json/dpdsBifurcation.json');
            //     break;
            // case CHART_NAMES.PRINCIPLE_OUTSTANDING:
            //     data = require('./sample_json/principleOutstanding.json');
            //     break;
            // case CHART_NAMES.NEW_ACCOUNTS_OPENED:
            //     data = require('./sample_json/newAccountsOpened.json');
            //     break;
            // case CHART_NAMES.ALL_ACCOUNTS_BY_LOAN_TYPE:
            //     data = require('./sample_json/allAccountsByLoanType.json');
            //     break;
            // case CHART_NAMES.ALL_ACCOUNTS_BY_LOAN_SIZE:
            //     data = require('./sample_json/allAccountsByLoanSize.json');
            //     break;
            // case CHART_NAMES.ALL_ACCOUNTS_TOP_FIVE_LENDERS:
            //     data = require('./sample_json/accountsTopFiveLenders.json');
            //     break;
            // case CHART_NAMES.ALL_ACCOUNTS_TENURE:
            //     data = require('./sample_json/allAccountsTenure.json');
            //     break;
            // case CHART_NAMES.PROJECTED_REPAYMENTS:
            //     data = require('./sample_json/projectedRepayments.json');
            //     break;
            case CHART_NAMES.ALL_DPDS:
                data = require('./sample_json/allDpds.json');
                break;
        }
        const response = yield call(apiService.getGraphData, action.payload);
        const responseData = response.data?.responseData;
        let graphs = response?.data?.graphs;

        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                yield put({
                    type: CONSTANTS.FETCH_CHART_DATA_SUCCESS,
                    payload: {
                        graphName: Object.keys(graphs || {}),
                        data: graphs?.[graphName],
                    },
                });

                break;
            case JAVA_API_STATUS.ERROR:
                yield put({
                    type: CONSTANTS.FETCH_CHART_DATA_ERROR,
                    payload: {
                        graphNames: [graphName],
                        data: graphs,
                    },
                });
                break;
            default:
                return toast.error(responseData?.responseMessage || SOMETHING_WRONG);
        }
    } catch (err: any) {
        yield put({
            type: CONSTANTS.FETCH_CHART_DATA_ERROR,
            payload: {
                graphNames: [graphName],
            },
        });
        console.error(err?.message || 'Some error occoured');
    }
}

function* fetchCibilOverview(action: ActionWithHandlers) {
    try {
        action?.handlers?.onStart?.();
        const response = yield call(creditBureauService.getCibilOverviewData, action.payload);
        const responseCode = response.data?.responseData?.responseCode;

        switch (responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                yield put({
                    type: CONSTANTS.FETCH_CIBIL_OVERVIEW_SUCCESS,
                    payload: {
                        cibilOverviewData: response?.data?.cibilOverviewData,
                    },
                });
                break;
            case JAVA_API_STATUS.ERROR:
                action?.handlers?.onError?.();
                break;
            default:
                return action?.handlers?.onSuccess?.();
        }
    } catch (err: any) {
        action?.handlers?.onError?.();
        console.error(err?.message || 'Some error occoured');
    }
}

function* fetchChargeDetails(action: ActionWithHandlers) {
    try {
        const response = yield call(apiService.getGraphData, action.payload);
        const responseCode = response.data?.responseData?.responseCode;
        switch (responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                yield put({
                    type: CONSTANTS.FETCH_CHARGE_DETAILS_SUCCESS,
                    payload: response?.data?.graphs?.mcaCharges,
                });
                action?.handlers?.onSuccess?.();
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                action?.handlers?.onError?.();
                return toast.error(
                    response?.data?.responseData?.responseMessage || SOMETHING_WRONG,
                );
        }
    } catch (err: any) {
        action?.handlers?.onError?.();
        console.error(err?.message || 'Some error occoured');
    }
}

function* fetchDirectorsOverview(action: ActionWithHandlers) {
    yield handleDirectorDataFetch({
        apiCall: creditBureauService.getDirectorsOverviewData,
        payload: action.payload,
        successActionType: CONSTANTS.FETCH_DIRECTOR_OVERVIEW_SUCCESS,
        loadingActionType: CONSTANTS.SET_LOADING_DIRECTOR_OVERVIEW,
    });
}

function* fetchDirectorBureauCibil(action: ActionWithHandlers) {
    yield handleDirectorDataFetch({
        apiCall: creditBureauService.getDirectorsBureauCibil,
        payload: action.payload,
        successActionType: CONSTANTS.FETCH_DIRECTOR_OVERVIEW_SUCCESS,
        loadingActionType: CONSTANTS.SET_LOADING_DIRECTOR_OVERVIEW,
    });
}

function* handleDirectorDataFetch({
    apiCall,
    payload,
    successActionType,
    loadingActionType,
}: {
    apiCall: (payload: any) => Promise<any>;
    payload: any;
    successActionType: string;
    loadingActionType: string;
}) {
    try {
        // Set loading to true
        yield put({
            type: loadingActionType,
            payload: true,
        });

        // Call the API
        const response = yield call(apiCall, payload);
        const responseCode = response.data?.responseData?.responseCode;

        switch (responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                yield put({
                    type: successActionType,
                    payload: {
                        listDirectorsOverview: response?.data?.responseBody?.combinedDirectors,
                    },
                });
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                yield put({
                    type: loadingActionType,
                    payload: false,
                });
        }
    } catch (err: any) {
        // Handle error and set loading to false
        yield put({
            type: loadingActionType,
            payload: false,
        });
        console.error(err?.message || 'Some error occurred');
    }
}

function* fetchCreditBureauSummaryTableData(action: ActionWithHandlers) {
    try {
        const response = yield call(
            creditBureauService.getCreditBureauSummaryTableService,
            action.payload,
        );
        const responseCode = response.data?.responseData?.responseCode;
        switch (responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                action?.handlers?.onSuccess?.(response.data);
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                action?.handlers?.onError?.();
                return toast.error(
                    response?.data?.responseData?.responseMessage || SOMETHING_WRONG,
                );
        }
    } catch (err: any) {
        action?.handlers?.onError?.();
        console.error(err?.message || 'Some error occoured');
    }
}

export default function* actionWatcher() {
    yield takeLatest(CONSTANTS.FETCH_DIRECTORS_OVERVIEW, fetchDirectorsOverview);
    yield takeLatest(CONSTANTS.FETCH_DIRECTOR_BUREAU_CIBIL, fetchDirectorBureauCibil);
    yield takeEvery(CONSTANTS.FETCH_CHART_DATA, fetchChartData);
    yield takeLatest(CONSTANTS.FETCH_CIBIL_OVERVIEW, fetchCibilOverview);
    yield takeLatest(CONSTANTS.FETCH_CHARGE_DETAILS, fetchChargeDetails);
    yield takeLatest(
        CONSTANTS.FETCH_CREDIT_BUREAU_SUMMARY_TABLE,
        fetchCreditBureauSummaryTableData,
    );
}
