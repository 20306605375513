import styles from '../../MagicUpload.module.scss';
import AnimatedFolder from '../AnimatedFolder/AnimatedFolder';
import useUploadEngine from './useUploadEngine';
import React, { useEffect } from 'react';
import useMnemosyneUpload from './useMnemosyneUpload';
import { useAppSelector } from '../../../../app/hooks';
import { useSearchParams } from 'react-router-dom';
import { MagicUploadFileData } from '../../useFetchDataForMU';

interface MagicUploadCenterProps {
    tabState: string;
    fileData: MagicUploadFileData[];
    setFileData: React.Dispatch<React.SetStateAction<MagicUploadFileData[]>>;
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
    orientation: 'vertical' | 'horizontal';
    folderDimension?: { width: string; height: string };
    componentStyle?: React.CSSProperties;
}

const MagicUploadCenter = (props: MagicUploadCenterProps) => {
    const [params] = useSearchParams();
    const config = useAppSelector((state) => state.investor.config);
    const companyConfig = useAppSelector((state) => state.company.config);
    const [categoryId, setCategoryId] = React.useState('');
    useEffect(() => {
        const isCompany = params.get('isCompany') === 'true';
        let selectedConfig = isCompany ? companyConfig : config;
        if (isCompany && !companyConfig) selectedConfig = config;

        if (!selectedConfig?.dataConfig?.staging?.categoryId) {
            console.error('Missing required configuration for categoryId');
            return;
        }
        setCategoryId(selectedConfig.dataConfig.staging.categoryId);
    }, [config, companyConfig]);

    const { tabState, fileData, setFileData, setLoading, folderDimension } = props;
    // const {
    //     handleFileChange,
    //     handleDragOver,
    //     handleDragLeave,
    //     handleDrop,
    //     handleClick,
    // } = useUploadEngine(tabState, fileData, setFileData);
    const { handleFileChange, handleDragOver, handleDrop, handleDragLeave, handleClick } =
        useMnemosyneUpload(tabState, fileData, setFileData, categoryId, setLoading);

    return (
        <div
            style={props.componentStyle}
            data-testid="file-input"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onDragLeave={handleDragLeave}
            onClick={handleClick}
            className={
                props.orientation === 'vertical'
                    ? `${styles.drawerBody} ${styles.folderContainer}`
                    : `${styles.drawerBody} ${styles.folderContainerHorizontal}`
            }
        >
            <AnimatedFolder folderDimensions={folderDimension} />
            <div className={styles.Text}>Drag and drop your files here</div>
            <input
                type="file"
                id="fileInput"
                style={{ display: 'none' }}
                onChange={(e) => handleFileChange(e.target.files)}
                multiple
            />
        </div>
    );
};

export default MagicUploadCenter;
