import * as Effects from 'redux-saga/effects';
import * as apiService from './service';
import { CONSTANTS } from './constants';
import { CONSTANTS as USER_CONSTANTS } from '../user/userV2/constants';
import { JAVA_API_STATUS, SOMETHING_WRONG } from '../../utils/axios-interceptor';
import { toast } from 'react-toastify';

const call: any = Effects.call;
const put = Effects.put;
const takeLatest = Effects.takeLatest;

function* fetchCompanyConfig(action) {
    try {
        yield put({
            type: CONSTANTS.SET_IS_FETCHING_CONFIG,
            payload: true,
        });
        const response = yield call(apiService.getCompanyConfigData, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                yield put({
                    type: CONSTANTS.FETCH_UPLOAD_JOURNEY_DATA,
                    payload: action.payload,
                });
                yield put({
                    type: CONSTANTS.FETCH_COMPANY_CONFIG_SUCCESS,
                    payload: response.data,
                });
                yield put({
                    type: USER_CONSTANTS.UPDATE_USER_EMAIL_VERIFIED,
                    payload: { userEmailVerified: response.data.userEmailVerified },
                });
                // yield put({
                //     type: USER_CONSTANTS.SET_USER_DATA_FROM_COMPANY_CONFIG,
                //     payload: {
                //         user: {
                //             assesseeOrgId: response2?.assesseeOrgId,
                //             investorOrgId: response2?.investorOrgId,
                //             userName: response2?.userName,
                //         },
                //     },
                // });
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                yield put({
                    type: CONSTANTS.SET_IS_FETCHING_CONFIG,
                    payload: false,
                });
                return;
        }
    } catch (err: any) {
        yield put({
            type: CONSTANTS.SET_IS_FETCHING_CONFIG,
            payload: false,
        });
        console.error(err?.message || 'Some error occoured');
    }
}

function* connectGST(action) {
    try {
        const response = yield call(apiService.connectGSTService, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                yield put({
                    type: CONSTANTS.CONNECT_GST_SUCCESS,
                    payload: response.data,
                });
                action.payload?.onSuccess?.();
                // yield put({
                //     type: CONSTANTS.FETCH_COMPANY_CONFIG,
                //     payload: action.payload,
                // });
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                return;
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

function* fetchUploadJourneyData(action) {
    try {
        yield put({
            type: CONSTANTS.SET_IS_FETCHING_UPLOAD_JOURNEY,
            payload: true,
        });
        const response = yield call(apiService.fetchUploadJourneyDataService, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                action?.handlers?.onSuccess?.();
                yield put({
                    type: CONSTANTS.FETCH_UPLOAD_JOURNEY_DATA_SUCCESS,
                    payload: response.data?.onboardingData || {},
                });
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                action?.handlers?.onError?.();
                yield put({
                    type: CONSTANTS.SET_IS_FETCHING_UPLOAD_JOURNEY,
                    payload: false,
                });
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                return;
        }
    } catch (err: any) {
        action?.handlers?.onError?.();
        yield put({
            type: CONSTANTS.SET_IS_FETCHING_UPLOAD_JOURNEY,
            payload: false,
        });
        console.error(err?.message || 'Some error occoured');
    }
}

function* SendCibilConsentLink(action) {
    try {
        const response = yield call(apiService.sendCibilConsentLink, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                action.payload?.onSuccess?.(response.data?.cibilSoftPullData.managementList);
                break;
            case JAVA_API_STATUS.ERROR:
                action.payload?.onError?.(responseData?.responseMessage || SOMETHING_WRONG);
                break;
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                return;
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

function* CaptureCibilConsent(action) {
    try {
        const response = yield call(apiService.captureCibilConset, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                action.payload?.onSuccess?.();
                break;
            case JAVA_API_STATUS.ERROR:
                action.payload?.onError?.(responseData?.responseMessage || SOMETHING_WRONG);
                break;
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                return;
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

function* updateProvisionalStatements(action) {
    try {
        const response = yield call(apiService.updateProvisionalStatements, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                action.payload?.onSuccess?.();
                break;
            case JAVA_API_STATUS.ERROR:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                break;
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                return;
        }
        yield put({
            type: CONSTANTS.UPDATE_UPLOADING_STATUS,
            payload: false,
        });
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
        yield put({
            type: CONSTANTS.UPDATE_UPLOADING_STATUS,
            payload: false,
        });
    }
}

function* ingestProvisionalStatements(action) {
    try {
        const response = yield call(apiService.ingestProvisionalStatements, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                action.payload?.onSuccess?.(response?.data?.data);
                break;
            case JAVA_API_STATUS.ERROR:
                action.payload?.onError?.(responseData?.responseMessage || SOMETHING_WRONG);
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                break;
            default:
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                return;
        }
        yield put({
            type: CONSTANTS.UPDATE_UPLOADING_STATUS,
            payload: false,
        });
    } catch (err: any) {
        action.payload?.onError?.(false);
        console.error(err?.message || 'Some error occoured');
        yield put({
            type: CONSTANTS.UPDATE_UPLOADING_STATUS,
            payload: false,
        });
    }
}

function* UpdateDebtScheduleDocs(action) {
    try {
        const response = yield call(apiService.updateDebtScheduleDocs, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData && responseData.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                action.payload?.onSuccess?.();
                break;
            case JAVA_API_STATUS.ERROR:
                action.payload?.onError?.();
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                break;
            default:
                action.payload?.onError?.();
                toast.error(responseData?.responseMessage || SOMETHING_WRONG);
                return;
        }
    } catch (err: any) {
        action.payload?.onError?.();
        console.error(err?.message || 'Some error occoured');
    }
}

function* fetchMonthlyViewData(action) {
    try {
        const response = yield call(apiService.fetchMonthlyViewData, action.payload.assesseeOrgId);

        switch (response.data.statusCode) {
            case 200:
                yield put({
                    type: CONSTANTS.FETCH_MONTHLY_VIEW_DATA_SUCCESS,
                    payload: response.data,
                });
                action?.payload?.onSuccess(response.data.data);
                break;
            default:
                toast.error(response?.data?.message || SOMETHING_WRONG);
                return;
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

function* fetchMetadataForInputModal(action) {
    try {
        const response = yield call(apiService.fetchMetadataForInputModal, action.payload);
        switch (response.data.statusCode) {
            case 200:
                action.payload?.onSuccess?.(response.data);
                break;
            default:
                action.payload?.onError?.();
                toast.error(response.data.message || SOMETHING_WRONG);
                return;
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

function* fetchTableDataForMIS(action) {
    try {
        const response = yield call(apiService.fetchTableDataForMIS, action.payload);
        switch (response.data.statusCode) {
            case 200:
                action.payload?.onSuccess?.(response.data);
                break;
            default:
                action.payload?.onError?.();
                toast.error(response.data.message || SOMETHING_WRONG);
                return;
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

function* fetchMisTableOptions(action) {
    try {
        const response = yield call(apiService.fetchMISTableOptions, action?.payload);
        switch (response.data.statusCode) {
            case 200:
                action.payload?.onSuccess?.(response.data.data);
                break;
            default:
                action.payload?.onError?.();
                toast.error(response.data.message || SOMETHING_WRONG);
                return;
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

function* updateUploadingState(action) {
    yield put({
        type: CONSTANTS.UPDATE_UPLOADING_STATUS,
        payload: action.payload,
    });
}

function* updateDataMapping(action) {
    try {
        const response = yield call(apiService.updateDataMetrics, action.payload);
        switch (response.data.statusCode) {
            case 200:
                action.payload?.onSuccess?.();
                break;
            default:
                toast.error(response?.data?.message || SOMETHING_WRONG);
                return;
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}
function* getVisualizationTableData(action) {
    try {
        const response = yield call(apiService.fetchVisualisationTableData, action.payload);
        switch (response.data.statusCode) {
            case 200:
                action.payload?.onSuccess?.(response.data);
                break;
            default:
                toast.error(response?.data?.message || SOMETHING_WRONG);
                return;
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

function* getRawDataForVisualization(action) {
    try {
        const response = yield call(apiService.fetchRawDataForVisualisation, action.payload);
        switch (response.data.statusCode) {
            case 200:
                action.payload?.onSuccess?.(response.data);
                break;
            default:
                toast.error(response?.data?.message || SOMETHING_WRONG);
                return;
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

function* FetchAuditedTableData(action) {
    try {
        const response = yield call(apiService.fetchAuditedTableData, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                action.handlers?.onSuccess?.(
                    response.data?.data?.data || response.data?.data,
                    response.data?.data?.reportStatus,
                );
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                action.handlers?.onError();
                toast.error(response?.data?.message || SOMETHING_WRONG);
                return;
        }
    } catch (err: any) {
        action.handlers?.onError();
        console.error(err?.message || 'Some error occoured');
    }
}

function* updateAuditedTableData(action) {
    try {
        const response = yield call(apiService.updateAuditedTableData, action.payload);
        switch (response.data.statusCode) {
            case 200:
                action.payload?.onSuccess?.(response.data);
                break;
            default:
                action.payload?.onError();
                toast.error(response?.data?.message || SOMETHING_WRONG);
                return;
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

function* fetchAICAOverviewData(action) {
    try {
        const response = yield call(apiService.fetchAicaOverviewData, action.payload);
        switch (response.data.responseData.responseCode) {
            case 20:
                action?.handlers?.onSuccess?.(response.data.data);
                break;
            default:
                action?.handlers?.onError?.(
                    response.data.responseData.responseMessage || SOMETHING_WRONG,
                );
                // toast.error(response.data.message || SOMETHING_WRONG);
                return;
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

function* sendAICAOverviewFeedback(action) {
    try {
        const response = yield call(apiService.sendAICAOverviewFeedback, action.payload);
        const responseData = response?.data?.responseData;
        switch (responseData?.responseCode) {
            case JAVA_API_STATUS.SUCCESS:
                action.handlers?.onSuccess?.();
                break;
            case JAVA_API_STATUS.ERROR:
            default:
                toast.error(response?.data?.message || SOMETHING_WRONG);
                return;
        }
    } catch (err: any) {
        console.error(err?.message || 'Some error occoured');
    }
}

export default function* actionWatcher() {
    yield takeLatest(CONSTANTS.FETCH_COMPANY_CONFIG, fetchCompanyConfig);
    yield takeLatest(CONSTANTS.CONNECT_GST, connectGST);
    yield takeLatest(CONSTANTS.FETCH_UPLOAD_JOURNEY_DATA, fetchUploadJourneyData);
    yield takeLatest(CONSTANTS.SEND_CIBIL_CONSENT_LINK, SendCibilConsentLink);
    yield takeLatest(CONSTANTS.CAPTURE_CIBIL_CONSENT, CaptureCibilConsent);
    yield takeLatest(CONSTANTS.UPDATE_PROVISIONAL_STATEMENTS, updateProvisionalStatements);
    yield takeLatest(CONSTANTS.UPDATE_DEBT_SCHEDULE, UpdateDebtScheduleDocs);
    yield takeLatest(CONSTANTS.FETCH_MONTHLY_VIEW_DATA, fetchMonthlyViewData);
    yield takeLatest(CONSTANTS.FETCH_METADATA_FOR_INPUT_MODAL, fetchMetadataForInputModal);
    yield takeLatest(CONSTANTS.UPDATING_UPLOADING_STATUS, updateUploadingState);
    yield takeLatest(CONSTANTS.FETCH_TABLE_DATA_FOR_MIS, fetchTableDataForMIS);
    yield takeLatest(CONSTANTS.FETCH_MIS_TABLE_OPTIONS, fetchMisTableOptions);
    yield takeLatest(CONSTANTS.UPDATE_DATA_METRICS, updateDataMapping);
    yield takeLatest(CONSTANTS.INGEST_PROVISIONAL_STATEMENTS, ingestProvisionalStatements);
    yield takeLatest(CONSTANTS.FETCH_VISUALISATION_TABLE_DATA, getVisualizationTableData);
    yield takeLatest(CONSTANTS.FETCH_RAW_DATA_FOR_VISUALISATION, getRawDataForVisualization);
    yield takeLatest(CONSTANTS.FETCH_AUDITED_TABLE_DATA, FetchAuditedTableData);
    yield takeLatest(CONSTANTS.UPDATE_AUDITED_TABLE_DATA, updateAuditedTableData);
    yield takeLatest(CONSTANTS.FETCH_AICA_OVERVIEW_DATA, fetchAICAOverviewData);
    yield takeLatest(CONSTANTS.SEND_AICA_OVERVIEW_FEEDBACK, sendAICAOverviewFeedback);
}
