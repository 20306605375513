import React from 'react';
import styles from './TextEditor.module.scss';
import { ShimmerThumbnail } from 'react-shimmer-effects';

function TextEditorShimmerLoader() {
    return (
        <div className={styles.EditorWrapperEmpty}>
            <div className={styles.Upper}>
                <ShimmerThumbnail height={10} rounded className={styles.ShimmerThumbnail} />
            </div>
            <div className={styles.Grid}>
                <ShimmerThumbnail height={20} rounded className={styles.ShimmerThumbnail} />
                <ShimmerThumbnail height={20} rounded className={styles.ShimmerThumbnail} />
                <ShimmerThumbnail height={20} rounded className={styles.ShimmerThumbnail} />
                <ShimmerThumbnail height={20} rounded className={styles.ShimmerThumbnail} />
                <ShimmerThumbnail height={20} rounded className={styles.ShimmerThumbnail} />
                <ShimmerThumbnail height={20} rounded className={styles.ShimmerThumbnail} />
                <ShimmerThumbnail height={20} rounded className={styles.ShimmerThumbnail} />
                <ShimmerThumbnail height={20} rounded className={styles.ShimmerThumbnail} />
                <ShimmerThumbnail height={20} rounded className={styles.ShimmerThumbnail} />
                <ShimmerThumbnail height={20} rounded className={styles.ShimmerThumbnail} />
                <ShimmerThumbnail height={20} rounded className={styles.ShimmerThumbnail} />
                <ShimmerThumbnail height={200} rounded className={styles.ShimmerThumbnail} />
            </div>
        </div>
    );
}

export default TextEditorShimmerLoader;
