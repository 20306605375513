import styles from './HeaderButtonContainer.module.scss';
import { HEADER_BUTTONS } from '../../TabConstants';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import isNil from 'lodash/isNil';
import ThreeDotMenu from '../../../../../../common/ThreeDotMenu/ThreeDotMenu';
import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks';
import {
    ACCESS_TIERS,
    ACCESS_TYPES,
    PROFILE_GENERATION_STATUS,
    RESTRICTED_ROLES,
} from '../../../../../../store/investor/constants';
import {
    getPlatformUserId,
    isEmpty,
    isRestrictedUser,
    openCompanySummonTab,
} from '../../../../../../utils/utils';
import {
    setDealDrawer,
    setOnePagerData,
    setShowInviteUserModal,
    setShowUnlockAicaConfirmation,
} from '../../../../../../store/investor/action';
import { USER_TYPE } from '../../../../../../utils/constants/user';
import {
    fetchInternalSummonAicaData,
    InternalSummonAicaDataPayload,
    InternalSummonUserType,
    updateAssesseeOrgId,
} from '../../../../../../store/user/actions';
import { updateQueryParams } from '../../../../../../utils/searchParamUtils';
import { useParams, useSearchParams } from 'react-router-dom';
import SideDrawer from '../../../../../../common/_custom/SideDrawer/SideDrawer';
import UploadData from '../../../../../Company/UploadData/UploadData';
import AddAssessee from '../../../../../Company/AddAssessee/AddAssessee';
import { AICA_CREATE_DEAL } from '../../../../../../utils/constants/mixpanelEvents/investorEvents';
import { Mixpanel } from '../../../../../../utils/mixpanel';
import { getInvestmentReport } from '../../../../../../store/investor/apiHelpers';
import { toast } from 'react-toastify';
import { GENERATION_STATUS } from '../../../../../../common/ManageDealDrawer/InvestmentReport/InvestmentReport';
import { FILE_SHARE_STATUS } from '../../../../../../common/ManageDealDrawer/DealDrawerConstants';
import { downloadFile } from '../../../../../Company/UploadJourney/components/UploadCard/utils/api/helpers';
import { moveCompanyToNewTier } from '../../../../../../store/investor/investorApiHelper';

type HeaderButtonContainerProps = {
    selectedCompany: any;
    refreshReportStatus: string;
    downloadPDFStatus: string;
    exportData: () => void;
    unlockAicaReport?: () => void;
};

const HeaderButtonContainer = (props: HeaderButtonContainerProps) => {
    const dispatch = useAppDispatch();

    const { selectedCompany, refreshReportStatus, downloadPDFStatus, unlockAicaReport } = props;
    const [mainButtonHovered, setMainButtonHovered] = useState('');
    const investorData = useAppSelector((state) => state.investor);
    const dashboardConfig = useAppSelector((state) => state.dashboard.dashboardReducer.config);
    const permissions = investorData.permissions;
    const isProfileLocked = selectedCompany?.profileGenerationStatus === 'LOCKED';
    const isScanLockedForUser = permissions?.[ACCESS_TIERS.SCAN] === ACCESS_TYPES.LOCKED;
    const user = useAppSelector((state) => state.user);
    const platformData = useAppSelector((state) => state.user.domainMetadata);
    const isUserRestricted = isRestrictedUser(user?.userData?.roles?.[0], RESTRICTED_ROLES);
    const isDemoProfile = user.domainMetadata?.demoOrgId === selectedCompany?.assesseeOrgId;
    const isLender = user.userData?.accountType === USER_TYPE.LENDER;
    const isPartner = user.userData?.accountType === USER_TYPE.PARTNER;

    const onePagerState = useAppSelector((state) => state.investor.onePager);

    const [queryParams, setQueryParams] = useSearchParams();
    const params = useParams();
    const showUploader = queryParams.get('upload') === 'true';
    const isSummonUser = !isNil(sessionStorage.getItem('auth_token'));

    const showInviteUser = useAppSelector((state) => state.investor.showInviteUser);

    const openCreateDealDrawer = () => {
        const mixpanelData = {
            Company_Org_Id: selectedCompany?.assesseeOrgId,
            PartnetAccessType: user?.userData?.accountType,
            Dsa_Id: user?.userData?.investorOrgId,
            websiteUrl: window.location.href,
        };
        Mixpanel.track(AICA_CREATE_DEAL, mixpanelData);
        dispatch(
            setDealDrawer({
                open: true,
                companyId: selectedCompany?.assesseeOrgId,
                companyName: selectedCompany?.orgName,
                profileGenerationStatus: selectedCompany?.profileGenerationStatus,
                drawerSource: 'TOP_BAR',
            }),
        );
    };

    const summon = () => {
        const platformUserId = getPlatformUserId();
        const payload: InternalSummonAicaDataPayload = {
            parentOrgId: platformData?.investorOrgId as string,
            parentEmailId: user.userData.investorPrimaryEmail,
            orgId: selectedCompany?.assesseeOrgId,
            userType: InternalSummonUserType.ASSESSEE,
            requesterUserId: platformUserId,
        };
        dispatch(
            fetchInternalSummonAicaData(payload, {
                onSuccess: (userData) => {
                    openCompanySummonTab(userData);
                },
            }),
        );
    };

    const addCompanyToPortfolio = (
        orgId: string,
        orgName: string,
        profileGenerationStatus: string,
    ): void => {
        // If the profile is locked, ask the user to unlock it first
        if (profileGenerationStatus === PROFILE_GENERATION_STATUS.LOCKED) {
            dispatch(
                setShowUnlockAicaConfirmation({ showUnlockAicaConfirmation: true, orgId, orgName }),
            );
            return;
        }

        // If the profile is unlocked, add the company to the portfolio
        moveCompanyToNewTier(
            {
                assesseeOrgId: orgId,
                inviteType: ACCESS_TIERS.TRACK,
            },
            {
                onSuccess: () => {
                    toast.success(`Added to Portfolio: ${orgName}`);
                    queryParams.append('portfolioSuccess', 'true');
                    updateQueryParams(queryParams, setQueryParams, {}, location);
                },
            },
        );
    };

    const ACTION_MENU_MAP = {
        VIEW_ONE_PAGER: {
            name: 'View One Pager',
            onClick: () => {
                dispatch(
                    setOnePagerData({
                        open: true,
                        assesseeOrgId: params.id as string,
                        source: 'REPORT_TOP_BAR',
                    }),
                );
            },
        },
        UNLOCK_REPORT: {
            name: 'Unlock AICA Report',
            onClick: props.unlockAicaReport,
        },
        CREATE_DEAL: {
            name: 'Create Deal',
            onClick: openCreateDealDrawer,
        },
        INVITE_USER: {
            name: 'Invite User',
            onClick: () => {
                // setShowInviteUser(true);
                dispatch(setShowInviteUserModal(true));
            },
        },
        SUMMON: {
            name: 'Upload Data',
            onClick: summon,
        },
        DOWNLOAD_RAW: {
            name: 'Export Data',
            onClick: props.exportData,
        },
        DOWNLOAD_IR: {
            name: 'Export Investment Report',
            onClick: () => {
                toast.warn(
                    'Generating Investment Report.\n' +
                        "It will automatically download within 2 mins, please don't close this tab.",
                );
                fetchInvestmentReport(selectedCompany, pollingCount);
            },
        },
        ADD_TO_PORTFOLIO: {
            name: 'Add to Portfolio',
            onClick: () =>
                addCompanyToPortfolio(
                    selectedCompany?.assesseeOrgId,
                    selectedCompany?.orgName,
                    selectedCompany?.profileGenerationStatus,
                ),
        },
    };
    const NotToShowLender = ['Create Deal', 'Add to Portfolio', 'Invite User'];

    const pollingCount = useRef(0);
    const apiPollingRef: any = useRef(null);

    useEffect(() => {
        return () => {
            if (apiPollingRef?.current) clearInterval(apiPollingRef.current);
        };
    }, []);

    const fetchInvestmentReport = (rowData, pollingCount) => {
        pollingCount.current += 1;
        getInvestmentReport(
            { assesseeOrgId: params.id },
            {
                onSuccess: (data) => {
                    const reportGenStatus = data?.reportGenerationStatus;
                    if (pollingCount.current >= 5) {
                        clearInterval(apiPollingRef.current);
                        toast.error('Error in generating Investment Report');
                    } else {
                        if (reportGenStatus === GENERATION_STATUS.IN_PROGRESS) {
                            if (apiPollingRef.current) return;
                            apiPollingRef.current = setInterval(() => {
                                fetchInvestmentReport(pollingCount, rowData);
                            }, 10000);
                        } else if (reportGenStatus === GENERATION_STATUS.SUCCESS) {
                            if (apiPollingRef.current) clearInterval(apiPollingRef.current);
                            onInvestmentReportReceived({
                                fileName: data?.fileName,
                                docId: data?.fileUrl,
                                action: FILE_SHARE_STATUS.SEND_TO_INVESTOR,
                            });
                        }
                    }
                },
                onError: () => {
                    toast.error('Error in generating Investment Report');
                },
            },
        );
    };

    const onInvestmentReportReceived = (fileData) => {
        downloadGeneratedReport(fileData?.docId);
    };

    const downloadGeneratedReport = (documentId = '') => {
        downloadFile(
            Number(documentId),
            () => {
                toast.success('Investment Report downloaded successfully');
            },
            `Investment_Report`,
            () => {
                toast.error('Error in downloading Investment Report');
            },
        );
    };

    const onUnlockAica = () => {
        unlockAicaReport?.();
    };
    const hideUploader = () => {
        const params = new URLSearchParams(queryParams);
        params.delete('upload');
        params.delete('id');
        setQueryParams(params.toString());
    };
    const uploadData = (assesseeOrgId) => {
        dispatch(updateAssesseeOrgId({ assesseeOrgId: assesseeOrgId }));
        updateQueryParams(
            queryParams,
            setQueryParams,
            { upload: 'true', id: assesseeOrgId },
            location,
        );
    };
    const secondaryCtaList = () => {
        if (isUserRestricted) return [];
        let ctaList: { name: string; onClick: Function }[] = [];
        if (selectedCompany?.companyAccessType !== ACCESS_TIERS.WATCH) {
            ctaList = dashboardConfig?.actionCta?.secondCTATrigger
                ?.filter((trigger) => !(isSummonUser && trigger === 'SUMMON'))
                ?.reduce((p, c, i) => {
                    if (ACTION_MENU_MAP[c]) {
                        p.push(ACTION_MENU_MAP[c]);
                    }
                    return p;
                }, ctaList) ?? [
                ...(isProfileLocked && !isPartner
                    ? [{ name: 'Unlock AICA Report', onClick: onUnlockAica }]
                    : []),

                ...(!isSummonUser ? [{ name: 'Upload Data', onClick: summon }] : []),
                ...(!isDemoProfile &&
                !isLender &&
                permissions?.TRACK !== ACCESS_TYPES.LOCKED &&
                !isPartner
                    ? [
                          {
                              name: 'Create Deal',
                              onClick: openCreateDealDrawer,
                          },
                      ]
                    : []),
                {
                    name: 'Export Data',
                    onClick: props?.exportData,
                },
                ...(!isLender
                    ? [
                          {
                              name: 'Invite User',
                              onClick: () => {
                                  dispatch(setShowInviteUserModal(true));
                              },
                          },
                      ]
                    : []),
                ...(isPartner || isLender
                    ? []
                    : [
                          {
                              name: 'Add to Portfolio',
                              onClick: () =>
                                  addCompanyToPortfolio(
                                      selectedCompany?.assesseeOrgId,
                                      selectedCompany?.orgName,
                                      selectedCompany?.profileGenerationStatus,
                                  ),
                          },
                      ]),

                {
                    name: 'Export Investment Report',
                    onClick: () => {
                        toast.warn(
                            'Generating Investment Report.\n' +
                                "It will automatically download within 2 mins, please don't close this tab.",
                        );
                        fetchInvestmentReport(selectedCompany, pollingCount);
                    },
                },
            ];
            if (isLender) {
                ctaList = ctaList.filter((item) => !NotToShowLender.includes(item.name));
            }
        }

        return ctaList;
    };

    return (
        <div>
            <div className={styles.MainButtonContainer}>
                {HEADER_BUTTONS.mainButtons.map((button, index) => {
                    if (isEmpty(HEADER_BUTTONS.mainButtons)) return <></>;
                    else
                        return (
                            <div
                                key={index}
                                className={clsx({
                                    [styles.MainButton]: true,
                                    [styles.Purple]: button.variant === 'purple',
                                })}
                                onMouseEnter={() => setMainButtonHovered(button.id)}
                                onMouseLeave={() => setMainButtonHovered('')}
                            >
                                <img
                                    src={
                                        mainButtonHovered === button.id
                                            ? button.hoveredIcon
                                            : button.icon
                                    }
                                    alt="icon"
                                />
                                <span>{button.title}</span>
                            </div>
                        );
                })}
                <ThreeDotMenu menuItems={secondaryCtaList()} />
            </div>
            {showUploader && (
                <SideDrawer
                    onClose={() => {
                        hideUploader();
                    }}
                    width="50%"
                >
                    <UploadData
                        userType={USER_TYPE.INVESTOR}
                        isInvestor
                        closeDrawer={() => {
                            hideUploader();
                        }}
                    />
                </SideDrawer>
            )}
            {showInviteUser && (
                <AddAssessee
                    assesseeOrgId={selectedCompany?.assesseeOrgId}
                    modalOpen={showInviteUser}
                    closeModal={() => {
                        dispatch(setShowInviteUserModal(false));
                    }}
                />
            )}

            {/*{onePagerState.open && onePagerState.source === 'REPORT_TOP_BAR' && (*/}
            {/*    <OnePager*/}
            {/*        source={'REPORT_TOP_BAR'}*/}
            {/*        editorExistingData={editorExistingData as Object}*/}
            {/*        metadataUpdate={onMetadataUpdate}*/}
            {/*        categoryId={categoryId}*/}
            {/*        flowId={flowId}*/}
            {/*    />*/}
            {/*)}*/}
        </div>
    );
};

export default HeaderButtonContainer;
