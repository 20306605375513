import Form from '../../../../../common/_custom/Form/Form';
import { Button, Input } from '../../../../../common/_custom';
import styles from '../../InvestorSignup.module.scss';
import { InvestorSignupData } from '../../InvestorSignup';
import { orgTypeOptions } from '../../../../../store/investor/utilities';
import { DropdownOption } from '../../../../../common/_custom/Dropdown/dropdownTypes';
import Dropdown from '../../../../../common/_custom/Dropdown';
import CountryCodesDropdown from '../../../../Company/CompanyLogin/CountryCodesDropdown/CountryCodesDropdown';
import { useNavigate } from 'react-router-dom';
import { INVESTOR_ROUTES } from '../../../../../utils/constants/routesConst';
import { useState } from 'react';
import { investorFormValidations } from '../../../../../utils/constants/formValidations';
import {
    EMAIL_ERROR_MESSAGE,
    FIRST_NAME_ERROR_MESSAGE,
    LAST_NAME_ERROR_MESSAGE,
    MOBILE_NUMBER_ERROR_MESSAGE,
    ORGANISATION_NAME_ERROR_MESSAGE,
    ORGANISATION_TYPE_ERROR_MESSAGE,
    PASSWORD_ERROR_MESSAGE,
} from '../../../../../utils/constants/investorSignup';
import { useAppSelector } from '../../../../../app/hooks';

type InvestorSignupFormProps = {
    formData: InvestorSignupData;
    handleFormChange: Function;
    handleSubmitClick: Function;
};

function InvestorSignupForm(porps: InvestorSignupFormProps) {
    const navigate = useNavigate();
    const isSigningUp = useAppSelector((state) => state.user.creatingAccount);
    const { formData, handleFormChange, handleSubmitClick } = porps;
    const disabled = !(
        formData.firstName &&
        formData.organisationName &&
        formData.organisationType.id &&
        formData.emailId &&
        formData.mobileNumber &&
        formData.password
    );
    const [errors, setErrors] = useState({
        firstName: false,
        // lastName: false,
        organisationName: false,
        organisationType: false,
        emailId: false,
        password: false,
        mobileNumber: false,
    });
    const onSubmit = () => {
        setErrors({
            firstName: !investorFormValidations.isFirstNameValid(formData.firstName),
            // lastName: !investorFormValidations.isLastNameValid(formData.lastName),
            organisationName: !investorFormValidations.isOrgNameValid(formData.organisationName),
            organisationType: !investorFormValidations.isOrgTypeValid(
                formData.organisationType.label,
            ),
            emailId: !investorFormValidations.isEmailValid(formData.emailId),
            password: !investorFormValidations.isPasswordValid(formData.password),
            mobileNumber: !investorFormValidations.isPhoneValid(
                formData.mobileNumber,
                formData.countryCode,
            ),
        });
        if (investorFormValidations.allValid(formData)) {
            handleSubmitClick();
        }
    };

    return (
        <div className={styles.InvestorLoginFormContainer}>
            <div className={styles.Title}>Sign Up</div>
            <Form>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: '1rem',
                    }}
                >
                    <Input
                        placeholder="First Name"
                        value={formData.firstName}
                        onChange={(val: string) => handleFormChange(val, 'firstName')}
                        errorMessage={FIRST_NAME_ERROR_MESSAGE}
                        isValid={!errors.firstName}
                    />
                    <Input
                        placeholder="Last Name"
                        value={formData.lastName}
                        onChange={(val: string) => handleFormChange(val, 'lastName')}
                        // isValid={!errors.lastName}
                        errorMessage={LAST_NAME_ERROR_MESSAGE}
                    />
                </div>
                <Input
                    placeholder="Organisation Name"
                    value={formData.organisationName}
                    onChange={(val: string) => handleFormChange(val, 'organisationName')}
                    errorMessage={ORGANISATION_NAME_ERROR_MESSAGE}
                    isValid={!errors.organisationName}
                />
                <Dropdown
                    selectedOption={formData.organisationType}
                    optionsList={orgTypeOptions}
                    itemClickHandler={(selectedItem: DropdownOption) =>
                        handleFormChange(selectedItem, 'organisationType')
                    }
                    style={{ marginTop: '35px', width: '100%' }}
                    inputStyle={{ fontSize: '12px' }}
                    label="Organisation Type"
                    placeholder={'Organisation Type'}
                    errorMessage={ORGANISATION_TYPE_ERROR_MESSAGE}
                />
                <Input
                    placeholder="Work Email"
                    value={formData.emailId}
                    onChange={(val: string) => handleFormChange(val.trim(), 'emailId')}
                    isValid={!errors.emailId}
                    errorMessage={EMAIL_ERROR_MESSAGE}
                />
                <div className={styles.Row}>
                    <CountryCodesDropdown
                        setCountryCode={(val) => handleFormChange(val, 'countryCode')}
                    />
                    <Input
                        type="number"
                        placeholder="Your Contact No."
                        value={formData.mobileNumber}
                        onChange={(val: string) => handleFormChange(val, 'mobileNumber')}
                        style={{ marginTop: '0', width: '100%' }}
                        isValid={!errors.mobileNumber}
                        errorMessage={MOBILE_NUMBER_ERROR_MESSAGE}
                    />
                </div>
                <Input
                    placeholder="Password (min 8 characters)"
                    type="password"
                    value={formData.password}
                    onChange={(val: string) => handleFormChange(val, 'password')}
                    isValid={!errors.password}
                    errorMessage={PASSWORD_ERROR_MESSAGE}
                />
                <Button
                    text="Create Account"
                    loadingText="Please Wait"
                    onClick={() => onSubmit()}
                    style={{ width: '100%' }}
                    disabled={disabled}
                    loading={isSigningUp}
                    variant="primary"
                />

                <div className={styles.Signup}>
                    Already have an account?&nbsp;
                    <button
                        className={styles.Login}
                        onClick={() => navigate(INVESTOR_ROUTES.LOGIN)}
                    >
                        Login.
                    </button>
                </div>
            </Form>
        </div>
    );
}

export default InvestorSignupForm;
