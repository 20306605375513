import React, { useRef, useState } from 'react';
import SlateEditor from '../../../TextEditor/TextEditor';
import { Button, Modal } from '../../../_custom';
import styles from './DealSummaryEditor.module.scss';
import { useReactToPrint } from 'react-to-print';
import html2pdf from 'html2pdf.js';
import {
    getSignedUrl,
    saveDocMetaDataInDocService,
    uploadFile,
} from '../../../../modules/Company/UploadJourney/components/UploadCard/utils/api/helpers';
import { toast } from 'react-toastify';
import { getTimeStampForFileName } from '../../../../utils/dateUtils';
import { useAppSelector } from '../../../../app/hooks';
import { fetchImageFromS3AndConvertToBase64 } from '../../../../utils/s3';
import { aicaGreyBase64_2, regenerateIcon } from '../../../../assets/hostedassets';

function DealSummaryEditor({
    source,
    openModal,
    closeModal,
    categoryId,
    flowId,
    assesseeOrgId,
    metadataUpdate,
    generating,
    editorInitialData,
    editorReady,
    saveOnePagerTemplateDataForOrg,
    setShowRegenConfirmModal,
}) {
    const [saving, setSaving] = useState(false);
    const [loadingPreview, setLoadingPreview] = useState(false);
    const contentToPrint = useRef(null);
    const editorContent = useRef(null);
    const companyName = useAppSelector((state) => state.investor.dealDrawer.companyName);
    const domainMetadata = useAppSelector((state) => state.user.domainMetadata);
    const mainImageUrl = domainMetadata?.mainImageUrl;
    const orgName = domainMetadata?.orgName;

    const getHTMLElementForExport = async () => {
        let clonedElement: any;
        try {
            const el = document?.getElementById('element-to-be-downloaded-as-pdf');

            clonedElement = el?.cloneNode(true);
            clonedElement.style.backgroundImage = aicaGreyBase64_2;
            clonedElement.style.backgroundRepeat = 'repeat';
            clonedElement.style.backgroundSize = '250px auto';

            if (mainImageUrl) {
                const response: any = await fetchImageFromS3AndConvertToBase64(mainImageUrl);

                if (response) {
                    const topDiv = document.createElement('div');
                    const bottomDiv = document.createElement('div');

                    const topImg = document.createElement('img');

                    topDiv.style.display = 'flex';
                    topDiv.style.justifyContent = 'flex-end';

                    topImg.src = response;
                    topImg.alt = '';
                    topImg.height = 30;
                    topImg.crossOrigin = 'true';
                    topImg.style.transform = 'translateY(40px)';

                    bottomDiv.style.fontSize = '24px';
                    bottomDiv.style.fontWeight = '500';
                    bottomDiv.style.color = '#D2D2D2';

                    bottomDiv.innerHTML = `
                        <span>Built on AICA by ${orgName}</span>
                    `;
                    topDiv.appendChild(topImg);

                    // bottomDiv.appendChild(bottomImg);
                    clonedElement.insertBefore(topDiv, clonedElement.firstChild);

                    clonedElement.appendChild(bottomDiv);
                }
            }

            clonedElement.remove();
        } catch (err) {
            console.error('error', err);
        }
        return clonedElement;
    };

    const handlePreview = useReactToPrint({
        onPrintError: (error) => console.error(error),
        content: () => contentToPrint.current,
        documentTitle: 'Print This Document',
        removeAfterPrint: true,
        print: async (printIframe: HTMLIFrameElement) => {
            const getElementForExport = await getHTMLElementForExport();

            try {
                // setLoadingPreview(true);
                const document = printIframe.contentDocument;
                if (document) {
                    var opt = {
                        margin: 10,
                        filename: 'deal_summary.pdf',
                        html2canvas: { scale: 2, useCORS: true, dpi: 198, allowTaint: false },
                        jsPDF: { unit: 'mm', format: 'a3', orientation: 'landscape' },
                    };
                    html2pdf()
                        .from(getElementForExport)
                        .set(opt)
                        .toPdf()
                        .output('bloburl')
                        .then((bloburl) => {
                            window.open(bloburl, '_blank');
                            setLoadingPreview(false);
                        });
                }
            } catch (err) {
                console.error('Error in generating preview', err);
            }
        },
    });

    const handleSaveToDb = useReactToPrint({
        onPrintError: (error) => console.error(error),
        content: () => contentToPrint.current,
        documentTitle: 'Print This Document',
        removeAfterPrint: true,
        print: async (printIframe: HTMLIFrameElement) => {
            try {
                const document = printIframe.contentDocument;
                const getElementForExport = await getHTMLElementForExport();

                if (document) {
                    const html = document.getElementById('element-to-be-downloaded-as-pdf');

                    var opt = {
                        margin: 10,
                        filename: 'check.pdf',
                        // image: { type: 'jpeg', quality: 0.20 },
                        html2canvas: { scale: 2, useCORS: true, dpi: 198, allowTaint: false },
                        jsPDF: { unit: 'mm', format: 'a3', orientation: 'landscape' },
                    };

                    html2pdf()
                        .from(getElementForExport)
                        .set(opt)
                        .toPdf()
                        .output('blob')
                        .then(function (res) {
                            var file = new File([res], 'one_pager_report.pdf', {
                                type: 'application/pdf',
                            });
                            getSignedUrlFromS3(file);
                        });
                }
            } catch (err) {
                console.error('Error in saving File', err);
            }
        },
    });

    const getSignedUrlFromS3 = async (file) => {
        const uploadConfig = {
            assesseeOrgId: assesseeOrgId,
            categoryId: categoryId,
            mnemosyneOrgId: domainMetadata?.investorOrgId,
        };
        const fileData = {
            file: {
                name: `${
                    companyName ? companyName + '_' : ''
                }DealSummary_${getTimeStampForFileName()}.pdf`,
                type: 'application/pdf',
            },
        };
        try {
            const signResponse = await getSignedUrl(fileData, uploadConfig);
            uploadFileAfter(signResponse.signedUrl, file, fileData);
        } catch (err) {
            setSaving(false);
            console.error('Error in getting signed url from s3', err);
            toast.error('Some error occoured, please try again later');
        }
    };

    const uploadFileAfter = async (url, file, fileData) => {
        const uploadResponse = await uploadFile(file, url);
        const input_saveMetadata = {
            categoryId: categoryId,
            mnemosyneOrgId: domainMetadata?.investorOrgId,
            fileName: fileData.file.name,
            fileSizeKb: fileData?.file?.size / 1_000,
            flowId: flowId,
            fileType: fileData.file.type,
        };
        const docData = await saveDocMetaDataInDocService({
            input: input_saveMetadata,
            orgId: assesseeOrgId,
        });

        const fileInfo = {
            url: uploadResponse?.url,
            fileName: fileData.file.name,
            docId: docData?.id,
        };
        metadataUpdate(fileInfo);
        setSaving(false);
        toast.success('Successfully Saved');
        closeModal();
    };

    return openModal ? (
        <Modal
            size="full"
            onClose={() => {
                closeModal();
            }}
            open={openModal}
            style={{ padding: 0, fontSize: '12px', lineHeight: '140%', position: 'relative' }}
            persistent
            withoutCloseButton
        >
            {!!editorReady && (
                <SlateEditor
                    source={source}
                    editorInitialData={editorInitialData}
                    targetRef={contentToPrint}
                    editorContentRef={editorContent}
                    assesseeOrgId={assesseeOrgId}
                    generating={generating}
                    onClose={() => {
                        if (source !== 'CREATE_DEAL')
                            saveOnePagerTemplateDataForOrg(editorContent.current);
                        closeModal();
                    }}
                />
            )}
            <div className={styles.Footer}>
                <div>
                    <div
                        className={styles.Regen}
                        onClick={() => {
                            !generating && setShowRegenConfirmModal(true);
                        }}
                    >
                        <img src={regenerateIcon} alt={'re'} height={'24px'} />
                        {generating ? 'Regenerating' : 'Regenerate'}
                    </div>
                </div>

                <div className={styles.Text}>
                    *Right-click tables to modify: add rows, add columns, or delete
                </div>

                <div className={styles.Buttons}>
                    <Button
                        text="Preview"
                        variant="purpleinverted"
                        onClick={(e) => {
                            e.preventDefault();
                            setLoadingPreview(true);
                            handlePreview(null, () => contentToPrint?.current);
                        }}
                        style={{ margin: '0 20px 0 0 ' }}
                        loading={loadingPreview}
                    />
                    <Button
                        text="Save & Close"
                        variant="primary"
                        onClick={(e) => {
                            e.preventDefault();
                            setSaving(true);
                            if (source === 'CREATE_DEAL') {
                                localStorage.setItem(
                                    'one_pager_content',
                                    JSON.stringify(editorContent.current),
                                );
                                handleSaveToDb(null, () => contentToPrint?.current);
                            } else {
                                saveOnePagerTemplateDataForOrg(editorContent.current);
                            }
                        }}
                        style={{ marginTop: '0' }}
                        loading={saving}
                    />
                </div>
            </div>
        </Modal>
    ) : (
        <></>
    );
}

export default DealSummaryEditor;
