import React, { useEffect, useState } from 'react';
import styles from './DebtSchedule.module.scss';
import ToggleButton from '../../../../../../../common/ToggleButton/ToggleButton';
import UploadCard from '../../../UploadCard/UploadCard';
import { useAppSelector } from '../../../../../../../app/hooks';
import { useDispatch } from 'react-redux';
import {
    fetchUploadJourneyData,
    updateDebtSchedule,
} from '../../../../../../../store/company/action';
import { USER_TYPE } from '../../../../../../../utils/constants/user';
import { DebtScheduleExcel } from '../../../../../../../assets/hostedassets';
import { useParams } from 'react-router-dom';
import { Mixpanel } from '../../../../../../../utils/mixpanel';
import {
    DEBT_SCHEDULE_UPLOAD,
    TOGGLE_LOANS,
} from '../../../../../../../utils/constants/mixpanelEvents/companyEvents';

function DebtSchedule(props) {
    const dispatch = useDispatch();
    const { flowId } = props;
    const [toggle, setToggle] = useState(false);
    const debtData = useAppSelector((state) => state.company.uploadJourneyData?.debtDocs);
    const queryParams = useParams();
    const assesseeOrgId =
        useAppSelector((state) => state.user?.userData?.assesseeOrgId) ?? queryParams.id;
    const configData = useAppSelector((state) => state.company.config?.tabConfigs);
    const categoryId = configData.financials.components.find((item) => item.name === 'debtSchedule')
        ?.categoryId;
    const SAMPLE_DEBT_SCHEDULE = DebtScheduleExcel;
    const toggleDebt = () => {
        Mixpanel.track(TOGGLE_LOANS);
        setToggle(!toggle);
        dispatch(
            updateDebtSchedule({
                hasDebtSchedule: !toggle,
                assesseeOrgId: assesseeOrgId ?? '',
                onSuccess: () => {
                    dispatch(
                        fetchUploadJourneyData({
                            accountType: USER_TYPE.COMPANY,
                            assesseeOrgId: assesseeOrgId ?? '',
                        }),
                    );
                },
            }),
        );
    };
    useEffect(() => {
        if (debtData) {
            setToggle(debtData?.hasDebt);
        }
    }, [debtData]);

    const updateDebtScheduleData = (file) => {
        return new Promise((resolve, reject) => {
            dispatch(
                updateDebtSchedule({
                    hasDebtSchedule: toggle,
                    assesseeOrgId: assesseeOrgId ?? '',
                    debtScheduleDoc: {
                        docId: file.docId,
                        docName: file.fileName,
                        isDeleted: false,
                    },
                    onSuccess: () => {
                        Mixpanel.track(DEBT_SCHEDULE_UPLOAD);
                        dispatch(
                            fetchUploadJourneyData(
                                {
                                    accountType: USER_TYPE.COMPANY,
                                    assesseeOrgId: assesseeOrgId ?? '',
                                },
                                {
                                    onSuccess: () => {
                                        resolve(file);
                                    },
                                },
                            ),
                        );
                    },
                    onError: () => {
                        reject();
                    },
                }),
            );
        });
    };
    return (
        <>
            <h3 style={{ margin: 0 }} className="mainHeading">
                Add existing Loans/Debts{' '}
            </h3>
            <div className={styles.HaveDebtToggle}>
                <div className={styles.ToggleText}>Do you have any existing loans/debts?</div>
                <ToggleButton checked={toggle} onChange={toggleDebt} />
            </div>
            {/* <div className={styles.LoanInfoBanner}>
                <img src={lightBulbBlack} alt="bulb" />
                <div className={styles.BannerText}>
                    Add all to conclude faster underwriting and ensure higher capital limit.
                </div>
            </div> */}
            <div
                style={toggle ? { maxHeight: '150rem' } : { maxHeight: '0' }}
                className={styles.UploadContainer}
            >
                <h5>
                    Upload details of all active loan/debt accounts in the given{' '}
                    <a
                        href={SAMPLE_DEBT_SCHEDULE}
                        className={styles.sampleLink}
                        download="Debt_Schedule.xlsx"
                    >
                        format.csv
                    </a>
                </h5>
                <UploadCard
                    existingFiles={debtData?.debtDocs ?? []}
                    assesseeOrgId={assesseeOrgId}
                    metadataUpdate={(file) => updateDebtScheduleData(file)}
                    categoryId={categoryId}
                    flowId={flowId}
                    fileDeleteHandler={() => {}}
                    disableDelete
                    allowMultiple
                />
            </div>
        </>
    );
}

export default DebtSchedule;
