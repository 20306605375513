import { Outlet } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import OnePager from '../../common/OnePager/OnePager';

function CompanyDashboardParent() {
    //* This component houses the AICA Report, Debt Products and Data Room page and
    // any shared logic for the above components.
    const onePagerState = useAppSelector((state) => state.investor.onePager);

    return (
        <div>
            <Outlet />

            {onePagerState.open &&
                ['REPORT_TOP_BAR', 'PORTFOLIO'].includes(onePagerState.source as string) && (
                    <OnePager />
                )}
        </div>
    );
}

export default CompanyDashboardParent;
