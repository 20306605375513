import React, { useEffect, useState } from 'react';
import FileTree from '../FileTree/FileTree';
import styles from './Folder.module.scss';
import { FileNode } from '../../../../DataRoom';
import {
    dataRoomArrowUp,
    downloadIconDataRoom,
    downloadIconDataRoomHovered,
    fileIconDataRoom,
    folderIconDataRoom,
} from '../../../../../../assets/hostedassets';
import useFetchDataRoomFiles from '../../../../useFetchDataRoomFiles';

interface FolderProps {
    name: string;
    innerFiles: FileNode[];
    lastUpdated: string;
    index: number;
    fetchFiles: Function;
    // isRecurCompany: boolean;
}

const Folder: React.FC<FolderProps> = ({
    name,
    innerFiles,
    lastUpdated,
    index,
    fetchFiles,
    // isRecurCompany,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [downloadImgUrl, setDownloadImgUrl] = useState(downloadIconDataRoom);
    const { downloadTheFolder } = useFetchDataRoomFiles();
    const [masterFolderName, setMasterFolderName] = useState('');
    useEffect(() => {
        if (index === 1) {
            setMasterFolderName(name);
        }
    }, []);

    return (
        <div className={styles.folder}>
            <div className={styles.folderHeader} onClick={() => setIsOpen(!isOpen)}>
                <div
                    style={{
                        marginLeft: `${index * 1.25}rem`,
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                    }}
                >
                    <span>
                        <img
                            className={isOpen ? styles.arrowDown : styles.arrowUp}
                            src={dataRoomArrowUp}
                            alt={'arrow'}
                        />
                    </span>
                    <span>
                        <img src={folderIconDataRoom} alt={'file'} />
                    </span>
                    <span className={styles.FolderName}>{name}</span>
                </div>
                <span className={styles.FolderName}>{lastUpdated}</span>
                <span
                    onClick={(e) => {
                        e.stopPropagation();
                        downloadTheFolder(innerFiles, name);
                    }}
                    className={styles.downloadIconContainer}
                    onMouseEnter={() => setDownloadImgUrl(downloadIconDataRoomHovered)}
                    onMouseLeave={() => setDownloadImgUrl(downloadIconDataRoom)}
                >
                    <img src={downloadImgUrl} alt={'icon'} />
                </span>
            </div>
            <div className={`${styles.folderContent} ${isOpen ? styles.open : styles.close}`}>
                <FileTree
                    // isRecurCompany={isRecurCompany}
                    data={innerFiles}
                    index={index}
                    masterFolderName={masterFolderName}
                    fetchFiles={fetchFiles}
                />
            </div>
        </div>
    );
};

export default Folder;
